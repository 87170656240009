import { getUserNameForAvatar, generateFullName } from "../utils";

export const renderCollaboratorAvatar = (avatarUrl, firstName, lastName) => {
  if (!avatarUrl && !firstName && !lastName) {
    return (
      <div className="avatar_not_exist">
        <p style={{ margin: "auto" }}>-</p>
      </div>
    );
  }
  let avatarDiv = null;
  if (!avatarUrl) {
    avatarDiv = (
      <div className="avatar_not_exist">
        <span>{getUserNameForAvatar(firstName, lastName)}</span>
      </div>
    );
  } else {
    avatarDiv = (
      <div className="avatar_exist">
        <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
      </div>
    );
  }

  return avatarDiv;
};

export const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
  return `${hours}h ${formattedMinutes}min`;
};
