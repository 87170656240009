import React, { Fragment, useState, useEffect } from "react";
import { useQuery } from "react-query";
import classNames from "classnames";
import { toast } from "react-toastify";
import {
  getClientsSuggestions,
  getCategoriesSuggestions,
  getClientProgressionBudget,
  getCollaboratorsPlanner,
  getCollaboratorCalendar,
  saveCollaboratorsPlanner,
  sendGenericEmail,
} from "../../../api";
import moment from "moment";
import "moment/locale/fr";
import "./Planner.local.scss";
import CalendarPage from "./Calendar.js";
import ListView from "./ListView.js";
import SideBarPlanner from "./SideBarPlanner.js";
import _ from "../../../i18n";
import { IconPlus, IconArrowRight, IconChevronDown } from "../../common/Icons";
import { PERIOD_INTERVALS, TTP_UA_URL } from "../../../config";
import {
  renderAvatar,
  formatDuration,
  renderDurationDisplay,
} from "../../../utils";
import { TiArrowSortedDown } from "react-icons/ti";

import { Calendar } from "primereact/calendar";
import SideBar from "./SideBarPlanner.js";
moment.locale("fr");
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

const today = formatDate(new Date());
const Planner = ({
  token,
  navCommunity,
  organizationId,
  collaboratorId,
  collaboratorName,
  avatar,
  lng,
  year,
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [clientOptions, setClientOptions] = useState([]);
  const [taskCategoriesOptions, setTaskCategoriesOptions] = useState([]);
  const [collapsedWeeks, setCollapsedWeeks] = useState({});
  const [selectedPeriodTypeId, setSelectedPeriodTypeId] = useState(2);
  const [selectedCalendarTypeId, setSelectedCalendarTypeId] = useState(1);
  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [selectedTypeEvent, setSelectedTypeEvent] = useState(null);
  const [selectedDateEvent, setSelectedDateEvent] = useState(today);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("todo");
  const [selectedDescription, setSelecteddescription] = useState(null);
  const [selectedDurationHours, setSelectedDurationHours] = useState(0);
  const [selectedPlaceRDV, setSelectedPlaceRDV] = useState(null);
  const [selectedDurationMin, setSelectedDurationMin] = useState(0);
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [childrens, setChildrens] = useState([]);
  const [timeSheetByDays, setTimeSheetByDays] = useState([]);
  const [events, setEvents] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [durationsByDay, setDurationsByDay] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentWeek, setCurrentWeek] = useState(moment());
  const [weekAll, setWeekAll] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [selectedShemaType, setSelectedShemaType] = useState(1);

  const calendarTabs = [
    {
      id: 1,
      label: "calandrier",
      title: "calendrier",
    },
    {
      id: 2,
      label: "time sheet",
      title: "time sheet",
    },
  ];
  const schemaTypeTabs = [
    {
      id: 1,
      label: "category",
      title: "category",
    },
    {
      id: 2,
      label: "clients",
      title: "clients",
    },
  ];
  const periodTabs = [
    {
      id: 1,
      label: "HEBDOMADAIRE",
      title: "HEBDOMADAIRE",
    },
    {
      id: 3,
      label: "",
      title: "",
    },
    {
      id: 2,
      label: "MENSUEL",
      title: "MENSUEL",
    },
  ];
  const statusOptions = [
    { label: "A faire", value: "todo" },
    { label: "En cours", value: "inprogress" },
    { label: "Terminé", value: "done" },
  ];
  const dayMapping = {
    "lun.": "Mon",
    "mar.": "Tue",
    "mer.": "Wed",
    "jeu.": "Thu",
    "ven.": "Fri",
    "sam.": "Sat",
    "dim.": "Sun",
  };

  const [startDate, setStartDate] = useState(new Date(2024, 1, 1));
  const [endDate, setEndDate] = useState(new Date(2024, 12, 7));
  const [orderBy, setOrderBy] = useState("category");
  const [transformedData, setTransformedData] = useState({});
  const [transformedDataClients, setTransformedDataClients] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [budgetProgression, setBudgetProgression] = useState({});
  const [responsables, setResponsables] = useState(null);
  const [borderedcolumns, setBorderedColumns] = useState({});

  let eventsPlanner = useQuery(
    ["getPlannersList", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCollaboratorsPlanner(
            organizationId,
            collaboratorId,
            year,
            token
          );
          setEvents(response.data.data.events);
          setTimeSheetByDays(response.data.data.tasksByDay);
          transformDataByWeek(
            response.data.data.events,
            startDate,
            endDate,
            orderBy
          );
          setHolidays(response.data.data.holidays);
          setAvailabilities(response.data.data.availabilities);
          setDurationsByDay(response.data.data.durationsByDay);
          setWorkingHours(response.data.data.workingHours);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientProgressionBudgetQuery = useQuery(
    ["getClientsList", token, organizationId, selectedClient, selectedCategory],
    async () => {
      if (token && selectedClient) {
        let columns = [];
        let entityColumns = [];
        try {
          const response = await getClientProgressionBudget(
            selectedClient.value,
            organizationId,
            token,
            { year: 2024, category: { id: selectedCategory.value } }
          );

          setBudgetProgression(response.data.data.progression);
          setResponsables(response.data.data.responsables);
          // setVolumetry(response.data.data.volumetry);
          // setGlobal(response.data.data.global);

          Object.keys(response.data.data.responsables).map((element) => {
            response.data.data.responsables[element].roles
              .filter((role) => role !== "other")
              .map((role) => {
                columns.push({
                  id: role,
                  label: _(role),
                  isEditable: true,
                  widthPercentage: 15,
                });
              });
          });
          setBorderedColumns(columns);
        } catch (e) {
          // setBorderedColumns({});
          // setBudgetProgression({});
          // // setResponsables(null);
          // setVolumetry([]);
          // setGlobal(null);
          return null;
        }
      }
    }
  );

  let taskCateoriesdQuery = useQuery(
    ["getCategoriesSuggestions", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCategoriesSuggestions(
            organizationId,
            token
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label.fr,
              };
            });
          setTaskCategoriesOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientsdQuery = useQuery(
    ["getClients", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getClientsSuggestions(
            token,
            [],
            organizationId,
            "isEntity",
            year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const saveCollaboratorPlanner = async () => {
    try {
      const response = await saveCollaboratorsPlanner(
        selectedEventId,
        organizationId,
        selectedTypeEvent,
        selectedPlaceRDV,
        collaboratorId,
        selectedClient ? selectedClient.value : null,
        selectedCategory ? selectedCategory.value : null,
        selectedStatus,
        selectedDateEvent,
        selectedDescription,
        parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin),
        year,
        token
      );
      // Check the response
      if (response.data.result === "OK") {
        toast.success(_("successfully_added"));
        // if (overContainer == "DONE") {
        //   sendEmailToResponsable(
        //     activePlan.plan?.responsables,
        //     activePlan.client,
        //     overContainer
        //   );
        // } else if (overContainer == "VALIDATION") {
        let responsables = [
          // {
          //   id: 528409,
          //   name: "Yousra Berradi",
          //   email: "yousra.berradi@oam.ma",
          // },
          // {
          //   id: 458410,
          //   name: "Yassine El Bekkali",
          //   email: "yassine.el.bekkali21@gmail.com",
          // },
          {
            id: 470189,
            name: "Safae El Jemli",
            email: "eljemlisafae@gmail.com",
          },
        ];

        sendEmailToResponsable(responsables, null, null);
        // }
      } else {
        toast.error(_("savingError"), { autoClose: true });
      }
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
    setOpenAddEvent(false);
    setSelectedCategory(null);
    setSelectedClient(null);
    setSelectedDateEvent(null);
    setSelectedTypeEvent(null);
    refreshEvents();
  };

  const sendEmailToResponsable = async (responsables, client, status) => {
    const recipients = {};
    const dynamicFields = {};
    const promises = responsables.map(async (responsable) => {
      try {
        recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
        dynamicFields["{{RECIPIENT}}"] = collaboratorName;

        dynamicFields[
          "{{OBJET}}"
        ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}. 
        `;
        if (selectedTypeEvent === "task") {
          if (selectedEventId) {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}. 
        `;
          } else {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une modification sur vos tâche a été faite pour le client ${selectedClient.label} à la date du ${selectedDateEvent}. 
       `;
          }
        } else {
          dynamicFields[
            "{{OBJET}}"
          ] = `Un nouveau rendez-vous vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent} . 
       `;
        }
        dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
        dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
        dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
      } catch (error) {
        return null;
      }
    });
    console.log(
      "ppppp",
      dynamicFields,
      selectedClient,
      selectedCategory,
      collaboratorName,
      selectedDurationHours,
      selectedDurationMin,
      "seletedIddddd====pppp",
      selectedEventId
    );
    await Promise.all(promises);

    sendGenericEmail(
      token,
      recipients,
      "PLANNER_NOTIFICATION",
      lng,
      dynamicFields,
      organizationId
    );
  };

  const refreshEvents = () => {
    eventsPlanner.refetch();
  };

  useEffect(() => {
    if (clientOptions.length === 0) {
      clientsdQuery.refetch();
    }
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const days = [];
    const nextWeekDays = [];

    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i, "days");
      const nextWeekDay = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
      // currentWeek.push(day);
      nextWeekDays.push(nextWeekDay);
    }

    // const weeks = [];

    // for (let i = 0; i <= 4; i++) {
    //   const days = [];
    //   for (let j = 0; j < 5; j++) {
    //     const day = startOfWeek.clone().add(i * 7 + j, "days");
    //     days.push(day);
    //   }
    //   weeks.push(days);
    // }
    // setWeekAll(weeks);
    // setCurrentWeekIndex(0);
    setWeekAll(days);
    setCurrentWeek(currentWeek);

    generateWeeks();
  }, [currentMonth, clientOptions]);

  const transformDataByWeek = (events, startDate, endDate) => {
    // const weekEvents = events.filter((event) => {
    //   return event.date.date >= startDate && event.date.date <= endDate;
    // });

    const weekEvents = events;
    const groupedDataByCategory = weekEvents.reduce((acc, event) => {
      const categoryKey = event.category_id;
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          id: event.category_id,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };
      }
      const clientKey = event.client_id;
      let client = acc[categoryKey].childrens.find((c) => c.id === clientKey);

      if (!client) {
        client = {
          id: clientKey,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[categoryKey].childrens.push(client);
      }

      client.estimated += event.duration ? parseInt(event.duration) : 0;
      client.realized += event.task_duration ? event.task_duration * 60 : 0;
      client.ecart = client.estimated - client.realized;

      acc[categoryKey].estimated += event.duration
        ? parseInt(event.duration)
        : 0;
      acc[categoryKey].realized += event.task_duration
        ? event.task_duration * 60
        : 0;
      acc[categoryKey].ecart =
        acc[categoryKey].estimated - acc[categoryKey].realized;

      return acc;
    }, {});

    const groupedDataByClient = weekEvents.reduce((acc, event) => {
      const clientKey = event.client_id;
      if (!acc[clientKey]) {
        acc[clientKey] = {
          id: event.client_id,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };
      }

      const categoryKey = event.category_id;
      let category = acc[clientKey].childrens.find((c) => c.id === categoryKey);

      if (!category) {
        category = {
          id: categoryKey,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[clientKey].childrens.push(category);
      }

      category.estimated += event.duration ? parseInt(event.duration) : 0;
      category.realized += event.task_duration ? event.task_duration * 60 : 0;
      category.ecart = category.estimated - category.realized;

      acc[clientKey].estimated += event.duration ? parseInt(event.duration) : 0;
      acc[clientKey].realized += event.task_duration
        ? event.task_duration * 60
        : 0;
      acc[clientKey].ecart = acc[clientKey].estimated - acc[clientKey].realized;

      return acc;
    }, {});

    const categoriesList = Object.values(groupedDataByCategory);
    const clientsList = Object.values(groupedDataByClient);
    setTransformedDataClients(clientsList);
    setTransformedData(categoriesList);

    return clientsList;
  };

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}`;
  };

  const generateWeeks = () => {
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const weeks = [];
    const collapsedState = {};

    let weekStart = startOfMonth.clone();
    if (startOfMonth.day() === 6) {
      // Samedi
      weekStart.add(2, "days");
    } else if (startOfMonth.day() === 0) {
      // Dimanche
      weekStart.add(1, "days");
    } else {
      weekStart = startOfMonth.clone().startOf("week").add(0, "days");
    }

    while (weekStart.isBefore(endOfMonth.clone().endOf("week"))) {
      const days = [];
      let day = weekStart.clone();

      for (let i = 0; i < 5; i++) {
        days.push(day.clone());
        day.add(1, "day");
      }

      weeks.push(days);
      weekStart.add(7, "days");
      collapsedState[weeks.length - 1] = true;
    }

    setWeeks(weeks);
    setCollapsedWeeks(collapsedState);
  };

  const renderCustomAddOn = (selectedId, items, type) => {
    return (
      <div className="filter_custom">
        <div className={"mini-tabs-frame"}>
          {items.map((item) => (
            <div
              key={item.id}
              className={selectedId === item.id ? "mini-tabs-frame_active" : ""}
              onClick={() =>
                type === "calendar"
                  ? handleCalendarTypeChange(item.id) // Reset to current week
                  : handlesetSelectedPeriodTypeIdChange(item.id)
              }
            >
              {console.log("=====item", item)}
              {console.log("=====type", type, type === "calendar")}
              {_(item.label)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePreviousWeek = () => {
    const currentWeek = weekAll;
    const startOfWeek = currentWeek[0].clone().startOf("week");
    const days = [];
    const nextWeekDays = [];
    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i - 7, "days");

      days.push(day);
    }
    setWeekAll(days);
    // const mostCommonMonth = getMostCommonMonth(days);
    console.log(
      "currentWeek====== days",
      currentWeek,
      "fff",
      days,
      "currentMonth",
      currentMonth,
      startOfWeek
    );
    // setCurrentMonth(startOfWeek);
  };

  const getMostCommonMonth = (days) => {
    const monthCounts = days.reduce((acc, day) => {
      const month = day.month();
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(monthCounts).reduce((a, b) =>
      monthCounts[a] > monthCounts[b] ? parseInt(a) : parseInt(b)
    );
  };

  const handleNextWeek = () => {
    const currentWeek = weekAll;
    const startOfWeek = currentWeek[0].clone().startOf("week");
    const days = [];
    const nextWeekDays = [];
    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
    }
    setWeekAll(days);
  };

  const handleCalendarTypeChange = (type) => {
    setSelectedCalendarTypeId(type);

    setCurrentWeek(moment().startOf("week"));
    setCurrentMonth(moment());
    transformDataByWeek(events, startDate, endDate, orderBy);
  };

  const handlesetSelectedPeriodTypeIdChange = (type) => {
    setSelectedPeriodTypeId(type);
    if (type === 1) {
      const startOfWeek = currentMonth.clone().startOf("week");
      const days = [];
      const nextWeekDays = [];
      for (let i = 0; i <= 4; i++) {
        const day = startOfWeek.clone().add(i + 7, "days");

        days.push(day);
      }
      console.log("days§§§§§§§", days);
      setWeekAll(days);
    } else {
      //  setCurrentWeek(currentWeek);
    }
    if (type === 2) {
      setStartDate(new Date(2024, 6, 1));
      setEndDate(new Date(2024, 6, 7));
    } else {
      setStartDate(new Date(2024, 1, 1));
      setEndDate(new Date(2024, 12, 7));
    }
  };

  return (
    <div className="planner-calendar">
      <div className="filters_content">
        <div className=" filters  line_space_content">
          <div className="filters ">
            <div className="calender_type">
              {renderCustomAddOn(
                selectedCalendarTypeId,
                calendarTabs,
                "calendar"
              )}
            </div>
            <div style={{ marginLeft: "12px" }}>
              {renderCustomAddOn(selectedPeriodTypeId, periodTabs, "period")}
            </div>
          </div>
          <button
            className="line_space_content"
            onClick={() => {
              setOpenAddEvent(true);
              setSelectedEventId(null);
            }}
          >
            <IconPlus size={10} /> Ajouter un événement
          </button>
        </div>
      </div>
      {eventsPlanner.isFetching ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : (
        <>
          {selectedCalendarTypeId === 1 ? (
            <CalendarPage
              token={token}
              organizationId={organizationId}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              renderDurationDisplay={renderDurationDisplay}
              formatDuration={formatDuration}
              timeSheetByDays={timeSheetByDays}
              holidays={holidays}
              durationsByDay={durationsByDay}
              workingHours={workingHours}
              dayMapping={dayMapping}
              events={events}
              setEvents={setEvents}
              selectedPeriodTypeId={selectedPeriodTypeId}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              handlePreviousWeek={handlePreviousWeek}
              handleNextWeek={handleNextWeek}
              weeks={weeks}
              currentDate={currentDate}
              currentWeekIndex={currentWeekIndex}
              weekAll={weekAll}
              collapsedWeeks={collapsedWeeks}
              setCollapsedWeeks={setCollapsedWeeks}
              setSelectedCategory={setSelectedCategory}
              selectedDateEvent={selectedDateEvent}
              selectedDurationHours={selectedDurationHours}
              setSelectedDurationHours={setSelectedDurationHours}
              selectedDurationMin={selectedDurationMin}
              setSelectedDurationMin={setSelectedDurationMin}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedPlaceRDV={selectedPlaceRDV}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              selectedDescription={selectedDescription}
              setSelectedDescription={setSelecteddescription}
              setSelectedClient={setSelectedClient}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedEventId={setSelectedEventId}
              refreshEvents={refreshEvents}
            />
          ) : (
            <ListView
              selectedParentId={selectedParentId}
              setSelectedParentId={setSelectedParentId}
              childrens={childrens}
              setChildrens={setChildrens}
              schemaTypeTabs={schemaTypeTabs}
              selectedShemaType={selectedShemaType}
              setSelectedShemaType={setSelectedShemaType}
              transformedData={transformedData}
              transformedDataClients={transformedDataClients}
              formatDuration={formatDuration}
              IconChevronDown={IconChevronDown}
            />
          )}
          {openAddEvent && (
            <SideBarPlanner
              token={token}
              year={year}
              organizationId={organizationId}
              collaboratorId={collaboratorId}
              avatar={avatar}
              events={events}
              setEvents={setEvents}
              collaboratorName={collaboratorName}
              clientOptions={clientOptions}
              taskCategoriesOptions={taskCategoriesOptions}
              statusOptions={statusOptions}
              selectedClient={selectedClient}
              selectedEventId={selectedEventId}
              selectedCategory={selectedCategory}
              selectedStatus={selectedStatus}
              selectedDescription={selectedDescription}
              selectedDurationHours={selectedDurationHours}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedCategory={setSelectedCategory}
              setSelectedClient={setSelectedClient}
              setSelectedStatus={setSelectedStatus}
              setSelectedDurationHours={setSelectedDurationHours}
              setSelectedDurationMin={setSelectedDurationMin}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              setSelecteddescription={setSelecteddescription}
              selectedDurationMin={selectedDurationMin}
              selectedPlaceRDV={selectedPlaceRDV}
              typesEventDivIsVisible={typesEventDivIsVisible}
              clientsDivIsVisible={clientsDivIsVisible}
              categoriesDivIsVisible={categoriesDivIsVisible}
              statusDivIsVisible={statusDivIsVisible}
              openAddEvent={openAddEvent}
              selectedDateEvent={selectedDateEvent}
              selectedTypeEvent={selectedTypeEvent}
              budgetProgression={budgetProgression}
              saveCollaboratorPlanner={saveCollaboratorPlanner}
              refreshEvents={refreshEvents}
              responsables={responsables}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Planner;
