import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { CustumedMatrix } from "tamtam-components";
import { getCollaboratorContribution } from "../../../../api";
import _ from "../../../../i18n";

import { FlagIcon } from "../../../common/Icons";
import "./EncodingProgressionContent.local.scss";

const NonFacturableContent = ({
  id,
  year,
  lng,
  name,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([
    {
      id: "total",
      label: _("total"),
      isEditable: true,
      widthPercentage: 10,
    },
    {
      id: "detail",
      label: _("Details"),
      isEditable: true,
      widthPercentage: 50,
    },
  ]);

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          setIsLoading(true);
          const response = await getCollaboratorContribution(
            id,
            organization,
            year,
            token,
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : filter.subPeriod.start
              : filter.start
                ? moment(filter.start).format("MM-DD")
                : "01-01",
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : filter.subPeriod.end
              : filter.end
                ? moment(filter.end).format("MM-DD")
                : "12-31",
            filter.keyWord,
            filter.category.label.split(".")[0],
          );
          setContribution(response.data.data);
          setFiltredContribution(response.data.data);
          setIsLoading(false);
        } catch (e) {
          setContribution([]);
          return null;
        }
      }
    },
  );

  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {isLoading ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !isLoading &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          <div className="production-frame_scroll">
            <div className="total">
              {_("folderCount")} : {contribution.length - 1}
            </div>
            <CustumedMatrix
              columns={columns}
              items={filtredContribution}
              handleCellule={handleColumns}
              handleTitle={handleTitle}
              headerTagBG={"#FFFFFF"}
            />
          </div>
        )}
      </div>
    );
  };

  const prepareExpandedClient = (id) => {
    if (typeof id === "string" && id.split("-").length > 1) {
      return;
    }
    if (id === "TOTAL") {
      return;
    }
    if (id === expandedClient) {
      const boxes = document.querySelectorAll(
        ".CustumedMatrix_matrix_body_line__11lbb",
      );

      boxes.forEach((box) => {
        box.classList.remove("sub_line");
        box.classList.remove("last_sub_line");
        box.classList.remove("expanded_line");
      });
      setFiltredContribution(contribution);
      setExpandedClient(null);
      setColumns([
        {
          id: "total",
          label: _("total"),
          isEditable: true,
          widthPercentage: 10,
        },
        {
          id: "detail",
          label: _("Details"),
          isEditable: true,
          widthPercentage: 50,
        },
      ]);
    } else {
      let result = [];
      contribution.forEach((item) => {
        result.push(item);
        if (item.id === id) {
          Object.keys(item.children).forEach((child) => {
            result.push(item.children[child]);
          });
        }
      });
      setExpandedClient(id === expandedClient ? null : id);
      setFiltredContribution(result);
      setColumns([
        {
          id: "total",
          label: _("total"),
          isEditable: true,
          widthPercentage: 10,
        },
        {
          id: "detail",
          label: _("Details"),
          isEditable: true,
          widthPercentage: 48,
        },
        {
          id: "observation",
          label: _("observation"),
          isEditable: true,
          widthPercentage: 15,
        },
      ]);
    }
  };

  const codes = Object.keys(
    (contribution[0] && contribution[0].details.client) || {},
  );

  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  };

  const handleColumns = (column, line) => {
    <style>
      {`
      .matrix_title_header{
        margin-top: 0px;
      }  
        .CustumedMatrix_matrix_body_line__11lbb > div:first-child{
          height: Opx;
        }
      `}
    </style>;
    if (line.id === "TOTAL") {
      if (column.id === "total") {
        return (
          <div
            className="tags"
            style={{ display: "grid",   justifyContent: "center" }}
          >
            <div className="tag no-border sm-margin" style={{ margin: "0px" }}>
              <span>{_("total")}</span>
            </div>
            <div className="tag no-border sm-margin" style={{  marginTop: "18px"}}>
              <div style={{  marginTop: "20px"}}>{convertToHoursMinutes(line.total.client)}</div>
            </div>
          </div>
        );
      } else if (column.id === "observation") {
        return null;
      } else {
        return (
          <Fragment>
            <div className="tags" style={{  paddingBottom: "20px"}}>
              {Object.keys(line.details.client || {}).map((key, index) => (
                <div
                  className="tag no-border sm-margin"
                  key={index}
                  style={{ margin: "0px" ,width: "60px"}}
                >
                  <span>{key.split("-")[0]}</span>
                </div>
              ))}
            </div>
            <div
              className="tags"
              style={{ marginLeft: "10px" }}
            >
              {Object.keys(line.details.client || {}).map((key, index) => (
                <div
                  className="tag no-border sm-margin"
                  key={index}
                  style={{ margin: "10px 0" }}
                >
                  <div>{convertToHoursMinutes(line.details.client[key])}</div>
                </div>
              ))}
            </div>
          </Fragment>
        );
      }
    } else {
      if (column.id === "total") {
        return (
          <div className="tags">
            <div className="tag no-border sm-margin">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  height: line.title.type === "child" && "40px",
                }}
              >
                {line.title.type !== "child"
                  ? convertToHoursMinutes(line.total.client)
                  : line.date}
              </div>
            </div>
          </div>
        );
      } else if (column.id === "observation") {
        return (
          <div className="text-tag">
            <div>
              {line.details.observation ? line.details.observation : "---"}
            </div>
          </div>
        );
      } else if (line.title.type === "child") {
        return (
          <div
            className="tags"
            style={{
              padding: "0"
            }}
          >
            {codes.map((code, index) => (
              <div
                className="tag no-border sm-margin"
                style={{ padding: "0" }}
                key={index}
              >
                <div>{convertToHoursMinutes(line.details[code] || 0)}</div>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <Fragment>
            <div onClick={() => prepareExpandedClient(line.id)}>
              <div
                className="tags"
                style={{
                  padding: "0",
                }}
              >
                {codes.map((code, index) => (
                  <div
                    className="tag no-border sm-margin"
                    style={{ padding: "0" }}
                    key={index}
                  >
                    <div>
                      {convertToHoursMinutes(line.details.client?.[code] || 0)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        );
      }
    }
  };

  const handleTitle = (title) => {
    return (
      <div
        onClick={() => prepareExpandedClient(title.id)}
        className={`${
          title.title.label === "TOTAL"
            ? "matrix_title_header sm-header"
            : title.title.type === "child"
              ? "matrix_sub_title"
              : "matrix_title"
        }`}
        id={
          title.title.label === "total"
            ? "total-line"
            : expandedClient === title.id
              ? "expanded"
              : title.title.type === "child"
                ? `sub-line-child`
                : ""
        }
        style={{
          height: title.title.label === "total" && "40%"
        }}
      >
        <div
          className="title_infos"
          style={{
            width: title.title.type !== "child" ? "51%" : "100%",            
          }}
        >
          <div>
            {title.title.type === "child"
              ? _(title.title.label)
              : title.title.label}
          </div>
        </div>

        {title.title.label !== "TOTAL" &&
          title.title.type !== "child" &&
          collaborator &&
          !collaborator.isDeleted && (
            <div className="actions">
              <div className="title_action">
                <FlagIcon />
              </div>
            </div>
          )}

        {title.title.type !== "child" && (
          <div
            className="tags"
            style={{
              flexDirection: "column",
              margin:
                title.title.label !== "TOTAL"
                  ? "0px 0px 0px 0px"
                  : "20px 0px 0px 20px",
            }}
          >
            <div className="tag no-border sm-margin lg-tag">
              <div>{name.split(" ")[0]}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="client-content">
      <div className="content-backscroll" style={{ height: "100%" }}>
        <div className="CustumedMatrix_header_scroll">
          {renderPannelFrame()}
        </div>
      </div>
    </div>
  );
};

export default NonFacturableContent;
