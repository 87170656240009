import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCategoriesSuggestions } from "../../../api";
import "./Nettoyage/Nettoyage.local.scss";
import { Carousel } from "primereact/carousel";
import Nettoyage from "./Nettoyage";
import _ from "../../../i18n";
import "./ProductionCapacity.local.scss"
import Encoding from "./Encoding";

const ProductionCapacity = ({filter, setFilter}) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [activeTab, setActiveTab] = useState({
    category: 6
  });

  const categories = [
    {
      id: 4,
      label: _("04. ENCODAGE COMPTABLE."),
      category: "ENCODAGE COMPTABLE"
    },
    {
      id: 6,
      label: _("06. NETTOYAGE ET VERIFIC."),
      category: "NETTOYAGE ET VERIFICATION COMPTABILITE"
    }
  ];

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const renderPannelFrame = () => {
    return (
      <div style={{ width: "100%" }}>
        {activeTab.category === 6 ? (
          <Nettoyage filter={filter} setFilter={setFilter}/>
        ) : activeTab.category === 4 ?(
          <Encoding filter={filter} setFilter={setFilter} />
        ): (
          <div></div>
        )}
      </div>
    );
  };

  let categoriesQuery = useQuery(
    ["categoriesQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getCategoriesSuggestions(
            navCommunity.id,
            token
          );
          let categoriesResponse = response.data.data.map((item) => {
            return { id: item.id, label: item.label[language] };
          });
          // setCategories(categoriesResponse); // Assurez-vous d'avoir useState pour categories si vous voulez mettre à jour l'état
        } catch (e) {
          // setCategories([]);
          return null;
        }
      }
    }
  );

  return (
    <div style={{ position: "relative", width: "100%" }}>
        <Carousel
          value={categories}
          numVisible={2}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          style={{ width: '100%' }}
          itemTemplate={(category, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                <div
                  className={`tab_badge ${
                    activeTab.category === category.id
                      ? "tab_badge_active"
                      : ""
                  }`}
                  onClick={() => setActiveTab({ category: category.id })}
                >
                  <div>
                    {/* {category.label.substr(0, 24)}
                    {category.label.length > 25 ? "." : ""} */}
                    {
                      category.label
                    }
                  </div>
                </div>
              </div>
            );
          }}
        />
        {renderPannelFrame()}
    </div>
  );
};

export default ProductionCapacity;
