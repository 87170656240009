import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteNotification, getManagerNotification } from "../../api";
import { setManagerNotification } from "../../store";
import { CircleIcon, IconPlus } from "../common/Icons";
import { NOTIFICATION_TABS, NOTIFICATION_STATUS } from "../../config";
import {
  useInterval,
  renderNotificationManagerMessage,
  renderNotificationCollaboratorMessage,
  getUserNameForAvatar,
  generateFullName,
  getNotificationManagerCollaboratorName,
} from "../../utils";
import moment from "moment";
import _ from "../../i18n";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";

import "./NotificationPage.local.scss";
import { MoreVerticalIcon } from "../common/Icons/MoreVerticalIcon";

const NotificationPage = () => {
  const { language } = useSelector((state) => state.params);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { managerNotification, loggedAs } = auth;
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const [activeTab, setActiveTab] = useState({
    tab: "allNotifications",
  });
  const [activeStatus, setActiveStatus] = useState({
    label: "ALL",
    value: null,
  });
  const [showActionsMenuForItem, setShowActionsMenuForItem] = useState(null)

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id
      ).authorisation.preferences
    : {};

  useInterval(() => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotification || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        loggedAs,
        allowedNotifications
      );
      managerNotificationQuery.then((result) => {
        if (
          result.data.data &&
          managerNotification.nbrResult < result.data.data.nbrResult
        ) {
          const data = [...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotificationQuery.nbrResult,
              data,
            })
          );
          setInterval({
            ...interval,
            start: 10,
          });
        }
      });
    } catch (e) {
      return null;
    }
  }, 100000);

  const handleFetchNotifications = async () => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotifications || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        auth.loggedAs,
        allowedNotifications,
        interval.start,
        interval.limit
      );
      if (managerNotificationQuery) {
        managerNotificationQuery.then((result) => {
          const data = [...managerNotification.data, ...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotification.nbrResult,
              data,
            })
          );
          setInterval({
            ...interval,
            start: interval.start + 10,
          });
        });
      }
    } catch (e) {
      return null;
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
        const response = await deleteNotification({
          token: auth.token,
          notificationId,
          organizationId: auth.navCommunity.id,
        })

        if (response.statusText === 'OK') {
          if (response.data?.data?.id) {
            let allowedNotifications = [];
            if (
              preferences.activateCalendarNotifications ||
              loggedAs === "COLLABORATOR"
            ) {
              allowedNotifications.push("COLLABORATOR_ABSENCE");
            }
            if (preferences.activateCarteNotifications || loggedAs === "COLLABORATOR") {
              allowedNotifications.push("CORRECTION_CARD");
            }
            try {
              let managerNotificationQuery = getManagerNotification(
                auth.user.uid,
                auth.navCommunity.id,
                auth.token,
                auth.loggedAs,
                allowedNotifications,
                0,
                interval.limit !== 10 ? interval.limit : null
              );
              console.log({interval})
              if (managerNotificationQuery) {
                managerNotificationQuery.then((result) => {
                  const data = [...result.data.data.data];
                  dispatch(
                    setManagerNotification({
                      nbrResult: managerNotification.nbrResult - 1,
                      data,
                    })
                  );
                });
              }
            } catch (e) {
              console.log({e})
            }
          }
          // TODO: REMOVE THE DELETED NOTIFICATION FROM THE UI AND DISPLAY A NOTIFICATION TO THE USER
        }

      } catch(e) {
        console.log(e);
      }
  }

  const getTabCount = (tab) => {
    if (managerNotification) {
      if (tab.tab === 'allNotifications') {
        let count = 0;
        for(let i = 0; i < managerNotification.data.length; i++) {
          if (managerNotification.data[i].status === 'PENDING') {
            ++count;
          }
        }
        return count;
      }

      let count = 0
      for(let i = 0; i < managerNotification.data.length; i++) {
        if (tab.actions.includes(managerNotification.data[i].data.action) && managerNotification.data[i].status === 'PENDING') {
          ++count;
        }
      }

      return count;
    }
    return 0;
  }

  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  const renderTabContent = () => {
    return NOTIFICATION_TABS.map((tab) => (
      <div
        className={`tab ${activeTab.tab === tab.tab ? "tab-active" : ""}`}
        onClick={() => setActiveTab(tab)}
      >
        { tab.tab !== 'allNotifications' ? tab.icon : ''}

        {_(tab.tab)}

        { tab.tab === activeTab.tab ? (<span className="count">{getTabCount(tab)}</span>) : ''}
      </div>
    ));
  };

  const getTabTitle = (tabName) => {
    if (tabName === 'card') return _('cardNotifications')
    else if (tabName === 'calendar') return _('calendarNotifications')
    else return _(tabName)
  }

  const renderFilterState = () => {
    return (
      <div className="content-header">
        <h2 className="selected-tab-title">
            {getTabTitle(activeTab.tab)}
        </h2>

        <div className="tabs-switcher">
            <div
              className={`tab ${
                activeStatus.value === 'PENDING' ? "tab-active" : ""
              }`}
              onClick={() => setActiveStatus({ label: "PENDING", value: "PENDING"  })}
            >
              <div>{_('PENDING') + ' (' + getTabCount(activeTab) + ')'}</div>
            </div>

            <div
              className={`tab ${
                activeStatus.value === null ? "tab-active" : ""
              }`}
              onClick={() => setActiveStatus({ label: "NEWEST", value: null  })}
            >
              <div>Les plus récentes</div>
            </div>
        </div>
      </div>
    );
  };

  const renderListNotifications = () => {
    return (
      (loggedAs === "COLLABORATOR" ||
        (preferences &&
          preferences.activateNotifications &&
          loggedAs === "MANAGER")) && (
        <div className={"notifications-list"}>
          {managerNotification && managerNotification.data.length > 0 ? (
            <div className={"list"}>
              {managerNotification &&
              managerNotification.data
                .filter(
                  (notific) =>
                    activeTab.tab === "allNotifications" ||
                    activeTab.actions.includes(notific.data.action)
                )
                .filter(
                  (notific) =>
                    !activeStatus.value || activeStatus.value === notific.status
                ).length > 0 ? (
                managerNotification &&
                managerNotification.data
                  .filter(
                    (notific) =>
                      activeTab.tab === "allNotifications" ||
                      activeTab.actions.includes(notific.data.action)
                  )
                  .filter(
                    (notific) =>
                      !activeStatus.value ||
                      activeStatus.value === notific.status
                  )
                  .map((notif) => (
                    <Link
                      to={NOTIFICATION_TABS.filter(
                        (tab) =>
                          tab.actions && tab.actions.includes(notif.data.action)
                      )[0]
                        .redirect.replace(":language", language)
                        .replace(":id", notif.collaborator)}
                    >
                      <div className={`notification-item ${notif.status === 'PENDING' ? 'notification-item-active' : ''}`}>

                        <CircleIcon
                          width="6px"
                          height="6px"
                          fill={notif.status === "PENDING" ? "#18A0FB" : ""}
                          className="status-indicator"
                        />

                        <div className="avatar">
                          {renderAvatar(notif.avatarUrl?.avatar,
                          getNotificationManagerCollaboratorName(notif.data))}
                        </div>

                        <div className="notification-content">
                          <h4 className="notification-content-title">
                            <div className={`notification-type-icon ${notif.status === 'PENDING' ? 'notification-type-icon-active' : ''}`}>
                              {renderNotificationItemIcon(notif)}
                            </div>
                            {_(notif.data.action)}
                          </h4>
                          <p className="notification-content-body">
                          {loggedAs === "COLLABORATOR"
                            ? renderNotificationCollaboratorMessage(
                                notif.data.action,
                                notif.data
                              )
                            : renderNotificationManagerMessage(
                                notif.data.action,
                                notif.data
                              )}
                          </p>
                        </div>

                        <div className="notification-date">
                          {moment(notif.createdAt.date).format("DD-MM-Y") ===
                            moment().format("DD-MM-Y")
                              ? moment(notif.createdAt.date)
                                  .locale(language)
                                  .fromNow()
                              : moment(notif.createdAt.date).format("DD-MM-Y")}
                        </div>

                        <div
                          className={`notification-actions ${showActionsMenuForItem === notif.id ? 'notification-actions-visible' : ''}`}
                          onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (showActionsMenuForItem === notif.id) {
                                setShowActionsMenuForItem(null);
                              } else {
                                setShowActionsMenuForItem(notif.id);
                              }
                            }}>
                          <MoreVerticalIcon color={showActionsMenuForItem === notif.id ? '#18A0FB' : '#29394D'} />
                          { showActionsMenuForItem === notif.id && (
                            <div className="notification-actions-menu">
                              <div className="notification-actions-menu-item notification-actions-menu-item-danger" onClick={() => handleDeleteNotification(notif.id)}>
                                Supprimer cette notification
                              </div>
                              <div className="notification-actions-menu-item">
                                Envoyer un message
                              </div>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </Link>
                  ))
              ) : (
                <div className="no_data_notif" style={{ width: 350 }}>
                  <div className="title">{_("noNotificationsFound")}</div>
                </div>
              )}
              {managerNotification &&
                managerNotification.nbrResult >
                  managerNotification.data.length && (
                  <div
                    className="fetch-more"
                    onClick={() => handleFetchNotifications()}
                  >
                    <IconPlus />
                  </div>
                )}
            </div>
          ) : (
            <div className="no_data_notif">
              <div className="title">{_("noNotificationsFound")}</div>
            </div>
          )}
        </div>
      )
    );
  };

  const renderNotificationItemIcon = (notificationItem) => {
    if (activeTab.tab === 'allNotifications') {
      const otherTabs = NOTIFICATION_TABS.filter(tab => tab.tab !== 'allNotifications')

      for (let i = 0; i < otherTabs.length; i++) {
        if (otherTabs[i]?.actions?.includes(notificationItem.data.action)) {
          return React.cloneElement(
            otherTabs[i]?.icon,
            {fill: notificationItem.status === "PENDING" ? "#18a0fb" : "#6D7F92"}
          )
        }
      }
    } else if (activeTab?.actions?.includes(notificationItem.data.action)) {
      if (activeTab.icon) {
        return React.cloneElement(
          activeTab?.icon,
          {fill: notificationItem.status === "PENDING" ? "#18a0fb" : "#6D7F92"}
        )
      }
    }
    return null;
  }

  return (
    <div className="page-content">
      <LigthPageHeader
        title={{
          label: "notifications",
        }}
      />
      <div className="content-notification">
        <div className="filter-tabs">{renderTabContent()}</div>

        <div className="content-frame">
          {renderFilterState()}

          <hr class="divider" />

          {renderListNotifications()}
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
