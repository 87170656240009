import React, { useState } from "react";
import _ from "../../i18n";
import { Tooltip as TooltipAntd } from "antd";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const CartesStatistics = ({ userPersonalDashboard, filter, updateFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState(filter.sortBy);

  const RADIAN = Math.PI / 180;
  const handleSortByClientChange = (items) => {
    setSelectedFilter(items);
    updateFilter({ ...filter, sortBy: items });
  };
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent > 0) {
      return (
        <text
          x={x}
          y={y}
          fill="#4169E1"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "12px" }}
        >
          {name}
        </text>
      );
    }
  };
  const getColor = (index) => {
    const colors = ["#ADD8E6", "#87CEEB", "#8CB7D7", "#C7E8FE"];
    return colors[index % colors.length];
  };
  const NumberDisplay = (number) => {
    const formattedNumber = parseFloat(number).toLocaleString("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return <div>{formattedNumber} €</div>;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="client-custom-tooltip" style={{ width: "110px" }}>
          <div
            className="title"
            style={{
              fontSize: "12px",
            }}
          >
            {payload[0].payload.name.length > 0
              ? payload[0].payload.name
              : "Autre"}
          </div>
          <div className="details" style={{ height: "20px" }}>
            <div
              style={{
                height: "18px",
                color:
                  payload[0].payload.name === "Real" ? "#4682B4" : "#4682B4",
              }}
            >
              {payload[0].payload.percentage} %
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div
        className="grid-x align-center"
        style={{
          width: "92%",
          maxWidth: "92%",
          margin: "auto",
          // overflowX: "auto",
          // whiteSpace: "nowrap",
          // flexFlow: "row",
          // overflow: "scroll",
        }}
      >
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            }`}
          >
            {userPersonalDashboard?.budgetByRoles && (
              <div>
                <div className="title_cartes">Mon Budget</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {userPersonalDashboard.budgetByRoles.budget_duration > 0 && (
                    <div style={{ width: "50%", height: "100%" }}>
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        min-width="100px"
                        minHeight="100px"
                      >
                        <PieChart>
                          <Pie
                            data={userPersonalDashboard.budgetChart}
                            cx="50%"
                            cy="50%"
                            outerRadius={40}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            fill="#8884d8"
                            stroke="none"
                            dataKey="percentage"
                          >
                            {userPersonalDashboard.budgetChart.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={getColor(index)}
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  <div
                    className="group_roles"
                    style={{
                      width:
                        userPersonalDashboard.budgetByRoles.budget_duration ===
                          0 && "80%",
                    }}
                  >
                    <div className="roles">
                      <span>Total:</span>
                      <span>
                        {userPersonalDashboard.budgetByRoles.budget_duration} h
                      </span>
                    </div>
                    {userPersonalDashboard.budgetByRoles.budget_supervisor >
                      0 && (
                      <div className="roles">
                        <span>Superviseur:</span>
                        <span>
                          {
                            userPersonalDashboard.budgetByRoles
                              .budget_supervisor
                          }
                          h
                        </span>
                      </div>
                    )}

                    {(userPersonalDashboard.budgetByRoles.budget_gd > 0 ||
                      userPersonalDashboard.budgetByRoles.budget_duration ===
                        0) && (
                      <div className="roles">
                        <span> GD:</span>
                        <span>
                          {userPersonalDashboard.budgetByRoles.budget_gd} h
                        </span>
                      </div>
                    )}
                    {(userPersonalDashboard.budgetByRoles.budget_ge > 0 ||
                      userPersonalDashboard.budgetByRoles.budget_duration ===
                        0) && (
                      <div className="roles">
                        <span>GE:</span>
                        <span>
                          {" "}
                          {userPersonalDashboard.budgetByRoles.budget_ge} h
                        </span>
                      </div>
                    )}
                    {(userPersonalDashboard.budgetByRoles.budget_se > 0 ||
                      userPersonalDashboard.budgetByRoles.budget_duration ===
                        0) && (
                      <div className="roles">
                        <span>SE:</span>
                        <span>
                          {userPersonalDashboard.budgetByRoles.budget_se} h
                        </span>
                      </div>
                    )}
                    <span style={{ margin: "auto" }}>
                      {
                        userPersonalDashboard.budgetByRoles
                          .budgetised_percentage
                      }
                      % dossier Budgétisé
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="cell medium-3">
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            }`}
            style={{
              background:
                selectedFilter === "OVER_VOLUMES_CLIENTS_DESC"
                  ? "linear-gradient(0deg, #ffffff, #ffffff), #d0fff6"
                  : "",
            }}
            onClick={() =>
              handleSortByClientChange("OVER_VOLUMES_CLIENTS_DESC")
            }
          >
            {userPersonalDashboard?.overbudgetFolders && (
              <div className="title_cartes">over budget Folders</div>
            )}
            {userPersonalDashboard?.overbudgetFolders &&
              userPersonalDashboard.overbudgetFolders.map((item) => {
                return (
                  <TooltipAntd
                    placement="top"
                    title={
                      <div>
                        <ul>
                          (Réalisé {parseFloat(item.total_duration).toFixed(2)}{" "}
                          / Budgétisé {parseFloat(item.total_budget).toFixed(2)}{" "}
                          ) * 100
                        </ul>
                      </div>
                    }
                  >
                    <div
                      className="roles"
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <div>{item.client_name}</div>
                      <div
                        className={`${
                          parseFloat(item.percentage).toFixed(2) > 100
                            ? "percentage-danger"
                            : ""
                        }`}
                      >
                        {parseFloat(item.percentage).toFixed(2)} %
                      </div>
                    </div>
                  </TooltipAntd>
                );
              })}
          </div>
        </div>
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            } 
            `}
            style={{
              background:
                selectedFilter === "PROFITABLE_CLIENTS_ASC"
                  ? "linear-gradient(0deg, #ffffff, #ffffff), #ffffff"
                  : "",
            }}
            onClick={() => handleSortByClientChange("PROFITABLE_CLIENTS_ASC")}
          >
            {userPersonalDashboard?.flopPerformanceeeOverbudgetFolders && (
              <div className="title_cartes">flop Performance</div>
            )}
            {userPersonalDashboard?.flopPerformanceeeOverbudgetFolders &&
              userPersonalDashboard.flopPerformanceeeOverbudgetFolders.map(
                (item) => {
                  return (
                    <TooltipAntd
                      placement="top"
                      title={
                        <div>
                          <ul>
                            CA{" "}
                            {item.sum_invoice
                              ? parseFloat(item.sum_invoice).toFixed(2)
                              : 0}{" "}
                            / H Réallisé{" "}
                            {parseFloat(item.total_duration).toFixed(2)}{" "}
                          </ul>
                        </div>
                      }
                    >
                      <div className="roles">
                        <div>{item.client_name}</div>
                        <div>
                          {item.percentage
                            ? parseFloat(item.percentage).toFixed(2)
                            : 0}
                          €/h
                        </div>
                      </div>
                    </TooltipAntd>
                  );
                }
              )}
          </div>
        </div>
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            }`}
          >
            {userPersonalDashboard?.clientsInfo && (
              <>
                <div className="title_cartes">clients informations</div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", height: "10px" }}>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                      min-width="100px"
                      minHeight="70px"
                    >
                      <PieChart>
                        <Pie
                          data={userPersonalDashboard.offshoreChart}
                          cx="50%"
                          cy="50%"
                          outerRadius={28}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          fill="#8884d8"
                          stroke="none"
                          dataKey="percentage"
                        >
                          {userPersonalDashboard.offshoreChart.map(
                            (entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name.length > 0
                                    ? getColor(index)
                                    : "#E1E4E8"
                                }
                              />
                            )
                          )}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                    </ResponsiveContainer>
                    <div className="roles roles_info">
                      <span>Offshore:</span>
                      <span>
                        {userPersonalDashboard?.clientsInfo.offshore_clients}
                      </span>
                    </div>
                    <div className="roles roles_info">
                      <span>Not Offshore:</span>
                      <span>
                        {
                          userPersonalDashboard?.clientsInfo
                            .not_offshore_clients
                        }
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "50%", height: "100%" }}>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                      min-width="100px"
                      minHeight="70px"
                    >
                      <PieChart>
                        <Pie
                          data={userPersonalDashboard.horusChart}
                          cx="50%"
                          cy="50%"
                          outerRadius={28}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          fill="#8884d8"
                          stroke="none"
                          dataKey="percentage"
                        >
                          {userPersonalDashboard.horusChart.map(
                            (entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name.length > 0
                                    ? getColor(index)
                                    : "#E1E4E8"
                                }
                              />
                            )
                          )}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                    </ResponsiveContainer>
                    <div className="roles roles_info">
                      <span>Horus:</span>
                      <span>
                        {userPersonalDashboard?.clientsInfo.clients_horus}
                      </span>
                    </div>
                    <div className="roles roles_info">
                      <span>éligible Horus:</span>
                      <span>
                        {
                          userPersonalDashboard?.clientsInfo
                            .clients_illiginle_horus
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            }`}
          >
            {userPersonalDashboard?.clientsInfo && (
              <>
                <div className="title_cartes">Chiffre d'affaire</div>
                <div className="chart">
                  <div style={{ width: "250px" }}>
                    <div className="item_progress_info">
                      <div>{_("economicBudget")}</div>
                      <div>
                        {userPersonalDashboard.globalInformations &&
                        userPersonalDashboard.globalInformations
                          .economic_budget > 0
                          ? NumberDisplay(
                              userPersonalDashboard.globalInformations
                                .economic_budget
                            )
                          : 0}
                      </div>
                    </div>
                    <div
                      style={{
                        height: "38px",
                        display: "flex",
                        marginTop: 5,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div className="item_progress_container">
                        <div
                          className="item_progress_bar"
                          style={{
                            width:
                              parseInt(
                                userPersonalDashboard.globalInformations
                                  .sum_invoices
                              ) >
                              parseInt(
                                userPersonalDashboard.globalInformations
                                  .economic_budget
                              )
                                ? "100%"
                                : parseInt(
                                    userPersonalDashboard.globalInformations
                                      .economic_budget
                                  ) > 0 &&
                                  parseInt(
                                    userPersonalDashboard.globalInformations
                                      .sum_invoices
                                  ) > 0
                                ? `${
                                    (parseInt(
                                      userPersonalDashboard.globalInformations
                                        .sum_invoices
                                    ) *
                                      100) /
                                    parseInt(
                                      userPersonalDashboard.globalInformations
                                        .economic_budget
                                    )
                                  }%`
                                : 0,
                            background:
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .economic_budget
                              ) > 0 &&
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .sum_invoices
                              ) /
                                parseFloat(
                                  userPersonalDashboard.globalInformations
                                    .economic_budget
                                ) >
                                80
                                ? "#06D9B1"
                                : "#EA8124",
                          }}
                        />
                        <div
                          className="item_progress_index"
                          style={{
                            width:
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .economic_budget
                              ) > 0 &&
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .sum_invoices
                              ) > 0
                                ? parseFloat(
                                    userPersonalDashboard.globalInformations
                                      .sum_invoices
                                  ) >
                                  parseFloat(
                                    userPersonalDashboard.globalInformations
                                      .economic_budget
                                  )
                                  ? `inherit`
                                  : `${
                                      (parseFloat(
                                        userPersonalDashboard.globalInformations
                                          .sum_invoices
                                      ) *
                                        200) /
                                      parseFloat(
                                        userPersonalDashboard.globalInformations
                                          .economic_budget
                                      )
                                    }%`
                                : "fit-content",
                            alignItems:
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .sum_invoices
                              ) >
                              parseFloat(
                                userPersonalDashboard.globalInformations
                                  .economic_budget
                              )
                                ? "end"
                                : "center",
                          }}
                        >
                          <TiArrowSortedDown />
                          <div>
                            {parseFloat(
                              userPersonalDashboard.globalInformations
                                .sum_invoices
                            ) > 0
                              ? NumberDisplay(
                                  userPersonalDashboard.globalInformations
                                    .sum_invoices
                                )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.budgetByRoles
                ? "gradiant_cartes"
                : "cartes"
            }`}
          >
            {userPersonalDashboard?.closingDates && (
              <>
                <div className="title_cartes">date de cloture</div>
                <div className="grid-x align-center">
                  {userPersonalDashboard?.closingDates &&
                    userPersonalDashboard.closingDates.map((item) => {
                      return (
                        <div
                          className={"cell medium-3 roles"}
                          style={{
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          <div>
                            {item.closing_date} : {item.count_closing_dates}{" "}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={"cell medium-3"}>
          <div
            className={`${
              !userPersonalDashboard?.closingDates ? "gradiant_cartes" : "cartes"
            }`}
          >
            {userPersonalDashboard.closingDates && (
              <>
                <div className="title_cartes">Statistique des dates</div>
                <div style={{ display: "flex" }}>
                  <div className="roles dates_info">
                    <span>Nombre de depart clients:</span>
                    <span>
                      {/* {userPersonalDashboard.datesStatistics.count_communicated_departure} */}
                      0
                    </span>
                  </div>
                  <div className="roles dates_info">
                    <span>Budget economique:</span>
                    <span>
                      {/* {userPersonalDashboard.datesStatistics.total_departure_economic_budget}*/}
                      {"0 €"}
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="roles dates_info">
                    <span>Nombres de nouveaux clients:</span>
                    <span>
                      0
                      {/* {userPersonalDashboard.datesStatistics.count_new_client} */}
                    </span>
                  </div>
                  <div className="roles dates_info">
                    <span>Budget economique:</span>
                    {"  "}
                    <span>
                      {/* {userPersonalDashboard.datesStatistics.total_start_economic_budget}  */}
                      {"0 €"}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartesStatistics;
