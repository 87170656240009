import React, { useState, useRef, Fragment } from "react";
import { useQuery } from "react-query";
import _ from "../../../../i18n";
import { Calendar } from "primereact/calendar";
import { ModalConfirm } from "tamtam-components";
import moment from "moment";

import {
  saveErrorReport,
  saveCommentPlan,
  saveCorrectionCard,
  saveCorrectionPlan,
  deleteCorrectionPlan,
  deleteCorrectionCard,
  deleteCommentPlan,
  getErrorReports,
  getCorrectionCards,
  getCollaboratorAvatars,
  getCorrectionCardReasons,
  getCategoriesSuggestions,
  getCorrectionCardAnomalies,
  getOrganizationCollaborators,
  sendGenericEmail,
  getEmailCollaborator,
} from "../../../../api";

import {
  useOnClickOutside,
  renderAvatar,
  setLocaleCalendarData,
  getTemporaryScreenShotFileLink,
} from "../../../../utils";
import { PRIORITY_LEVELS, TTP_UA_URL } from "../../../../config";
import {
  IconPlus,
  BellIcon,
  UserIcon,
  FlagIcon,
  ClockIcon,
  IconClose,
  IconTrash,
  CalendarIcon,
  IconPencil2,
  BarsIcon,
  BookMarkIcon,
  FrequanceIcon,
  IconChevronDown,
  SynchroRuningIcon,
} from "../../../common/Icons";
import { TiArrowSortedDown } from "react-icons/ti";
import { Tooltip } from "antd";

import AddNewCorrectionCardFrame from "./AddNewCorrectionCardFrame";
import FileReaderIframe from "./IFrameFileReader/FileReaderIframe";
import "./EncodingProgressionContent.local.scss";
import { useSelector } from "react-redux";

const IMPORTANCE_LEVELS = [
  { label: "low", level: 1 },
  { label: "moyen", level: 2 },
  { label: "high", level: 3 },
];
const CollaboratorCorrectionCard = ({
  lng,
  year,
  onClose,
  client,
  token,
  plan,
  category,
  loggedAs,
  organization,
}) => {
  const refResponsables = useRef();
  const refPlans = useRef();
  const refImportance = useRef();
  const refProgression = useRef();
  const refPriority = useRef();
  useOnClickOutside(refResponsables, () => setResponsablesDivIsVisible(false));
  useOnClickOutside(refPlans, () => setPlanDivIsVisible(false));
  useOnClickOutside(refImportance, () => setImportanceDivIsVisible(false));
  useOnClickOutside(refPriority, () => setPriorityDivIsVisible(false));
  useOnClickOutside(refProgression, () => setStepsDivIsVisible(false));

  //States
  const [planDivIsVisible, setPlanDivIsVisible] = useState(false);
  const [sortDivIsVisible, setSortDivIsVisible] = useState(false);
  const [stepsDivIsVisible, setStepsDivIsVisible] = useState(false);
  const [priorityDivIsVisible, setPriorityDivIsVisible] = useState(false);

  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedErrorReport, setSelectedErrorReport] = useState(null);
  const [selectedCategoryReason, setSelectedCategoryReason] = useState(null);
  const [importanceDivIsVisible, setImportanceDivIsVisible] = useState(false);
  const [responsablesDivIsVisible, setResponsablesDivIsVisible] =
    useState(false);
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] =
    useState(null);
  const [isDeletePlanModalOpen, setIsDeletePlanModalOpen] = useState(false);
  const [isDeleteResponsableModalOpen, setIsDeleteResponsableModalOpen] =
    useState(false);

  const [correctionCards, setCorrectionCards] = useState([]);
  const [anomalies, setAnomalies] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [errorReports, setErrorReports] = useState([]);
  const [cardComments, setCardComments] = useState([]);
  const [cardResponsables, setCardResponsables] = useState(
    client.title.responsables
  );

  const [commentsSortBy, setCommentsSortBy] = useState("moreRecent");
  const [collaborators, setCollaborators] = useState([]);
  const [categories, setCategories] = useState([]);
  const [avatarCollaborators, setAvatarCollaborators] = useState([]);
  const [collaboratorsKeyWord, setCollaboratorsKeyWord] = useState("");

  const [activeCard, setActiveCard] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [pendingReport, setPendingReport] = useState(null);
  const [tempCard, setTempCard] = useState(null);
  const [tempPlan, setTempPlan] = useState(null);
  const [tempComment, setTempComment] = useState(null);
  const [editedPlan, setEditedPlan] = useState(false);
  const [editedComment, setEditedComment] = useState(null);
  const [tab, setTab] = useState("CORRECTION_CARD");
  const [iframeConfig, setIframeConfig] = useState({
    url: "",
    hide: true,
    loading: false,
  });

  const [addErrorReport, setAddErrorReport] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [latestResponsables, setLatestResponsables] = useState([]);

  setLocaleCalendarData(lng);

  let categoriesQuery = useQuery(
    ["categoriesQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCategoriesSuggestions(organization, token);
          let categoriesResponse = response.data.data.map((item) => {
            return { id: item.id, label: item.label[lng] };
          });
          setCategories(categoriesResponse);
        } catch (e) {
          setCategories([]);
          return null;
        }
      }
    }
  );

  let collaboratorsQuery = useQuery(
    ["collaboratorsQuery", organization, collaboratorsKeyWord],
    async () => {
      if (token) {
        try {
          const response = await getOrganizationCollaborators(
            organization,
            year,
            token,
            null,
            collaboratorsKeyWord
          );
          let filtredCollaborator = response.data.data.filter(
            (collab) => collab !== null
          );

          setCollaborators(filtredCollaborator);
        } catch (e) {
          setCollaborators([]);
          return null;
        }
      }
    }
  );

  let collaboratorAvatarsQuery = useQuery(
    ["collaboratorAvatarsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorAvatars(organization, token);
          setAvatarCollaborators(response.data.data);
        } catch (e) {
          setAvatarCollaborators([]);
          return null;
        }
      }
    }
  );

  let correctionCardReasonsQuery = useQuery(
    ["correctionCardReasonsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCorrectionCardReasons(organization, token);
          setReasons(response.data.data);
        } catch (e) {
          setReasons([]);
          return null;
        }
      }
    }
  );

  let correctionCardAnomaliesQuery = useQuery(
    ["correctionCardAnomaliesQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCorrectionCardAnomalies(
            organization,
            token
          );
          setAnomalies(response.data.data);
        } catch (e) {
          setAnomalies([]);
          return null;
        }
      }
    }
  );

  const result = useQuery({
    queryKey: ["getAllPlans", organization],
    queryFn: async () => {
      if (token) {
        try {
          const response = await Promise.all([
            getCorrectionCards(client.id, year, organization, token),
            getErrorReports(client.id, year, organization, token),
          ]);
          const [responseCorrectionCards, responseErrorReports] =
            await response;
          let errorReportsData = responseErrorReports.data.data;
          let correctionCardsData = responseCorrectionCards.data.data;

          if (correctionCardsData.length + errorReportsData.length === 0) {
            setAddCard(true);
          } else {
            if (errorReportsData.length > 0) {
              setSelectedErrorReport(errorReportsData[0]);
              setErrorReports(errorReportsData);
              setTab("ERROR_REPORT");
              let pendingReports = errorReportsData.filter(
                (report) => report.status === "PENDING"
              );
              if (pendingReports.length > 0) {
                setPendingReport(pendingReports[0]);
              }
            }
            if (correctionCardsData.length > 0) {
              setTab("CORRECTION_CARD");
              setCorrectionCards(correctionCardsData);
              if (plan) {
                let localPlan = null;
                let localCard = null;
                correctionCardsData.forEach((card) => {
                  card.plans.forEach((planCard) => {
                    if (planCard.id === plan) {
                      localPlan = planCard;
                      localCard = card;
                    }
                  });
                });
                let indexedPlans = localCard.plans.map((plan, index) => {
                  if (plan.id === localPlan.id) {
                    localPlan = {
                      ...localPlan,
                      index: localCard.plans.length - index - 1,
                    };
                  }
                  return { ...plan, index: localCard.plans.length - index - 1 };
                });
                localCard = { ...localCard, plans: indexedPlans };
                setActivePlan({
                  ...localPlan,
                  deadLine: moment(localPlan.deadLine, "Y-MM-DD HH:mm:ss")._d,
                });
                setActiveCard(localCard);
                prepareResponsableCards(localCard);
                prepareCommentsCard(localCard);
              } else {
                let localCard = correctionCardsData[0];
                let indexedPlans = localCard.plans.map((plan, index) => {
                  return { ...plan, index: localCard.plans.length - index - 1 };
                });
                localCard = { ...localCard, plans: indexedPlans };
                setActivePlan({
                  ...localCard.plans[0],
                  deadLine: moment(
                    localCard.plans[0].deadLine,
                    "Y-MM-DD HH:mm:ss"
                  )._d,
                });
                setActiveCard(localCard);
                prepareResponsableCards(localCard);
                prepareCommentsCard(localCard);
              }
            } else {
              let pendingReports = errorReportsData.filter(
                (report) => report.status === "PENDING"
              );
              if (pendingReports.length > 0) {
                setAddCard(true);
                setAddErrorReport(true);
              }
            }
          }
        } catch (e) {
          return [];
        }
      }
    },
  });

  const handleSaveCard = async (item) => {
    try {
      const response = await saveCorrectionCard({
        id: null,
        token,
        organization,
        card: {
          user: user.id,
          client: client.id,
          anomaly: item.anomaly.id,
          reason: item.reason.id,
          category: category ? category.id : null,
          year: moment().year(),
          startDate: moment(),
          endDate: moment(),
          plans: item.plans,
        },
      });

      let cardResponse = response.data.data;
      let newCards = [...correctionCards];
      let indexedPlans = cardResponse.plans.map((plan, index) => {
        return { ...plan, index: cardResponse.plans.length - index - 1 };
      });
      cardResponse = { ...cardResponse, plans: indexedPlans };
      newCards.unshift(cardResponse);
      setActivePlan({
        ...cardResponse.plans[0],
        deadLine: moment(cardResponse.plans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
      });
      prepareResponsableCards(cardResponse);
      setActiveCard(cardResponse);
      setCorrectionCards(newCards);
      setTempPlan(null);
      setTempCard(null);
      // toast.success(_("saveSuccess"));

      const recipients = {};
      const dynamicFields = {};
      const responsables = item.plans[0].responsables;

      // Itérer sur chaque responsable et obtenir l'email
      const promises = responsables.map(async (responsable) => {
        try {
          recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
          dynamicFields["{{RECIPIENT}}"] = responsable.name;
          dynamicFields[
            "{{OBJET}}"
          ] = `Un plan de correction concernant le dossier <u>${client.title.label}</u> vous a été assigné.`;
          dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
          dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
          dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
        } catch (error) {
          return null;
        }
      });

      await Promise.all(promises);
      console.log("send email==========");
      // Exécuter la deuxième requête uniquement si la première a réussi
      await sendGenericEmail(
        token,
        recipients,
        "POWERTEAM_NOTIFICATION",
        lng,
        dynamicFields,
        organization
      );
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      console.error(
        "Erreur lors de l'enregistrement de la carte ou de l'envoi de l'email:",
        e
      );
      return null;
    }
  };

  const handleSaveErrorReport = async (item) => {
    try {
      const response = await saveErrorReport({
        token,
        organization,
        report: {
          responsable: item.responsable.id,
          client: client.id,
          errors: item.errors,
          year: moment().year(),
          state: item.state,
          id: item.id,
        },
        files: item.files,
      });
      let errorsResponse = response.data.data;
      if (item.id) {
        setSelectedErrorReport(errorsResponse);
        let newErrorReports = [...errorReports];
        newErrorReports = newErrorReports.map((report) => {
          return report.id === item.id ? errorsResponse : report;
        });
        setErrorReports(newErrorReports);
      } else {
        setSelectedErrorReport(errorsResponse);
        let newErrorReports = [...errorReports];
        newErrorReports.push(errorsResponse);
        setErrorReports(newErrorReports);
      }
      if (item.state === "DONE") {
        setPendingReport(null);
        setAddCard(false);
      } else {
        setPendingReport(errorsResponse);
      }
    } catch (e) {
      return null;
    }
  };

  const handleSavePlan = async (item) => {
    try {
      const response = await saveCorrectionPlan({
        token,
        organization,
        plan: item.plan,
        card: item.card,
        user: user ? user.firstName + " " + user.lastName : "",
      });
      let planResponse = response.data.data;
      let newPlans = [];
      if (item.plan.id) {
        newPlans = activeCard.plans.map((plan) => {
          if (plan.id === item.plan.id) {
            planResponse = { ...plan, ...planResponse };
            return planResponse;
          } else {
            return plan;
          }
        });
        setActiveCard({ ...activeCard, plans: newPlans });
        setActivePlan({
          ...planResponse,
          deadLine: moment(planResponse.deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
        setEditedPlan(null);
        let newCards = correctionCards.map((card) => {
          if (card.id === activeCard.id) {
            return { ...card, plans: newPlans };
          } else {
            return card;
          }
        });
        setCorrectionCards(newCards);
        prepareResponsableCards({ ...activeCard, plans: newPlans });
      } else {
        newPlans = [...activeCard.plans];
        planResponse = { ...planResponse, index: activeCard.plans.length };
        newPlans.unshift(planResponse);
        let newCards = correctionCards.map((card) => {
          if (card.id === item.card) {
            let newPlansLocal = [...card.plans];
            newPlansLocal.unshift(planResponse);
            return { ...card, plans: newPlansLocal };
          } else {
            return card;
          }
        });
        setCorrectionCards(newCards);
        setAddPlan(false);
        setTempCard(null);
        setTempPlan(null);
      }
      setActiveCard({ ...activeCard, plans: newPlans });
      setActivePlan({
        ...planResponse,
        deadLine: moment(planResponse.deadLine, "Y-MM-DD HH:mm:ss")._d,
      });
      prepareResponsableCards({ ...activeCard, plans: newPlans });
      // toast.success(_("saveSuccess"));

      const recipients = {};
      const dynamicFields = {};

      // Si le plan contient de nouveaux responsables (latest), on les prend ; sinon, on prend les responsables du nouveau plan créé
      const responsables =
        item.plan?.latestResponsables ?? item.plan?.responsables ?? [];

      if (responsables && responsables.length > 0) {
        // Itérer sur chaque responsable et obtenir l'email
        const promises = responsables.map(async (responsable) => {
          try {
            recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
            dynamicFields["{{RECIPIENT}}"] = responsable.name;
            dynamicFields[
              "{{OBJET}}"
            ] = `Un plan de correction concernant le dossier <u>${client.title.label}</u> vous a été assigné.`;
            dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
            dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
            dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
          } catch (error) {
            return null;
          }
        });

        await Promise.all(promises);

        const secondResponse = await sendGenericEmail(
          token,
          recipients,
          "POWERTEAM_NOTIFICATION",
          lng,
          dynamicFields,
          organization
        );

        setLatestResponsables([]);
      }
    } catch (e) {
      console.error("Error in handleSavePlan:", e);
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleSaveComment = async (item) => {
    try {
      const response = await saveCommentPlan({
        token,
        organization,
        plan: item.plan ? item.plan.id : null,
        card: item.plan ? null : activeCard.id,
        comment: {
          code: item.code,
          message: item.message,
          importance: item.importance,
        },
        user: user ? user.firstName + " " + user.lastName : "",
      });
      let commentResponse = response.data.data;
      let newComments = [];
      if (item.code) {
        newComments = cardComments.map((comment) => {
          if (comment.code === commentResponse.code) {
            return { ...item, ...commentResponse };
          }
          return comment;
        });
        setEditedComment(null);
      } else {
        newComments = [...cardComments];
        newComments.unshift({ ...item, ...commentResponse });
        setTempComment(null);
      }
      setCardComments(newComments);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteComment = async (item) => {
    try {
      const response = await deleteCommentPlan({
        token,
        organization,
        code: item.code,
        plan: item.plan ? item.plan.id : null,
        card: item.card ? item.card.id : null,
      });
      let commentResponse = response.data.data;
      let newComments = [];
      newComments = cardComments.filter((comment) => {
        return comment.code !== commentResponse.code;
      });
      setCardComments(newComments);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const deleteCorrectionCardPlan = async () => {
    try {
      const response = await deleteCorrectionPlan({
        token,
        organization,
        plan: activePlan.id,
      });
      let planResponse = response.data.data;
      if (activeCard.plans.length > 1) {
        let index = activeCard.plans.length - 1;
        let newPlans = activeCard.plans
          .filter((plan) => plan.id !== planResponse.plan)
          .map((plan) => {
            index--;
            return { ...plan, index };
          });
        setActivePlan({
          ...newPlans[0],
          deadLine: moment(newPlans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
        setActiveCard({ ...activeCard, plans: newPlans });
        let newCards = correctionCards.map((card) => {
          if (activeCard.id === card.id) {
            return { ...card, plans: newPlans };
          } else {
            return card;
          }
        });
        setCorrectionCards(newCards);
        prepareResponsableCards({ ...activeCard, plans: newPlans });
      } else {
        if (correctionCards.length > 1) {
          let newCards = correctionCards.filter(
            (card) => card.id !== activeCard.id
          );
          let indexedPlans = newCards[0].plans.map((plan, index) => {
            return { ...plan, index: newCards[0].plans.length - 1 - index };
          });
          setCorrectionCards(newCards);
          setActiveCard({ ...newCards[0], plans: indexedPlans });
          setActivePlan({
            ...indexedPlans[0],
            deadLine: moment(indexedPlans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
          });
        } else {
          setAddCard(true);
          setActiveCard(null);
          setActivePlan(null);
          setCorrectionCards([]);
        }
      }
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteCorrectionCard = async (id) => {
    try {
      const response = await deleteCorrectionCard({
        token,
        organization,
        card: id,
      });
      let cardResponse = response.data.data;
      if (correctionCards.length > 1) {
        let newCards = correctionCards.filter(
          (card) => card.id !== cardResponse.card
        );
        setCorrectionCards(newCards);
        let indexedPlans = newCards[0].plans.map((plan, index) => {
          return { ...plan, index: newCards[0].plans.length - 1 - index };
        });
        setActiveCard({ ...newCards[0], plans: indexedPlans });
        setActivePlan({
          ...indexedPlans[0],
          deadLine: moment(indexedPlans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
      } else {
        setAddCard(true);
        setActiveCard(null);
        setActivePlan(null);
        setCorrectionCards([]);
      }
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  let loading =
    result.isFetching ||
    correctionCardReasonsQuery.isFetching ||
    correctionCardAnomaliesQuery.isFetching;

  const renderCorrectionCardsFrame = () => {
    return (
      <Fragment>
        <div style={{ width: addCard ? "" : "70%" }}>
          <div className="tags">
            {!addCard &&
              !addPlan &&
              activeCard &&
              activeCard.year == moment().year() && (
                <div
                  onClick={() => {
                    setAddPlan(true);
                    setActivePlan(null);
                    setTempPlan({
                      title: "NEW PLAN",
                      responsables: [],
                      priorityLevel: { label: "MEDIUM", step: "medium" },
                      progression: { label: "TODO", step: "todo" },
                    });
                  }}
                >
                  <IconPlus fill="#6D7F92" />
                </div>
              )}
            {addPlan && !activePlan && (
              <div className="tag success">{`PLAN ${
                activeCard.plans.length + 1
              }`}</div>
            )}
            {activeCard &&
              activeCard.plans.map((item, index) => (
                <div
                  className={
                    "tag" +
                    " " +
                    (activePlan && item.id === activePlan.id ? "active" : "")
                  }
                  onClick={() => {
                    setActivePlan({
                      ...item,
                      deadLine: moment(item.deadLine, "Y-MM-DD HH:mm:ss")._d,
                    });
                    if (editedPlan) {
                      setEditedPlan(null);
                    }
                  }}
                >
                  <div className={`dot ${item.progression}_dot`} />
                  {`PLAN ${item.index + 1}`}
                </div>
              ))}
            {activePlan && (
              <div
                className="action"
                onClick={() => {
                  setIsDeletePlanModalOpen(true);
                }}
              >
                <IconTrash size={12} />
              </div>
            )}
          </div>
          {addPlan && !activePlan && (
            <div className="light_frame">
              <div>
                <div className="frame">
                  <div className="title">{_("Plan de correction")}</div>
                  <div className="set">
                    <label>{_("title")} *</label>
                    <input
                      value={tempPlan.title}
                      onChange={(event) =>
                        setTempPlan({
                          ...tempPlan,
                          title: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="set">
                    <label>{_("description")}</label>
                    <textarea
                      value={tempPlan.description}
                      onChange={(event) =>
                        setTempPlan({
                          ...tempPlan,
                          description: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="panel">
                  <div className="set">
                    <label>{_("responsable")} *</label>
                    <div
                      ref={refResponsables}
                      className={
                        "rectangular_select" +
                        " " +
                        (responsablesDivIsVisible
                          ? "rectangular_select_opened"
                          : "")
                      }
                    >
                      <div
                        className={"rectangular_select_header"}
                        onClick={() =>
                          setResponsablesDivIsVisible(!responsablesDivIsVisible)
                        }
                      >
                        <span>
                          {tempPlan.responsables.length > 0
                            ? tempPlan.responsables[0].name
                            : ""}
                        </span>
                        <TiArrowSortedDown />
                      </div>
                      {responsablesDivIsVisible && (
                        <div className={"rectangular_select_body"}>
                          <input
                            onChange={(e) =>
                              setCollaboratorsKeyWord(e.target.value)
                            }
                          />
                          <div className={"rectangular_select_body_options"}>
                            {collaborators.map((item, index) => {
                              if (!item) {
                                return;
                              }
                              return (
                                <div
                                  key={index}
                                  className={"rectangular_select_body_option"}
                                  onClick={() => {
                                    setTempPlan({
                                      ...tempPlan,
                                      responsables: [item],
                                    });
                                    setResponsablesDivIsVisible(false);
                                  }}
                                >
                                  <div className="avatar" key={index}>
                                    {renderAvatar(
                                      item && avatarCollaborators[item.uid]
                                        ? avatarCollaborators[item.uid]
                                            .avatarUrl
                                        : null,
                                      item.name
                                    )}
                                  </div>
                                  {item.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="set">
                    <label>{_("deadLine")}</label>
                    <div className={"calendar_select"}>
                      <Calendar
                        showIcon
                        dateFormat="dd/mm/yy"
                        locale={lng}
                        value={tempPlan.deadLine ? tempPlan.deadLine : moment()}
                        className="p-datepicker-sidebar"
                        panelClassName="large_calendar"
                        onChange={(e) =>
                          setTempPlan({
                            ...tempPlan,
                            deadLine: e.value,
                          })
                        }
                        icon={<CalendarIcon fill="#6D7F92" />}
                      />
                    </div>
                  </div>
                  <div className="set">
                    <label>{_("priorityLevel")}</label>
                    <div
                      // ref={refPriority}
                      className={
                        "rectangular_select " +
                        (priorityDivIsVisible
                          ? "rectangular_select_opened"
                          : "")
                      }
                      style={{ marginBottom: 23 }}
                    >
                      <div
                        className={`rectangular_select_header ${"hight_priority"}`}
                        style={{ border: "none" }}
                        onClick={() =>
                          setPriorityDivIsVisible(!priorityDivIsVisible)
                        }
                      >
                        <span>{tempPlan.priorityLevel.label}</span>
                        <TiArrowSortedDown />
                      </div>
                      {priorityDivIsVisible && (
                        <div
                          className={"rectangular_select_body"}
                          style={{
                            border: "none",
                            width: 290,
                            boxShadow: "none",
                          }}
                        >
                          <div className={"rectangular_select_body_options"}>
                            {PRIORITY_LEVELS.map((item, index) => (
                              <div
                                key={index}
                                className={`rectangular_select_body_option ${item.value}_priority`}
                                onClick={() => {
                                  setTempPlan({
                                    ...tempPlan,
                                    priorityLevel: item,
                                  });
                                  setPriorityDivIsVisible(false);
                                }}
                              >
                                {item.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="footer"
                style={{ width: addCard ? "70%" : "53%" }}
              >
                <div
                  className="action_left"
                  onClick={() => {
                    setAddPlan(false);
                    setActivePlan({
                      ...activeCard.plans[0],
                      deadLine: moment(
                        activeCard.plans[0].deadLine,
                        "Y-MM-DD HH:mm:ss"
                      )._d,
                    });
                  }}
                >
                  {_("cancel")}
                </div>
                <div
                  className={
                    "action_right" +
                    " " +
                    (tempPlan.title && tempPlan.responsables.length > 0
                      ? ""
                      : "disabled")
                  }
                  onClick={() => {
                    if (
                      tempPlan.title === "" ||
                      !tempPlan.title ||
                      tempPlan.responsables.length === 0
                    ) {
                      return;
                    } else {
                      handleSavePlan({
                        card: activeCard.id,
                        plan: {
                          title: tempPlan.title,
                          priorityLevel: tempPlan.priorityLevel.label,
                          progression: tempPlan.progression.label,
                          description: tempPlan.description,
                          deadLine:
                            tempPlan.deadLine instanceof Date &&
                            !isNaN(tempPlan.deadLine)
                              ? moment(tempPlan.deadLine).format(
                                  "Y-MM-DD HH:mm:ss"
                                )
                              : null,
                          responsables: tempPlan.responsables,
                        },
                      });
                      setAddCard(false);
                    }
                  }}
                >
                  {_("save")}
                </div>
              </div>
            </div>
          )}
          <div className="heavy_frame">
            {activePlan && !addPlan && (
              <div>
                <div className="frame">
                  <div className="frame_title">
                    {editedPlan ? (
                      <input
                        value={editedPlan.title}
                        onChange={(event) => {
                          setEditedPlan({
                            ...editedPlan,
                            title: event.target.value,
                          });
                        }}
                      />
                    ) : (
                      <div className="title">{activePlan.title}</div>
                    )}
                    {!editedPlan && (
                      <div
                        className="smallAction"
                        onClick={() => setEditedPlan(activePlan)}
                      >
                        {_("edit")}
                      </div>
                    )}
                  </div>
                  {editedPlan ? (
                    <textarea
                      value={editedPlan.description}
                      onChange={(event) => {
                        setEditedPlan({
                          ...editedPlan,
                          description: event.target.value,
                        });
                      }}
                    />
                  ) : (
                    <div className="b-frame">
                      {activePlan?.description &&
                        activePlan.description.length > 0 && (
                          <p>{activePlan.description}</p>
                        )}
                    </div>
                  )}
                  {/* <div className="frame_title">
                <div className="title title_sub">{_("Attachement")}</div>
                <div className="smallAction">{_("import")}</div>
              </div>
              <div className="doted_items">
                <div className="doted_item">
                  <span>Nom document attachement.pdf</span>
                  <div className="actions">
                    <span onClick={() => null}>
                      <DownloadIcon />
                    </span>
                    <span onClick={() => null}>
                      <IconTrash size={11} />
                    </span>
                  </div>
                </div>
                <div className="doted_item">Nom document 2 attachement.pdf</div>
                <div className="doted_item">Nom document 3 attachement.pdf</div>
              </div> */}
                  <div className="frame_title">
                    <FrequanceIcon />
                    <div className="title title_sub">{_("activities")}</div>
                  </div>
                  <div className="time_line">
                    {activePlan.activities &&
                      activePlan.activities.map((item, index) => (
                        <div className="event">
                          <div className="at">
                            <span>{item.at.split(".")[0]}</span>
                            <span>{item.at.split(".")[1]}</span>
                          </div>
                          <div className="line">
                            <div className="dot" />
                          </div>
                          <div className="details">
                            <span>{item.user}</span>
                            {renderActivityMessage(item.action, item.data)}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="panel">
                  <div className="title">
                    <UserIcon />
                    <span>{_("planResponsable")}</span>
                  </div>
                  <div
                    ref={refResponsables}
                    className={
                      "rectangular_select" +
                      " " +
                      (responsablesDivIsVisible
                        ? "rectangular_select_opened"
                        : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() =>
                        setResponsablesDivIsVisible(!responsablesDivIsVisible)
                      }
                    >
                      <span>{_("choose")}</span>
                      <TiArrowSortedDown />
                    </div>
                    {responsablesDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <input
                          onChange={(e) =>
                            setCollaboratorsKeyWord(e.target.value)
                          }
                        />
                        <div className={"rectangular_select_body_options"}>
                          {collaborators.map((item, index) => {
                            if (!item) {
                              return;
                            }
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setLatestResponsables([
                                    ...latestResponsables,
                                    item,
                                  ]);
                                  let newResponsables = editedPlan
                                    ? [...editedPlan.responsables]
                                    : [...activePlan.responsables];
                                  const isAlreadyAdded = newResponsables.some(
                                    (responsable) => responsable.id === item.id
                                  );
                                  if (!isAlreadyAdded) {
                                    newResponsables.push(item);
                                    setEditedPlan(
                                      editedPlan
                                        ? {
                                            ...editedPlan,
                                            responsables: newResponsables,
                                          }
                                        : {
                                            ...activePlan,
                                            responsables: newResponsables,
                                          }
                                    );
                                  }
                                  setResponsablesDivIsVisible(false);
                                }}
                              >
                                <div className="avatar" key={index}>
                                  {renderAvatar(
                                    item && avatarCollaborators[item.uid]
                                      ? avatarCollaborators[item.uid].avatarUrl
                                      : null,
                                    item.name
                                  )}
                                </div>
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="list_users">
                    {(editedPlan
                      ? editedPlan.responsables
                      : activePlan.responsables
                    ).map((item, index) => (
                      <div key={index} className={"user"}>
                        <div className="avatar" key={index}>
                          {renderAvatar(
                            item && avatarCollaborators[item.uid]
                              ? avatarCollaborators[item.uid].avatarUrl
                              : null,
                            item.name
                          )}
                        </div>
                        <div className="infos">
                          <span>{item.name}</span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            let newResponsables = editedPlan
                              ? [...editedPlan.responsables]
                              : [...activePlan.responsables];
                            if (newResponsables.length === 1) {
                              setIsDeleteResponsableModalOpen(true);
                            } else {
                              newResponsables = newResponsables.filter(
                                (resp) => item.uid !== resp.uid
                              );
                              setLatestResponsables(
                                latestResponsables.filter(
                                  (resp) => item.uid !== resp.uid
                                )
                              );
                              setEditedPlan(
                                editedPlan
                                  ? {
                                      ...editedPlan,
                                      responsables: newResponsables,
                                    }
                                  : {
                                      ...activePlan,
                                      responsables: newResponsables,
                                    }
                              );
                            }
                          }}
                        >
                          <IconTrash size={11} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="title">
                    <ClockIcon />
                    <span>{_("deadLine")}</span>
                  </div>
                  <div className={"rectangular_select"} style={{ width: 179 }}>
                    <Calendar
                      showIcon
                      dateFormat="dd/mm/yy"
                      locale={lng}
                      className="p-datepicker-sidebar"
                      value={
                        editedPlan ? editedPlan.deadLine : activePlan.deadLine
                      }
                      onChange={(e) => {
                        editedPlan
                          ? setEditedPlan({
                              ...editedPlan,
                              deadLine: e.value,
                            })
                          : setEditedPlan({
                              ...activePlan,
                              deadLine: e.value,
                            });
                      }}
                      icon={<CalendarIcon fill="#6D7F92" />}
                    ></Calendar>
                  </div>
                  <div className="list_tags">
                    {[15, 30, 45].map((item, index) => (
                      <div
                        key={index}
                        className={"tag"}
                        onClick={() => {
                          let date = moment(
                            editedPlan
                              ? editedPlan.deadLine
                              : activePlan.deadLine
                          );
                          let newDate = date.add(item, "days")._d;
                          editedPlan
                            ? setEditedPlan({
                                ...editedPlan,
                                deadLine: newDate,
                              })
                            : setEditedPlan({
                                ...activePlan,
                                deadLine: newDate,
                              });
                        }}
                      >
                        +{item}j.
                      </div>
                    ))}
                  </div>
                  <div className="title">
                    <BookMarkIcon fill="#29394D" />
                    <span>{_("progression")}</span>
                  </div>
                  <div
                    ref={refProgression}
                    className={
                      "rectangular_select " +
                      (stepsDivIsVisible ? "rectangular_select_opened" : "")
                    }
                    style={{ marginBottom: 23 }}
                  >
                    <div
                      className={`rectangular_select_header ${
                        editedPlan
                          ? editedPlan.progression
                          : activePlan.progression
                      }_step`}
                      style={{ border: "none" }}
                      onClick={() => setStepsDivIsVisible(!stepsDivIsVisible)}
                    >
                      <span>
                        {_(
                          editedPlan
                            ? editedPlan.progression
                            : activePlan.progression
                        )}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {stepsDivIsVisible && (
                      <div
                        className={"rectangular_select_body"}
                        style={{
                          border: "none",
                          background: `${"moyen"}_state`,
                          width: 178,
                          boxShadow: "none",
                        }}
                      >
                        <div className={"rectangular_select_body_options"}>
                          {[
                            { label: "TODO", step: "todo" },
                            { label: "WIP", step: "wip" },
                            { label: "VALIDATION", step: "validation" },
                            { label: "DONE", step: "done" },
                          ].map((item, index) => {
                            if (
                              item.step === "done" &&
                              loggedAs !== "MANAGER"
                            ) {
                              return;
                            } else {
                              return (
                                <div
                                  key={index}
                                  className={`rectangular_select_body_option ${item.label}_step`}
                                  onClick={() => {
                                    editedPlan
                                      ? setEditedPlan({
                                          ...editedPlan,
                                          progression: item.label,
                                        })
                                      : setEditedPlan({
                                          ...activePlan,
                                          progression: item.label,
                                        });
                                    setStepsDivIsVisible(false);
                                  }}
                                >
                                  {_(item.label)}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="title">
                    <BellIcon />
                    <span>{_("priorityLevel")}</span>
                  </div>
                  <div
                    ref={refPriority}
                    className={
                      "rectangular_select " +
                      (priorityDivIsVisible ? "rectangular_select_opened" : "")
                    }
                    style={{ marginBottom: 23 }}
                  >
                    <div
                      className={`rectangular_select_header ${"hight_priority"}`}
                      style={{ border: "none" }}
                      onClick={() =>
                        setPriorityDivIsVisible(!priorityDivIsVisible)
                      }
                    >
                      <span>
                        {_(
                          editedPlan
                            ? editedPlan.priorityLevel
                            : activePlan.priorityLevel
                        )}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {priorityDivIsVisible && (
                      <div
                        className={"rectangular_select_body"}
                        style={{
                          border: "none",
                          width: 178,
                          boxShadow: "none",
                        }}
                      >
                        <div className={"rectangular_select_body_options"}>
                          {[
                            { label: "URGENT", step: "hight" },
                            { label: "MEDIUM", step: "medium" },
                            { label: "LOW", step: "low" },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className={`rectangular_select_body_option ${item.step}_priority`}
                              onClick={() => {
                                editedPlan
                                  ? setEditedPlan({
                                      ...editedPlan,
                                      priorityLevel: item.label,
                                    })
                                  : setEditedPlan({
                                      ...activePlan,
                                      priorityLevel: item.label,
                                    });
                                setPriorityDivIsVisible(false);
                              }}
                            >
                              {_(item.label)}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {editedPlan && (
            <div className="footer">
              <div
                className="action_left"
                onClick={() => {
                  setEditedPlan(null);
                }}
              >
                {_("cancel")}
              </div>
              <div
                className={
                  "action_right" +
                  " " +
                  (editedPlan.title === "" ||
                  (editedPlan.responsables &&
                    editedPlan.responsables.length === 0)
                    ? "disabled"
                    : "")
                }
                onClick={() => {
                  if (
                    editedPlan.title === "" ||
                    (editedPlan.responsables &&
                      editedPlan.responsables.length === 0)
                  ) {
                    return;
                  } else {
                    handleSavePlan({
                      plan: {
                        ...editedPlan,
                        deadLine:
                          editedPlan.deadLine instanceof Date &&
                          !isNaN(editedPlan.deadLine)
                            ? moment(editedPlan.deadLine).format(
                                "Y-MM-DD HH:mm:ss"
                              )
                            : null,
                      },
                      responsables: editedPlan.responsables,
                      latestResponsables: latestResponsables,
                    });
                  }
                }}
              >
                {_("save")}
              </div>
            </div>
          )}
        </div>
        {!addCard && (
          <div className="pannel">
            <div className="pannel_header">
              <label>{_("remarks")} : </label>
              <div
                className="action"
                onClick={() => setSortDivIsVisible(!sortDivIsVisible)}
              >
                <div className="action_header">
                  <span>{_(commentsSortBy)}</span>
                  <IconChevronDown width="10" height="6" />
                </div>
                {sortDivIsVisible && (
                  <div className="action_body">
                    {["moreRecent", "morePrioritaire"].map((item) => (
                      <div
                        className="option"
                        onClick={() => {
                          setCommentsSortBy(item);
                          setCardComments(sortCommentsCard(cardComments, item));
                        }}
                      >
                        {_(item)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {cardComments.length > 0 && (
              <div className="infos-set">
                <div className="comments">
                  {cardComments.map((remark) => {
                    return editedComment &&
                      editedComment.code === remark.code ? (
                      <div className="textarea-form">
                        <div className="set">
                          <span>Nv. Importance : </span>
                          <div
                            ref={refImportance}
                            className={
                              "rectangular_select" +
                              " " +
                              (importanceDivIsVisible
                                ? "rectangular_select_opened"
                                : "")
                            }
                          >
                            <div
                              className={"rectangular_select_header"}
                              onClick={() =>
                                setImportanceDivIsVisible(
                                  !importanceDivIsVisible
                                )
                              }
                            >
                              <span>
                                {
                                  IMPORTANCE_LEVELS[
                                    editedComment.importance - 1
                                  ].label
                                }
                              </span>
                              <TiArrowSortedDown />
                            </div>
                            {importanceDivIsVisible && (
                              <div className={"rectangular_select_body"}>
                                <div
                                  className={"rectangular_select_body_options"}
                                >
                                  {IMPORTANCE_LEVELS.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          "rectangular_select_body_option"
                                        }
                                        onClick={() => {
                                          setEditedComment({
                                            ...editedComment,
                                            importance: item.level,
                                          });
                                          setImportanceDivIsVisible(false);
                                        }}
                                      >
                                        {_(item.label)}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="set">
                          <span>Afficher sur : </span>
                          <div
                            ref={refPlans}
                            className={
                              "rectangular_select" +
                              " " +
                              (planDivIsVisible
                                ? "rectangular_select_opened"
                                : "")
                            }
                          >
                            <div
                              className={"rectangular_select_header"}
                              onClick={() =>
                                setPlanDivIsVisible(!planDivIsVisible)
                              }
                            >
                              <span>
                                {editedComment.plan && editedComment.plan.name}
                              </span>
                              <TiArrowSortedDown />
                            </div>
                            {planDivIsVisible && (
                              <div className={"rectangular_select_body"}>
                                <div
                                  className={"rectangular_select_body_options"}
                                >
                                  {activeCard.plans.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          "rectangular_select_body_option"
                                        }
                                        onClick={() => {
                                          setTempComment({
                                            ...tempComment,
                                            card: null,
                                            plan: {
                                              id: item.id,
                                              name: item.name,
                                            },
                                          });
                                          setPlanDivIsVisible(false);
                                        }}
                                      >
                                        {editedComment.plans.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <textarea
                          value={editedComment.message}
                          onChange={(e) =>
                            setEditedComment({
                              ...editedComment,
                              message: e.target.value,
                            })
                          }
                        />
                        <div className="actions">
                          <div
                            className="default"
                            onClick={() => setEditedComment(null)}
                          >
                            <IconClose size="10" fill="#6D7F92" />
                          </div>
                          <div
                            className="premium"
                            onClick={() => handleSaveComment(editedComment)}
                          >
                            {_("Edit")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="comment">
                        <div className="comment_header">
                          <div className="comment_user">
                            <div className="avatar" key={"item.uid"}>
                              {renderAvatar(null, remark.user)}
                            </div>
                            <div className="comment_user_details">
                              <span>{remark.user}</span>
                              <span>GD</span>
                            </div>
                          </div>
                          <div className="actions">
                            <div onClick={() => setEditedComment(remark)}>
                              <IconPencil2 size={10} fill="#6D7F92" />
                            </div>
                            <div
                              onClick={() =>
                                setIsDeleteCommentModalOpen(remark)
                              }
                            >
                              <IconTrash size={10} />
                            </div>
                          </div>
                        </div>
                        <div className="comment_content">{remark.message}</div>
                        <div className="comment_footer">
                          <div>
                            <span>
                              {moment(remark.at, "DD/MM/YY.HH:mm")
                                .locale(lng)
                                .fromNow()}
                            </span>
                            <div className="dot" />
                            <div>
                              {remark.plan
                                ? remark.plan.name
                                : remark.card.name}
                            </div>
                          </div>
                          <div>
                            <BarsIcon level={remark.importance} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {tempComment ? (
              <div className="textarea-form">
                <div className="set">
                  <span>Nv. Importance : </span>
                  <div
                    ref={refImportance}
                    className={
                      "rectangular_select" +
                      " " +
                      (importanceDivIsVisible
                        ? "rectangular_select_opened"
                        : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() =>
                        setImportanceDivIsVisible(!importanceDivIsVisible)
                      }
                    >
                      <span>
                        {IMPORTANCE_LEVELS[tempComment.importance - 1].label}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {importanceDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <div className={"rectangular_select_body_options"}>
                          {IMPORTANCE_LEVELS.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setTempComment({
                                    ...tempComment,
                                    importance: item.level,
                                  });
                                  setImportanceDivIsVisible(false);
                                }}
                              >
                                {_(item.label)}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="set">
                  <span>Afficher sur : </span>
                  <div
                    ref={refPlans}
                    className={
                      "rectangular_select" +
                      " " +
                      (planDivIsVisible ? "rectangular_select_opened" : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() => setPlanDivIsVisible(!planDivIsVisible)}
                    >
                      <span>{tempComment.plan && tempComment.plan.name}</span>
                      <TiArrowSortedDown />
                    </div>
                    {planDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <div className={"rectangular_select_body_options"}>
                          {activeCard.plans.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setTempComment({
                                    ...tempComment,
                                    plan: {
                                      id: item.id,
                                      name: `PLAN ${item.index + 1}`,
                                    },
                                  });
                                  setPlanDivIsVisible(false);
                                }}
                              >
                                {`PLAN ${activeCard.plans.length - index}`}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <textarea
                  onChange={(e) => {
                    setTempComment({
                      ...tempComment,
                      message: e.target.value,
                    });
                  }}
                />
                <div className="actions">
                  <div className="default" onClick={() => setTempComment(null)}>
                    <IconClose size="10" fill="#6D7F92" />
                  </div>
                  <div
                    className="premium"
                    onClick={() => handleSaveComment(tempComment)}
                  >
                    {_("post")}
                  </div>
                </div>
              </div>
            ) : editedComment ? (
              ""
            ) : (
              <div
                className="textarea"
                onClick={() =>
                  setTempComment({
                    message: "",
                    card: { id: activeCard.id, name: _("allPlans") },
                    plan: null,
                    importance: 2,
                  })
                }
              >
                {_("Ajouter une remarque...")}{" "}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  const renderErrorReportsFrame = () => {
    if (!selectedErrorReport) {
      return;
    }
    let groups = [];
    categories.forEach((taskCat) => {
      let frames = [];
      reasons.forEach((cat) => {
        if (taskCat.id === cat.categoryId) {
          if (
            Object.keys(selectedErrorReport.errors.categories).includes(
              cat.id.toString()
            )
          ) {
            frames.push({
              id: cat.id,
              label: cat.label,
              description:
                selectedErrorReport.errors.categories[cat.id].description,
              files: selectedErrorReport.errors.categories[cat.id].files,
            });
          } else {
            let subFrames = [];
            cat.items &&
              cat.items.forEach((res) => {
                if (
                  Object.keys(selectedErrorReport.errors.reasons).includes(
                    res.id.toString()
                  )
                ) {
                  subFrames.push({
                    id: res.id,
                    label: res.label,
                    description:
                      selectedErrorReport.errors.reasons[res.id].description,
                    files: selectedErrorReport.errors.reasons[res.id].files,
                  });
                }
              });
            if (subFrames.length > 0) {
              frames.push({
                id: cat.id,
                label: cat.label,
                items: subFrames,
              });
            }
          }
        }
      });
      if (frames.length > 0) {
        groups.push({ cat: { id: taskCat.id, name: taskCat.label }, frames });
      }
    });
    return (
      <div style={{ width: "100%" }}>
        <div className="tags">
          {errorReports.map((item, index) => (
            <div
              className={`tag ${
                selectedErrorReport.id === item.id && "success"
              }`}
              onClick={() => setSelectedErrorReport(item)}
            >
              {_("report")} {index + 1}
            </div>
          ))}
        </div>
        <div className="heavy_frame">
          <div>
            <div className="panel" style={{ border: "none", width: "100%" }}>
              <div className="title">
                <UserIcon />
                <div>{_("reportResponsable")} :</div>
                <span>{selectedErrorReport.responsable.name}</span>
              </div>
              <div className="title" style={{ margin: "20px 0px" }}>
                <ClockIcon />
                <div>{_("creationDate")} :</div>
                {/* <span>{selectedErrorReport.createdAt}</span> */}
                <span>{moment().format("DD-MM-Y")}</span>
              </div>
              <div className="grid_card">
                {groups.map((element) => (
                  <Fragment>
                    <div>{element.cat.name}</div>
                    {element.frames.map((cat) => (
                      <Fragment>
                        <div
                          className={
                            "card card_large" +
                            " " +
                            (cat.item && cat.item.length > 0
                              ? "card_large_open"
                              : "")
                          }
                        >
                          <div>{cat.label}</div>
                          {cat.files && (
                            <div className="action-side">
                              <div
                                className={
                                  "arrow" +
                                  " " +
                                  (selectedCategoryReason === cat.id
                                    ? "open"
                                    : "")
                                }
                                onClick={() => {
                                  if (selectedCategoryReason === cat.id) {
                                    setSelectedCategoryReason(null);
                                  } else {
                                    setSelectedCategoryReason(cat.id);
                                    setSelectedReason(null);
                                  }
                                }}
                              >
                                <IconChevronDown width={10} height="8" />
                              </div>
                            </div>
                          )}
                        </div>
                        {selectedCategoryReason === cat.id && (
                          <div className={"card_form"}>
                            <div className="text-area">
                              <div className="description_frame">
                                {cat.description}
                              </div>
                            </div>
                            <div className="attachment-list">
                              <div className="instruction">
                                {_("attachments ")} * :
                              </div>
                              {cat.files.map((file, index) => {
                                return (
                                  <div
                                    className="attachment-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      displayErrorScreenShot(cat.id, file.path)
                                    }
                                  >
                                    <div key={index}>{file.name}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}

                        {cat.items &&
                          cat.items.length &&
                          cat.items.map((reason) => (
                            <Fragment>
                              <div className={"card card_large sub_card"}>
                                <div>{reason.label}</div>
                                {reason.files && (
                                  <div className="action-side">
                                    <div
                                      className={
                                        "arrow" +
                                        " " +
                                        (selectedReason === reason.id
                                          ? "open"
                                          : "")
                                      }
                                      onClick={() => {
                                        if (selectedReason === reason.id) {
                                          setSelectedReason(null);
                                        } else {
                                          setSelectedReason(reason.id);
                                          setSelectedCategoryReason(null);
                                        }
                                      }}
                                    >
                                      <IconChevronDown width={10} height="8" />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {selectedReason === reason.id && (
                                <div className={"card_form card_form_sub"}>
                                  <div className="text-area">
                                    <div className="description_frame">
                                      {reason.description}
                                    </div>
                                  </div>
                                  <div className="attachment-list">
                                    <div className="instruction">
                                      {_("attachments ")} * :
                                    </div>
                                    {reason.files.map((file, index) => {
                                      return (
                                        <div
                                          className="attachment-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            displayErrorScreenShot(
                                              reason.id,
                                              file.path
                                            )
                                          }
                                        >
                                          <div key={index}>{file.name}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayErrorScreenShot = (id, filePath) => {
    document.getElementsByClassName("mask").item(0).classList.remove("hide");
    document.body.classList.add("hidden_overflow");
    setIframeConfig({
      hide: false,
      loading: true,
      url: getTemporaryScreenShotFileLink(id, filePath, token),
    });

    setTimeout(() => {
      setIframeConfig({
        hide: false,
        loading: false,
        url: getTemporaryScreenShotFileLink(id, filePath, token),
      });
    }, 2000);
  };

  const closeErrorScreenShot = () => {
    setIframeConfig({ ...iframeConfig, hide: true, url: "" });
    document.getElementsByClassName("mask").item(0).classList.add("hide");
    document.body.classList.remove("hidden_overflow");
  };

  const renderTempPlanFrame = () => {
    return (
      <div>
        <div className="tags">
          {tempPlan && <div className="tag success">PLAN 1</div>}
        </div>
        {tempPlan && (
          <div className="light_frame">
            <div>
              <div className="frame">
                <div className="title">{_("Plan de correction")}</div>
                <div className="set">
                  <label>{_("title")} *</label>
                  <input
                    value={tempPlan.title}
                    onChange={(event) =>
                      setTempPlan({
                        ...tempPlan,
                        title: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="set">
                  <label>{_("description")}</label>
                  <textarea
                    value={tempPlan.description}
                    onChange={(event) =>
                      setTempPlan({
                        ...tempPlan,
                        description: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="panel">
                <div className="set">
                  <label>{_("responsable")} *</label>
                  <div
                    ref={refResponsables}
                    className={
                      "rectangular_select" +
                      " " +
                      (responsablesDivIsVisible
                        ? "rectangular_select_opened"
                        : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() =>
                        setResponsablesDivIsVisible(!responsablesDivIsVisible)
                      }
                    >
                      <span>
                        {tempPlan.responsables.length > 0
                          ? tempPlan.responsables[0].name
                          : ""}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {responsablesDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <input
                          onChange={(e) =>
                            setCollaboratorsKeyWord(e.target.value)
                          }
                        />
                        <div className={"rectangular_select_body_options"}>
                          {collaborators.map((item, index) => {
                            if (!item) {
                              return;
                            }
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setTempPlan({
                                    ...tempPlan,
                                    responsables: [item],
                                  });
                                  setResponsablesDivIsVisible(false);
                                }}
                              >
                                <div className="avatar" key={index}>
                                  {renderAvatar(
                                    item && avatarCollaborators[item.uid]
                                      ? avatarCollaborators[item.uid].avatarUrl
                                      : null,
                                    item.name
                                  )}
                                </div>
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="set">
                  <label>{_("deadLine")}</label>
                  <div className={"calendar_select"}>
                    <Calendar
                      showIcon
                      dateFormat="dd/mm/yy"
                      locale={lng}
                      value={tempPlan.deadLine}
                      className="p-datepicker-sidebar"
                      panelClassName="large_calendar"
                      onChange={(e) => {
                        setTempPlan({
                          ...tempPlan,
                          deadLine: e.value,
                        });
                      }}
                      icon={<CalendarIcon fill="#6D7F92" />}
                    />
                  </div>
                </div>
                <div className="set">
                  <label>{_("priorityLevel")}</label>
                  <div
                    ref={refPriority}
                    className={
                      "rectangular_select " +
                      (priorityDivIsVisible ? "rectangular_select_opened" : "")
                    }
                    style={{ marginBottom: 23 }}
                  >
                    <div
                      className={`rectangular_select_header ${"hight_priority"}`}
                      style={{ border: "none" }}
                      onClick={() =>
                        setPriorityDivIsVisible(!priorityDivIsVisible)
                      }
                    >
                      <span>{tempPlan.priorityLevel.label}</span>
                      <TiArrowSortedDown />
                    </div>
                    {priorityDivIsVisible && (
                      <div
                        className={"rectangular_select_body"}
                        style={{
                          border: "none",
                          width: 290,
                          boxShadow: "none",
                        }}
                      >
                        <div className={"rectangular_select_body_options"}>
                          {[
                            { label: "URGENT", step: "hight" },
                            { label: "MEDIUM", step: "medium" },
                            { label: "LOW", step: "low" },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className={`rectangular_select_body_option ${item.step}_priority`}
                              onClick={() => {
                                setTempPlan({
                                  ...tempPlan,
                                  priorityLevel: item,
                                });
                                setPriorityDivIsVisible(false);
                              }}
                            >
                              {item.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer" style={{ width: addCard ? "70%" : "53%" }}>
              <div
                className="action_left"
                onClick={() => {
                  if (tempCard.plans && tempCard.plans.length > 0) {
                    setTempPlan(null);
                  } else {
                    setTempCard(null);
                    setTempPlan(null);
                  }
                }}
              >
                {_("cancel")}
              </div>
              <div
                className={
                  "action_right" +
                  " " +
                  (tempPlan.title && tempPlan.responsables.length > 0
                    ? ""
                    : "disabled")
                }
                onClick={() => {
                  if (
                    tempPlan.title === "" ||
                    !tempPlan.title ||
                    tempPlan.responsables.length === 0
                  ) {
                    return;
                  } else {
                    handleSaveCard({
                      ...tempCard,
                      plans: [
                        {
                          title: tempPlan.title,
                          priorityLevel: tempPlan.priorityLevel.label,
                          progression: "TODO",
                          description: tempPlan.description,
                          deadLine: tempPlan.deadLine
                            ? moment(tempPlan.deadLine).format(
                                "Y-MM-DD HH:mm:ss"
                              )
                            : null,
                          responsables: tempPlan.responsables,
                        },
                      ],
                    });
                    setAddCard(false);
                  }
                }}
              >
                {_("save")}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const prepareResponsableCards = (item) => {
    setCardResponsables([]);
    let cardResponsablesLocal = [...client.title.responsables];
    let cardResponsablesIds = [];
    cardResponsablesLocal.forEach((resp) => {
      if (!cardResponsablesIds.includes(resp.uid)) {
        cardResponsablesIds.push(resp.uid);
      }
    });
    item.plans.forEach((plan) => {
      plan.responsables &&
        plan.responsables.forEach((responsable) => {
          if (!cardResponsablesIds.includes(responsable.uid)) {
            cardResponsablesLocal.push(responsable);
            cardResponsablesIds.push(responsable.uid);
          }
        });
    });
    setCardResponsables(cardResponsablesLocal);
  };

  const prepareCommentsCard = (item) => {
    setCardComments([]);
    let cardCommentsLocal = [];
    item.plans.forEach((plan) => {
      plan.comments &&
        plan.comments.length &&
        plan.comments.forEach((comment) => {
          cardCommentsLocal.push({
            ...comment,
            plan: { name: `PLAN ${plan.index + 1}`, id: plan.id },
          });
        });
    });
    item.comments &&
      item.comments.length > 0 &&
      item.comments.forEach((remark) => {
        cardCommentsLocal.push({
          ...remark,
          card: { name: _(`allPlans`), id: item.id },
        });
      });
    cardCommentsLocal.sort((a, b) =>
      a.code < b.code ? 1 : b.code < a.code ? -1 : 0
    );
    cardCommentsLocal = sortCommentsCard(cardCommentsLocal);
    setCardComments(cardCommentsLocal);
  };

  const sortCommentsCard = (comments, sort = null) => {
    comments.sort((a, b) => {
      if (sort === "morePrioritaire") {
        return a.importance < b.importance
          ? 1
          : b.importance < a.importance
          ? -1
          : 0;
      } else {
        return a.code < b.code ? 1 : b.code < a.code ? -1 : 0;
      }
    });
    return comments;
  };

  const renderActivityMessage = (action, data) => {
    switch (action) {
      case "updated":
        return (
          <div>
            {`${_("hasModified")} ${_("the")} ${data.attribute} : `}
            <span>{`${data.from} -> ${data.to}`}</span>
          </div>
        );
      case "addResponsable":
        return (
          <div>
            {`${_("hasAdd")} ${_("asPlanResponsable")} : `}
            <span>{data.name}</span>
          </div>
        );
      case "removeResponsable":
        return (
          <div>
            {`${_("hasRemoved")} ${_("fromPlanResponsables")} : `}
            <span>{data.name}</span>
          </div>
        );
      case "create":
        return (
          <div>
            {`${_("hasCreate")} ${_("thePlan")} : `}
            <span>{data.title}</span>
          </div>
        );
      default:
        return;
    }
  };
  const { user, navCommunity } = useSelector((state) => state.auth);
  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  return (
    <div style={{ width: addCard ? 971 : 1244 }}>
      <div className="sidebar_header">
        <div className="sidebar_header_infos">
          <div
            className={`tag ${tab === "CORRECTION_CARD" && "active"}`}
            onClick={(e) => {
              setTab("CORRECTION_CARD");
              if (correctionCards.length === 0) {
                setAddCard(true);
              }
              setAddErrorReport(false);
              e.stopPropagation();
            }}
          >
            <span>{_("correctionCard")}</span>
            {!addCard && preferences.allowCreateCorrectionCards === "1" && (
              <div
                className="action"
                onClick={() => {
                  setActiveCard(null);
                  setActivePlan(null);
                  setAddCard(true);
                  setCardComments([]);
                  setCardResponsables(client.title.responsables);
                }}
              >
                <IconPlus size={13} />
              </div>
            )}
          </div>
          <div
            className={`tag ${tab === "ERROR_REPORT" && "active"}`}
            onClick={(e) => {
              setTab("ERROR_REPORT");
              if (errorReports.length === 0 || pendingReport) {
                setAddCard(true);
                setAddErrorReport(true);
              } else {
                setAddCard(false);
                setAddErrorReport(false);
              }
              e.stopPropagation();
            }}
          >
            <span>{_("errorReports")}</span>
            {!addCard && !pendingReport && (
              <div
                className="action"
                onClick={(e) => {
                  setAddCard(true);
                  setAddErrorReport(true);
                  e.stopPropagation();
                }}
              >
                <IconPlus size={13} />
              </div>
            )}
            {pendingReport && (
              <Tooltip
                key={`pending`}
                placement="bottom"
                title={_("pendingReport")}
              >
                <div
                  className="action"
                  onClick={(e) => {
                    setAddCard(true);
                    setAddErrorReport(true);
                    e.stopPropagation();
                  }}
                >
                  <SynchroRuningIcon size={14} fill="#FFF" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="sidebar_header_action" onClick={onClose}>
          <IconClose size={18} />
        </div>
      </div>
      {!loading &&
        tab === "CORRECTION_CARD" &&
        (tempCard || correctionCards.length > 0) && (
          <div className="sidebar_galery">
            {tempCard && (
              <div
                className="carte active"
                style={{ height: "80px", width: "16%" }}
              >
                <div>{tempCard.reason.label}</div>
                <div>{_("newCard")}</div>
              </div>
            )}
            {correctionCards.length > 0 &&
              correctionCards.map((item) => {
                return (
                  <div
                    className={
                      "carte" +
                      " " +
                      (activeCard && activeCard.id === item.id && "active") +
                      " " +
                      (addCard || addPlan ? "blocked" : "")
                    }
                    onClick={() => {
                      if (addCard || addPlan) {
                        return;
                      }
                      let indexedPlans = item.plans.map((plan, index) => {
                        return {
                          ...plan,
                          index: item.plans.length - index - 1,
                        };
                      });
                      setActiveCard({ ...item, plans: indexedPlans });
                      setActivePlan({
                        ...indexedPlans[0],
                        deadLine: moment(
                          item.plans[0].deadLine,
                          "Y-MM-DD HH:mm:ss"
                        )._d,
                      });
                      setAddPlan(null);
                      setEditedPlan(null);
                      prepareResponsableCards(item);
                      prepareCommentsCard({ ...item, plans: indexedPlans });
                    }}
                  >
                    <div>{item.reason.label}</div>
                    <div>
                      <FlagIcon
                        fill={
                          activeCard && activeCard.id === item.id
                            ? "#FFFFFF"
                            : "#29394D"
                        }
                      />{" "}
                      {_("plansCount")} : {item.plans.length}
                    </div>
                    {/* <div
                    className="action"
                    onClick={() => setIsDeleteCardModalOpen(item.id)}
                  >
                    <IconTrash size={12} />
                  </div> */}
                  </div>
                );
              })}
          </div>
        )}
      <div className="sidebar_body">
        <div className="aside" style={{ width: addCard ? "31%" : "21.5%" }}>
          <div className="infos-set">
            <label>{_("clientFolder")} : </label>
            <span>{client.title.label}</span>
          </div>
          {activeCard && activeCard.anomaly && tab === "CORRECTION_CARD" && (
            <div className="infos-set">
              <label>{_("anomaly")} :</label>
              <span>{activeCard.anomaly.label}</span>
            </div>
          )}
          {tempCard && tempCard.anomaly && tab === "CORRECTION_CARD" && (
            <div className="infos-set">
              <label>{_("anomaly")} :</label>
              <span>{tempCard.anomaly.label}</span>
            </div>
          )}
          {selectedErrorReport &&
            selectedErrorReport.anomaly &&
            tab === "ERROR_REPORT" && (
              <div className="infos-set">
                <label>{_("anomaly")} :</label>
                <span>{selectedErrorReport.anomaly.label}</span>
              </div>
            )}
          <div className="infos-set">
            <label>{_("assignedCollaborator")} : </label>
            <div className="avatars">
              <div className="avatars">
                {cardResponsables.map((item) => (
                  <Tooltip
                    key={`resp-${item.uid}`}
                    placement="top"
                    title={_(item.name)}
                  >
                    <div className="avatar" key={item.uid}>
                      {renderAvatar(
                        avatarCollaborators[item.uid]
                          ? avatarCollaborators[item.uid].avatarUrl
                          : null,
                        item.name
                      )}
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {loading ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : addCard ? (
            tempCard ? (
              renderTempPlanFrame()
            ) : (
              <AddNewCorrectionCardFrame
                organization={organization}
                categories={categories}
                responsables={collaborators}
                isToCorrectProp={addErrorReport}
                pendingReport={pendingReport}
                token={token}
                year={year}
                anomalies={anomalies}
                reasons={reasons}
                onCancel={() => {
                  if (tab === "ERROR_REPORT") {
                    setTab("CORRECTION_CARD");
                  }
                  if (correctionCards.length > 0) {
                    let indexedPlans = correctionCards[0].plans.map(
                      (plan, index) => {
                        return { ...plan, index };
                      }
                    );
                    setActiveCard({
                      ...correctionCards[0],
                      plans: indexedPlans,
                    });
                    setActivePlan({
                      ...indexedPlans[0],
                      deadLine: moment(
                        indexedPlans[0].deadLine,
                        "Y-MM-DD HH:mm:ss"
                      )._d,
                    });
                    prepareResponsableCards(correctionCards[0]);
                    setAddCard(false);
                  } else {
                    onClose();
                  }
                }}
                onConfirm={(isToCorrect, item) => {
                  if (isToCorrect) {
                    let index = 0;
                    let files = [];
                    let itemToSend = {
                      responsable: item.responsable,
                      state: item.status,
                      id: item.id,
                    };
                    let errors1 = Object.keys(item.errors.categories).map(
                      (rIndex) => {
                        let idsFile = [];
                        let oldFiles = [];
                        let r = item.errors.categories[rIndex];
                        for (let i = index; i < r.files.length; i++) {
                          if (r.files[i].type && r.files[i].lastModified) {
                            idsFile.push(index.toString());
                            files = files.concat(r.files[i]);
                            index = index + 1;
                          } else {
                            oldFiles.push(r.files[i]);
                          }
                        }
                        return {
                          idsFile,
                          oldFiles,
                          id: r.id,
                          type: "CATEGORY",
                          idReason: r.idReason,
                          description: r.description,
                        };
                      }
                    );
                    let errors2 = Object.keys(item.errors.reasons).map(
                      (rIndex) => {
                        let idsFile = [];
                        let oldFiles = [];
                        let r = item.errors.reasons[rIndex];
                        for (let i = 0; i < r.files.length; i++) {
                          if (r.files[i].type && r.files[i].lastModified) {
                            idsFile.push(index.toString());
                            files = files.concat(r.files[i]);
                            index = index + 1;
                          } else {
                            oldFiles.push(r.files[i]);
                          }
                        }
                        return {
                          idsFile,
                          oldFiles,
                          id: r.id,
                          type: "REASON",
                          idReason: r.idReason,
                          description: r.description,
                        };
                      }
                    );
                    handleSaveErrorReport({
                      ...itemToSend,
                      files,
                      errors: [...errors1, ...errors2],
                    });
                  } else {
                    setTempCard(item);
                    setTempPlan({
                      title: "NEW P",
                      responsables: [],
                      priorityLevel: { label: "MEDIUM", step: "medium" },
                      progression: "TODO",
                    });
                  }
                }}
                updateAnomalies={(items) => setAnomalies(items)}
                updateReasons={(items) => setReasons(items)}
              />
            )
          ) : tab === "CORRECTION_CARD" && correctionCards.length > 0 ? (
            preferences.allowAccessToCorrectionCards === "1" ? (
              renderCorrectionCardsFrame()
            ) : (
              <div className="no_data">
                <div className="title">{_("Oops")}</div>
                <div className="subTitle">
                  {_(
                    "vous ne pouvez pas voir les cartes de correction, acces non auto"
                  )}
                </div>
              </div>
            )
          ) : (
            errorReports.length > 0 && renderErrorReportsFrame()
          )}
        </div>
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isDeleteCommentModalOpen}
        onCancel={() => setIsDeleteCommentModalOpen(null)}
        onConfirm={() => {
          handleDeleteComment(isDeleteCommentModalOpen);
          setIsDeleteCommentModalOpen(null);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("deleteConfirm")}
        text={_("confirmCommentDelete")}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
      <ModalConfirm
        type={"delete"}
        isOpen={isDeletePlanModalOpen}
        onCancel={() => setIsDeletePlanModalOpen(false)}
        onConfirm={() => {
          deleteCorrectionCardPlan();
          setIsDeletePlanModalOpen(false);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("deleteConfirm")}
        text={
          activeCard && activeCard.plans.length === 1
            ? _("confirmPlanDeleteCascade")
            : _("confirmPlanDelete")
        }
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
      <ModalConfirm
        type={"alert"}
        isOpen={isDeleteResponsableModalOpen}
        onCancel={() => setIsDeleteResponsableModalOpen(false)}
        onConfirm={() => setIsDeleteResponsableModalOpen(false)}
        inProcess={false}
        actionFailed={false}
        title={_("impossibleDelete")}
        text={_("cancelConfirmContent")}
        labelNo={_("cancel")}
        labelYes={""}
        labelError={"Error"}
      />
      <FileReaderIframe
        hideIframe={iframeConfig.hide}
        srcIframe={iframeConfig.url}
        loadingIframe={iframeConfig.loading}
        onCloseIframe={() => closeErrorScreenShot()}
      />
    </div>
  );
};

export default CollaboratorCorrectionCard;
