import React, { Fragment, useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { renderAvatar } from "../../../utils";
import { IconClose, CalendarIcon } from "../../common/Icons";
import { TiArrowSortedDown } from "react-icons/ti";
import { Calendar } from "primereact/calendar";
import moment from "moment";
moment.locale("fr");

const SideBarPlanner = ({
  collaboratorName,
  selectedEventId,
  selectedClient,
  setSelectedClient,
  selectedCategory,
  setSelectedCategory,
  selectedDateEvent,
  setSelectedDateEvent,
  selectedDurationHours,
  setSelectedDurationHours,
  selectedDurationMin,
  setSelectedDurationMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  clientOptions,
  taskCategoriesOptions,
  statusOptions,
  events,
  setEvents,
  setOpenAddEvent,
  setSelectedTypeEvent,
  selectedTypeEvent,
  setSelecteddescription,
  budgetProgression,
  responsables,
  avatar,
  saveCollaboratorPlanner,
  lng,
}) => {
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const ECART_MAP = {
    NEGATIVE: "tag-warning",
    POSITIVE: "tag-danger",
    EQUAL: "tag-success",
  };
  const typeEvent = [
    {
      id: 1,
      label: "task",
      title: "task",
    },
    {
      id: 2,
      label: "rendez-vous",
      title: "rendez-vous",
    },
  ];
  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredOptions = clientOptions.filter((item) =>
    item.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  const renderAddTaskEevent = (selectedId, items, type) => {
    return (
      <div>
        <div className="title">Dossier client</div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
          >
            <span>{selectedClient ? selectedClient.label : "Select"}</span>
            <TiArrowSortedDown />
          </div>
          {clientsDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <input
                type="text"
                value={filterValue}
                onChange={handleFilterChange}
                placeholder="Filter options..."
                className={"rectangular_select_filter"}
              />
              <div className={"rectangular_select_body_options"}>
                {filteredOptions.map((item, index) => (
                  <div
                    key={index}
                    className={"rectangular_select_body_option"}
                    onClick={() => {
                      setSelectedClient(item);
                      setClientsDivIsVisible(false);
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="title">Catégorie</div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setCategoriesDivIsVisible(!categoriesDivIsVisible)}
          >
            <span>
              {selectedCategory ? selectedCategory.label : _("Select")}
            </span>
            <TiArrowSortedDown />
          </div>
          {categoriesDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {taskCategoriesOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedCategory(item);
                        setCategoriesDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {selectedCategory && selectedClient && (
          <div className="">
            <div className="upper-frame">
              <div className="left-panel" style={{ width: "100%" }}>
                <div className="list-frame">{renderTaskListII()}</div>
              </div>
            </div>
          </div>
        )}
        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              {/* <Calendar
                      showIcon
                      dateFormat="dd/mm/yy"
                      locale={lng}
                      className="p-datepicker-sidebar"
                      value={
                        editedPlan ? editedPlan.deadLine : activePlan.deadLine
                      }
                      onChange={(e) => {
                        editedPlan
                          ? setEditedPlan({
                              ...editedPlan,
                              deadLine: e.value,
                            })
                          : setEditedPlan({
                              ...activePlan,
                              deadLine: e.value,
                            });
                      }}
                      icon={<CalendarIcon fill="#6D7F92" />}
                    ></Calendar> */}
              <Calendar
                showIcon
                dateFormat="dd/mm/yy"
                locale={lng}
                // value={"select"}
                value={moment(selectedDateEvent, "Y-M-D").toDate()}
                className="p-datepicker-sidebar"
                panelClassName="large_calendar"
                onChange={(e) => setSelectedDateEvent(e.value)}
                icon={<CalendarIcon fill="#6D7F92" />}
              />
            </div>
          </div>
          <div>
            <div className="title">Durée de la tache</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationHours}
                  onChange={(event) =>
                    setSelectedDurationHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationMin}
                  onChange={(event) =>
                    setSelectedDurationMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="title">Statut de progression</div>
        {/* {renderRectangularSelect(
          selectedCategory,
          taskCategoriesOptions,
          categoriesDivIsVisible,
          setSelectedCategory
        )} */}
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
          >
            {selectedStatus ? selectedStatus : _("Select")}
            <TiArrowSortedDown />
          </div>
          {statusDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {statusOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedStatus(item.label);
                        setStatusDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="title">Description</div>

        <textarea
          className="description"
          value={selectedDescription}
          placeholder="Ajoutez votre description ici ..."
          onChange={(event) => {
            setSelecteddescription(event.target.value);
          }}
        />
      </div>
    );
  };
  const renderAddRDVEevent = (selectedId, items, type) => {
    return (
      <div>
        <div className="radio-container">
          <input
            type="radio"
            id="option1"
            name="options"
            value="option1"
            style={{ border: "2px solid red" }}
            checked={selectedPlaceRDV === "À distance"}
            onChange={() => setSelectedPlaceRDV("À distance")}
          />
          <div className="place"> À distance</div>
          <input
            type="radio"
            id="option2"
            name="options"
            value="option1"
            checked={selectedPlaceRDV === "Sur place"}
            onChange={() => setSelectedPlaceRDV("Sur place")}
          />
          <div className="place"> Sur place</div>
        </div>
        <div className="title">Dossier client</div>

        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
          >
            <span>{selectedClient ? selectedClient.label : _("Select")}</span>
            <TiArrowSortedDown />
          </div>
          {clientsDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {clientOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedClient(item);
                        setClientsDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              <Calendar
                showIcon
                dateFormat="dd/mm/yy"
                locale={lng}
                // value={"select"}
                value={moment(selectedDateEvent, "Y-M-D").toDate()}
                className="p-datepicker-sidebar"
                panelClassName="large_calendar"
                onChange={(e) => setSelectedDateEvent(e.value)}
                icon={<CalendarIcon fill="#6D7F92" />}
              />
            </div>
          </div>
          <div>
            <div className="title">Durée de la tache</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationHours}
                  onChange={(event) =>
                    setSelectedDurationHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationMin}
                  onChange={(event) =>
                    setSelectedDurationMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="title">Description</div>
        <textarea
          className="description"
          value={"Ajoutez votre description ici ..."}
          // onChange={(event) => {
          //   setEditedPlan({
          //     ...editedPlan,
          //     title: event.target.value,
          //   });
          // }}
        />
      </div>
    );
  };
  const handleSaveEvent = () => {
    // const parts = selectedDateEvent ? selectedDateEvent.split("/") : null;
    // const year = parseInt(parts[0], 10);
    // const month = parseInt(parts[1], 10) - 1;
    // const day = parseInt(parts[2], 10);
    // const timezone = "UTC";

    // const date = new Date(year, month, day);

    let date;
    if (
      typeof selectedDateEvent === "string" ||
      selectedDateEvent instanceof String
    ) {
      date = new Date(selectedDateEvent);
    } else {
      date = selectedDateEvent;
    }

    let formattedDate = null;
    let formattedDateAA, formattedDateLast;
    if (!isNaN(date)) {
      formattedDate = {
        date: date.toISOString().replace("T", " ").replace("Z", ".000000"),
        timezone_type: 3,
        timezone: "UTC",
      };

      // Obtenez l'heure locale en UTC
      const localOffset = date.getTimezoneOffset(); // Décalage en minutes

      // Créez une nouvelle date avec le décalage ajusté
      const adjustedDate = new Date(date.getTime() + localOffset * 60000);

      // Formatez la date en chaîne
      formattedDateAA = adjustedDate
        .toISOString()
        .replace("T", " ")
        .replace("Z", ".000000");

      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Mois de 1 à 12
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      // Formatez la date en chaîne
      formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000000`;
      formattedDateLast = {
        date: formattedDate,
        timezone_type: 3,
        timezone: "UTC",
      };
    }
    const duration =
      selectedDurationHours && selectedDurationMin
        ? parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin)
        : selectedDurationHours
        ? selectedDurationHours * 60
        : selectedDurationMin
        ? selectedDurationMin
        : 0;

    // const min =
    //   parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin);
    //   const formattedDate = {
    //     date: date.format('YYYY-MM-DD'),
    //     timezone_type: 3,
    //     timezone: 'UTC',
    //   };
    const newEvent = {
      id: selectedEventId,
      type: selectedTypeEvent,
      start: moment(selectedDateEvent, "Y-M-D").toDate(),
      // start: selectedDateEvent,
      date: formattedDateLast,
      duration: duration,
      meetingPlace: selectedPlaceRDV,
      client_id: selectedClient ? selectedClient.value : null,
      client_name: selectedClient ? selectedClient.label : null,
      category_name: selectedCategory ? selectedCategory.label : null,
      category_id: selectedCategory ? selectedCategory.value : null,
      description: selectedDescription,
      status: selectedStatus,
    };
    // const formattedDate = {
    //   date: selectedDateEvent.toISOString().replace('T', ' ').replace('Z', '.000000'),
    //   timezone_type: 3,
    //   timezone: 'UTC',
    // };

    // setEvents([...events, newEvent]);

    // saveCollaboratorPlanner(newEvent);
    saveCollaboratorPlanner(newEvent);
    // setSelectedTitle("");
  };

  const renderTaskListII = () => {
    if (!budgetProgression || !responsables) {
      return <div>Loading...</div>;
    }

    const selectedCategoryDetails =
      budgetProgression.categories[selectedCategory.label];
    // if (!selectedCategoryDetails || !selectedCategoryDetails.details) {
    //   return <div>Aucun détail disponible pour cette catégorie.</div>;
    // }

    return (
      <Fragment>
        <div className="list-frame_item_body">
          <div className="header">
            <div className="infos">
              <div className="avatar"></div>
              <div></div>
              <div></div>
            </div>

            <div className="tag" style={{ marginLeft: "47%" }}>
              <span className="progression_header">{_("Budgétés")}</span>
              <div className="tag">
                {/* {parseFloat(budgetProgression.categories[selectedCategory.value].total.budget)}h */}
              </div>
            </div>
            <div className="tag tag-success">
              <span className="progression_header">{_("Réalisés")}</span>{" "}
            </div>
            <div>
              <span className="progression_header">{_("Écart")}</span>{" "}
              <div className="tag"></div>
            </div>
          </div>

          {Object.keys(selectedCategoryDetails.details).map((index) => {
            let detail = selectedCategoryDetails.details[index];
            let responsable = responsables[index];

            // Vérifiez que le responsable existe
            if (!responsable) {
              return null;
            }

            return (
              <div className="line" key={index}>
                <div className="infos">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url(${responsable.avatarUrl})`,
                    }}
                  ></div>
                  <div>{responsable.name}</div>
                  <div>{_(responsable.roles.join("-"))}</div>
                </div>
                <div className="tag">
                  <div>{parseFloat(detail.budget)}</div>
                </div>
                <div className="tag tag-success">
                  <div>{parseFloat(detail.real)}</div>
                </div>
                <div className={`tag ${ECART_MAP[detail.ecart]}`}>
                  <div>
                    {parseFloat(
                      parseFloat(detail.real - detail.budget).toFixed(2)
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="fadePage">
      <div className={`sideBar display`}>
        <div className="header">
          <span>
            {selectedEventId ? "Modifier l'événement" : "Ajouter un événement"}
          </span>
          <div
            className="sidebar_header_action"
            onClick={() => {
              setOpenAddEvent(false);
              setSelectedCategory(null);
              setSelectedClient(null);
              setSelectedDateEvent(null);
              setSelectedTypeEvent(null);
            }}
          >
            <IconClose size={18} />
          </div>
        </div>
        <div className="sidebar_content">
          <div className="title">Collaborateur assigné</div>
          <div className="sub_title">
            <div className="avatar">{renderAvatar(avatar)}</div>
            <div className="name">{collaboratorName}</div>
          </div>
          <div className="title">Type d’événement</div>
          <div className={"rectangular_select"}>
            <div
              className={"rectangular_select_header"}
              onClick={() => setTypesEventDivIsVisible(!typesEventDivIsVisible)}
            >
              <span>{selectedTypeEvent ? selectedTypeEvent : _("select")}</span>
              <TiArrowSortedDown />
            </div>
            {typesEventDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <div className={"rectangular_select_body_options"}>
                  {typeEvent.map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setSelectedTypeEvent(item.label);
                          setTypesEventDivIsVisible(false);
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="diviser" />
          {
            // selectedTypeEvent && selectedTypeEvent === "task"
            //   ?
            selectedTypeEvent &&
              (selectedTypeEvent === "task"
                ? renderAddTaskEevent()
                : renderAddRDVEevent())
          }
        </div>
        <div className="sidebar_footer line_space_content">
          <button
            onClick={() => {
              setOpenAddEvent(false);
              setSelectedCategory(null);
              setSelectedClient(null);
              setSelectedDateEvent(null);
              setSelectedTypeEvent(null);
            }}
          >
            Annuler
          </button>
          <button
            className={"add " + (!selectedTypeEvent ? "disabled" : "")}
            onClick={() => handleSaveEvent()}
          >

            {selectedEventId ? "Modifier" : "Ajouter" }
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBarPlanner;
