import React, { useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import moment from "moment";
import classNames from "classnames";
import { IconPlus, IconChevronDown } from "../../common/Icons";
import Item from "./Item.js";
const Calendar = ({
  token,
  organizationId,
  setOpenAddEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  events,
  setEvents,
  selectedPeriodTypeId,
  currentMonth,
  setCurrentMonth,
  handlePreviousWeek,
  handleNextWeek,
  weeks,
  currentDate,
  currentWeekIndex,
  collapsedWeeks,
  setCollapsedWeeks,
  setSelectedCategory,
  selectedDateEvent,
  setSelectedDateEvent,
  selectedDurationHours,
  setSelectedDurationHours,
  selectedDurationMin,
  setSelectedDurationMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  setSelectedDescription,
  setSelectedTypeEvent,
  setSelectedClient,
  setSelectedEventId,
  weekAll,
  refreshEvents
}) => {
  const toggleCollapseWeek = (weekIndex) => {
    setCollapsedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };
  const renderWeek = (week, weekIndex, collapsedWeeks) => {
    return (
      <div className="week_row" key={weekIndex}>
        {week.map((day, dayIndex) => (
          <Item
            token={token}
            organizationId={organizationId}
            setOpenAddEvent={setOpenAddEvent}
            setSelectedDateEvent={setSelectedDateEvent}
            renderDurationDisplay={renderDurationDisplay}
            formatDuration={formatDuration}
            timeSheetByDays={timeSheetByDays}
            holidays={holidays}
            durationsByDay={durationsByDay}
            workingHours={workingHours}
            dayMapping={dayMapping}
            events={events}
            day={day}
            dayIndex={dayIndex}
            collapsedWeeks={collapsedWeeks}
            toggleCollapseWeek={toggleCollapseWeek}
            setSelectedCategory={setSelectedCategory}
            selectedDateEvent={selectedDateEvent}
            selectedDurationHours={selectedDurationHours}
            setSelectedDurationHours={setSelectedDurationHours}
            selectedDurationMin={selectedDurationMin}
            setSelectedDurationMin={setSelectedDurationMin}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedPlaceRDV={selectedPlaceRDV}
            setSelectedPlaceRDV={setSelectedPlaceRDV}
            selectedDescription={selectedDescription}
            setSelectedDescription={setSelectedDescription}
            setSelectedTypeEvent={setSelectedTypeEvent}
            setSelectedClient={setSelectedClient}
            setSelectedEventId={setSelectedEventId}
            refreshEvents={refreshEvents}
            isMonthVue={collapsedWeeks}
          />
        ))}
      </div>
    );
  };
  const renderWeeklyView = () => {
    // const startOfWeek = currentWeek.clone().startOf("week");
    // const daysOfWeek = [];

    // for (let i = 0; i < 5; i++) {
    //   // De lundi à vendredi
    //   daysOfWeek.push(startOfWeek.clone().add(i, "days"));
    // }
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const days = [];
    const nextWeekDays = [];

    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i, "days");
      const nextWeekDay = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
      nextWeekDays.push(nextWeekDay);
      //  setCurrentWeek(days);
    }
    console.log("currentDate========", currentDate,"weekAll", weekAll)
    return (
      <div className="weekly_view">
        {renderWeek(weekAll, currentWeekIndex, false)}
      </div>
    );
  };
  return (
    <div>
      <div className="calendar-header">
        <div
          onClick={() =>
            selectedPeriodTypeId !== 1
              ? setCurrentMonth(currentMonth.clone().subtract(1, "month"))
              : handlePreviousWeek()
          }
        >
          {selectedPeriodTypeId !== 1 ? "Mois précédent" : "Semaine précédente"}
        </div>
        <h2>{ selectedPeriodTypeId !== 1 ? currentMonth.format("MMMM YYYY") : weekAll[0].format("MMMM YYYY")}</h2>
        <div
          onClick={() =>
            selectedPeriodTypeId !== 1
              ? setCurrentMonth(currentMonth.clone().add(1, "month"))
              : handleNextWeek()
          }
        >
          {selectedPeriodTypeId !== 1 ? "Mois suivant" : "Semaine Suivante"}
        </div>
      </div>
      <div className="planner_calendar">
        {selectedPeriodTypeId === 1
          ? renderWeeklyView()
          : weeks.map((week, weekIndex) =>
              renderWeek(week, weekIndex, collapsedWeeks)
            )}
      </div>
    </div>
  );
};

export default Calendar;
