import { React, useState, useEffect } from "react";
import styles from "./Budget.module.scss";
import classNames from "classnames";
import Select from "react-select";
import { IconTrash } from "../common/Icons";
import { toast } from "react-toastify";
import _ from "../../i18n";
import { saveBudgetOffre } from "../../api";

const RolesManagement = ({
  preferences,
  updatedCollaboratorsList,
  setUpdatedCollaboratorsList,
  collaborators,
  totalGD,
  totalGE,
  totalSE,
  totalSupervisor,
  totalScanOp,
  setUpdated,
}) => {
  // const [value, onChange] = useState(new Date());
  const [enableToAddGD, setEnableToAddGD] = useState(false);
  const [enableToAddGE, setEnableToAddGE] = useState(false);
  const [enableToAddSE, setEnableToAddSE] = useState(false);
  const [enableToAddSupervisor, setEnableToAddSupervisor] = useState(false);
  const [enableToAddScanOp, setEnableToAddScanOp] = useState(false);

  const handleCollaboratorscompoment = (
    role,
    delegated,
    collaborator,
    value,
    index
  ) => {
    return (
      <div style={{ display: "flex", margin: "15px 0px" }}>
        <div>
          {!delegated && <label className={styles.role}>{role}:</label>}
          <div
            className={styles.select_frame}
            style={{ width: "264px", marginRight: "20px" }}
          >
            {updatedCollaboratorsList && (
              <Select
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect
                placeholder={"collaborator"}
                value={collaborator}
                options={collaborators}
                onChange={(event) =>
                  handleCollaboratorChange(event, role, collaborator)
                }
                isDisabled={!delegated}
                className={styles.filter_frame_field_placeholder}
                getOptionLabel={(option) => option.name}
              />
            )}
          </div>
        </div>
        <div className={styles.input_frame}>
          {!delegated && <label className={styles.role}>Total heures:</label>}
          <div>
            <input
              type="number"
              className={classNames(
                styles.input_box,
                styles.label,
                !delegated && styles.disabled
              )}
              disabled={!delegated}
              style={{ margin: 0, width: 77, height: 38 }}
              value={
                value
                // item[0] === "gd"
                //   ? totalGD
                //   : item[0] === "ge"
                //   ? totalGE
                //   : item[0] === "se"
                //   ? totalSE
                //   : totalSupervisor
              }
              onChange={(event) =>
                handleCollaboratorValueChange(event, role, collaborator)
              }
              // onBlur={(event) =>
              //   this.handleChangeBudgetZone(event, zone, [key], true)
              // }
              // disabled={
              //   activatedLevel !== "LEVEL_3" ||
              //   !zone["responsibles"][key]["isActive"]
              // }
            />
          </div>
        </div>
        {delegated && (
          // <button
          //   className={classNames(styles.add_button, styles.delete_button)}
          //
          // >
          //   {/* <IconTrash className={styles.setting_page_header_icon} /> */}
          //   <IconTrash />
          // </button>
          <div
            className={styles.garbadge}
            onClick={() => handleRemoveDelegate(role, index)}
          >
            <IconTrash />
          </div>
        )}
      </div>
    );
  };
  const handleCollaboratorChange = (event, role, collaborator) => {
    setUpdatedCollaboratorsList((prevCollaboratorsList) => {
      const updatedDelegated = prevCollaboratorsList[role]["delegated"].map(
        (delegate) => {
          if (delegate.id === collaborator.id) {
            return {
              ...delegate,
              id: event.id,
              zone: event.zone,
              name: event.name,
            };
          } else {
            return delegate;
          }
        }
      );

      return {
        ...prevCollaboratorsList,
        [role]: {
          ...prevCollaboratorsList[role],
          delegated: updatedDelegated,
        },
      };
    });
  };

  const handleCollaboratorValueChange = (event, role, collaborator) => {
    setUpdatedCollaboratorsList((prevCollaboratorsList) => {
      const updatedDelegated = prevCollaboratorsList[role]["delegated"].map(
        (delegate) => {
          if (delegate.id === collaborator.id) {
            const updatedDelegate = {
              ...delegate,
              value: event.target.value,
            };
            return updatedDelegate;
          }
          return delegate;
        }
      );

      const delegatedValuesSum = updatedDelegated.reduce(
        (sum, delegate) => sum + parseInt(delegate.value || 0),
        0
      );
      const principalValue = parseInt(
        parseInt(
          role === "GD"
            ? totalGD
            : role === "GE"
            ? totalGE
            : role === "SE"
            ? totalSE
            : role === "SUPERVISOR"
            ? totalSupervisor
            : totalScanOp
        ) - parseInt(delegatedValuesSum)
      );

      return {
        ...prevCollaboratorsList,
        [role]: {
          ...prevCollaboratorsList[role],
          principal: {
            ...prevCollaboratorsList[role]["principal"],
            value: principalValue,
          },
          delegated: updatedDelegated,
        },
      };
    });
    setUpdated(true);
  };
  //   const handleCollaboratorValueChange = (event, role, collaborator) => {
  //     setUpdatedCollaboratorsList((prevCollaboratorsList) => {
  //       const updatedDelegated = prevCollaboratorsList[role]["delegated"].map(
  //         (delegate) => {
  //           if (delegate.id === collaborator.id) {
  //             return {
  //               ...delegate,
  //               value: event.value,
  //             };
  //           } else {
  //             return delegate;
  //           }
  //         }
  //       );

  //       const delegatedValuesSum = updatedDelegated.reduce(
  //         (sum, delegate) => (sum + delegate.value ? delegate.value : 0),
  //         0
  //       );

  //       const principalValue = totalGD - delegatedValuesSum; // Assumed totalGD is the total value for GD
  //       return {
  //         ...prevCollaboratorsList,
  //         [role]: {
  //           ...prevCollaboratorsList[role],
  //           principale: {
  //             ...prevCollaboratorsList[role]["principale"],
  //             value: 9,
  //           },
  //           delegated: updatedDelegated,
  //         },
  //       };
  //     });
  //   };

  const handleAddNewDelegate = (role, roleKey) => {
    if (role === "gd") {
      setEnableToAddGD(true);
    } else if (role === "ge") {
      setEnableToAddGE(true);
    } else if (role === "se") {
      setEnableToAddSE(true);
    } else if (role === "supervisor") {
      setEnableToAddSupervisor(true);
    } else if (role === "scanOp") {
      setEnableToAddScanOp(true);
    }

    setUpdatedCollaboratorsList((prevCollaboratorsList) => {
      return {
        ...prevCollaboratorsList,
        [role]: {
          ...prevCollaboratorsList[role],
          delegated: [
            ...prevCollaboratorsList[role]["delegated"],
            {
              id: null,
              zone: null,
              name: null,
              value: 0,
              idBzd: null,
              responsible: prevCollaboratorsList[role].principal.idBzd,
            },
          ],
        },
      };
    });
    setUpdated(true);
  };
  const handleRemoveDelegate = (role, delegateIndex) => {
    if (role === "gd") {
      setEnableToAddGD(false);
    } else if (role === "ge") {
      setEnableToAddGE(false);
    } else if (role === "se") {
      setEnableToAddSE(false);
    } else if (role === "supervisor") {
      setEnableToAddSupervisor(false);
    } else if (role === "scanOp") {
      setEnableToAddScanOp(false);
    }
    setUpdatedCollaboratorsList((prevCollaboratorsList) => {
      const updatedDelegated = prevCollaboratorsList[role]["delegated"].filter(
        (_, index) => index !== delegateIndex
      );

      const delegatedValuesSum = updatedDelegated.reduce(
        (sum, delegate) => sum + parseInt(delegate.value || 0),
        0
      );

      const principalValue = parseInt(
        parseInt(totalGD) - parseInt(delegatedValuesSum)
      );
      setUpdated(true);
      return {
        ...prevCollaboratorsList,
        [role]: {
          ...prevCollaboratorsList[role],
          principal: {
            ...prevCollaboratorsList[role]["principal"],
            value: principalValue,
          },
          delegated: updatedDelegated,
        },
      };
    });
  };
  return (
    <div>
      <div className={styles.title}>GESTION DES RÔLES</div>
      <div>
        <div className={classNames(styles.line, "grid-x")}>
          <div className="cell small-4 medium-4 large-6">
            <label className={styles.contry}>Belgique</label>

            {updatedCollaboratorsList &&
              Object.entries(updatedCollaboratorsList).map((item) => {
                return (
                  item[1] &&
                  item[1]["principal"] &&
                  item[1]["principal"].zone === "1" && (
                    <div>
                      <div className={styles.role_content}>
                        {handleCollaboratorscompoment(
                          item[0],
                          false,
                          updatedCollaboratorsList[item[0]]["principal"],
                          updatedCollaboratorsList[item[0]]["principal"][
                            "value"
                          ]
                        )}

                        {item[1]["delegated"] &&
                          item[1]["delegated"].map((delegated, index) => {
                            return handleCollaboratorscompoment(
                              item[0],
                              true,
                              delegated,
                              delegated.value,
                              index
                            );
                          })}
                        {preferences && preferences.allowToAddDeleger==="1" && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "13px",
                            alignItems: "center",
                          }}
                        >
                          <button
                            className={styles.add_button}
                            onClick={() => {
                              if (
                                (item[0] === "GD" && !enableToAddGD) ||
                                (item[0] === "GE" && !enableToAddGE) ||
                                (item[0] === "SE" && !enableToAddSE) ||
                                (item[0] === "Superviseur" &&
                                  !enableToAddSupervisor) ||
                                (item[0] === "scanOp" && !enableToAddScanOp)
                              ) {
                                handleAddNewDelegate(item[0], "");
                              }
                            }}
                          >
                            +
                          </button>
                          <label className={styles.add_delegate_label}>
                            Ajouter un délégué
                          </label>
                        </div>)}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
          <div className="cell small-4 medium-4 large-6">
            <label className={styles.contry}>Maroc</label>
            {updatedCollaboratorsList &&
              Object.entries(updatedCollaboratorsList).map((item) => {
                return (
                  item[1] &&
                  item[1]["principal"] &&
                  item[1]["principal"].zone === "2" && (
                    <div>
                      <div className={styles.role_content}>
                        {handleCollaboratorscompoment(
                          item[0],
                          false,
                          updatedCollaboratorsList[item[0]]["principal"],
                          updatedCollaboratorsList[item[0]]["principal"][
                            "value"
                          ]
                          //   item[0] === "gd"
                          //     ? totalGD
                          //     : item[0] === "ge"
                          //     ? totalGE
                          //     : item[0] === "se"
                          //     ? totalSE
                          //     : totalSupervisor
                        )}

                        {item[1]["delegated"] &&
                          item[1]["delegated"].map((delegated, index) => {
                            return handleCollaboratorscompoment(
                              item[0],
                              true,
                              delegated,
                              delegated.value,
                              index
                            );
                          })}
                        {preferences && preferences.allowToAddDeleger==="1" &&(
                        <div
                          style={{
                            display: "flex",
                            marginTop: "13px",
                            alignItems: "center",
                          }}
                        >
                          <button
                            className={styles.add_button}
                            onClick={() => {
                              if (
                                (item[0] === "GD" && !enableToAddGD) ||
                                (item[0] === "GE" && !enableToAddGE) ||
                                (item[0] === "SE" && !enableToAddSE) ||
                                (item[0] === "Superviseur" &&
                                  !enableToAddSupervisor) ||
                                (item[0] === "scanOp" && !enableToAddScanOp)
                              ) {
                                handleAddNewDelegate(item[0], "");
                              }
                            }}
                          >
                            +
                          </button>
                          <label className={styles.add_delegate_label}>
                            Ajouter un délégué
                          </label>
                        </div>)}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesManagement;
