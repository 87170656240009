import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";
import moment from "moment";

import { getTimeSheetProgression, getCollaboratorsAvatars } from "../../../api";
import _ from "../../../i18n";

import { Table, Tooltip } from "antd";
import TTPPaginator from "../../common/TTPPaginator";
import { TiArrowSortedDown, TiArrowSortedUp, TiWarning } from "react-icons/ti";
import TimeSheetProgressionFilter from "./TimeSheetProgressionFilter";
import "./TimeSheetProgression.local.scss";

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: _("budgetedHours"),
    dataIndex: "budget",
    key: "budget",
    width: "100px",
  },
  {
    title: _("realBillableHours"),
    dataIndex: "realBillable",
    key: "realBillable",
    width: "100px",
  },
  {
    title: _("realNonBillableHours"),
    dataIndex: "realNonBillable",
    key: "realNonBillable",
    width: "100px",
  },
  {
    title: _("gap"),
    dataIndex: "gap",
    key: "gap",
    width: "100px",
  },
  {
    title: _("cp"),
    dataIndex: "cp",
    key: "cp",
    width: "15%",
  },
];

const TimeSheetProgression = ({ token, navCommunity, language }) => {
  const [progression, setProgression] = useState([]);
  const [nbResult, setNbResult] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    collaborators: [],
    clients: [],
    zone: null,
    role: null,
    bu: null,
    paginationPage: 1,
    pageSize: 10,
    sort: null,
  });

  let timesheetQuery = useQuery(
    [("getTimeSheetProgression", token, navCommunity, filter)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getTimeSheetProgression(
            navCommunity.id,
            token,
            filter
          );
          setProgression(response.data.data.data);
          setNbResult(response.data.data.nbrResult);
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderTimeSheetTree = () => {
    let columnsTest = [];

    columnsTest = [
      {
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43, textAlign: "right" }}>
                <span
                  class="ant-table-column-title"
                  style={{ width: "92.5%", marginLeft: "28px" }}>
                  SYNTHETIQUE
                </span>
              </div>
            </span>
          );
        },
        dataIndex: "label",
        key: "label",
        render: (text, record) => (
          <span
            className={"balance-sheet-key-indicator-treasury-bold"}
            style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
            <span
              className={
                record.type === "CLIENT" && "ant-table-column-title_sub"
              }>
              <div>
                {record.name && record.name.length >= 46
                  ? `${record.name.slice(0, 32)}...`
                  : record.name}
              </div>
              {record.type === "CLIENT" && record.role && (
                <span>{record.role}</span>
              )}
            </span>
          </span>
        ),
        width: 350,
      },
    ];

    for (let i = 1; i < columns.length; i++) {
      columnsTest.push({
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43 }}>
                <span class="ant-table-column-title">
                  {_(columns[i].title)} {columns[i].key === "gap" ? "" : "(h)"}
                  <span
                    class="sorter"
                    style={{
                      color:
                        filter.sort && filter.sort.includes(columns[i].key)
                          ? "#e4525d"
                          : "#fff",
                    }}
                    onClick={() => {
                      if (!filter.sort) {
                        setFilter({
                          ...filter,
                          sort: `${columns[i].key}_desc`,
                        });
                      } else {
                        setFilter({
                          ...filter,
                          sort:
                            filter.sort === `${columns[i].key}_desc`
                              ? `${columns[i].key}_asc`
                              : `${columns[i].key}_desc`,
                        });
                      }
                    }}>
                    {filter.sort === `${columns[i].key}_asc` ? (
                      <TiArrowSortedUp />
                    ) : (
                      <TiArrowSortedDown />
                    )}
                  </span>
                </span>
              </div>
            </span>
          );
        },
        dataIndex: columns[i].dataIndex,
        key: columns[i].key,
        render: (text, record) => {
          return columns[i].key === "cp" ? (
            record.cp_config === "NO_CONFIGURATION" ? (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
                <Tooltip
                  key={`role-${record.key}`}
                  placement="top"
                  title={_("regimeNotConfigurated")}>
                  <div className="tag" style={{ color: "red", marginTop: 10 }}>
                    <TiWarning />
                  </div>
                </Tooltip>
              </span>
            ) : (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
                <div>
                  <span
                    className={
                      text && text.length >= 46
                        ? "balance-sheet-label-wrap"
                        : ""
                    }>
                    <div>
                      {record.gap}
                      {record.type === "COLLABORATOR" && "%"}
                    </div>
                  </span>
                  {record.type === "COLLABORATOR" && (
                    <div>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("cp")}>
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            <span>
                              {parseFloat(parseFloat(record.cp).toFixed(2))}h
                            </span>
                          </span>
                        </span>
                      </Tooltip>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("realized")}>
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            <span>
                              {parseFloat(
                                record.realBillable + record.realNonBillable
                              ).toFixed(2)}
                              h
                            </span>
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </span>
            )
          ) : columns[i].key === "gap" ? (
            <span
              className={"balance-sheet-td-component"}
              style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
              <div>
                <span
                  className={
                    text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
                  }>
                  <div>
                    {parseFloat(
                      (record.budget *
                        (moment().diff(moment([filter.year, 0, 1]), "days") +
                          1)) /
                        365 -
                        record.realBillable -
                        record.realNonBillable
                    ).toFixed(2)}
                    h
                  </div>
                </span>
                {record.type === "COLLABORATOR" && (
                  <div>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("proportionnalBudget")}>
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>
                            {parseFloat(
                              (record.budget *
                                (moment().diff(
                                  moment([filter.year, 0, 1]),
                                  "days"
                                ) +
                                  1)) /
                                365
                            ).toFixed(2)}
                            h
                          </span>
                        </span>
                      </span>
                    </Tooltip>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("realized")}>
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>
                            {parseFloat(
                              parseFloat(
                                record.realBillable + record.realNonBillable
                              ).toFixed(2)
                            )}
                            h
                          </span>
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </span>
          ) : (
            <span
              className={"balance-sheet-td-component"}
              style={{
                minHeight: record.type === "COLLABORATOR" ? 72 : 43,
                padding:
                  record.type === "COLLABORATOR"
                    ? "20px 0 20px 0px"
                    : "10px 0 10px 0px",
              }}>
              <span
                className={
                  text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
                }>
                <div>{record[columns[i].dataIndex]}h</div>
              </span>
            </span>
          );
        },
        width: "180px",
      });
    }

    return (
      <div style={{ paddingTop: "0rem" }}>
        <div className={"rmodal__center"}>
          <Table
            columns={columnsTest}
            dataSource={progression}
            pagination={false}
            bordered={false}
            style={{ margin: "0 0 0 0" }}
          />
        </div>
      </div>
    );
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={10}
        nbResult={nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  };

  return (
    <div className="portfolio_container">
      <div className="container">
        <TimeSheetProgressionFilter
          filterFormIsVisible={false}
          filter={filter}
          nbResult={nbResult}
          updateFilter={setFilter}
          fetching={timesheetQuery.isFetching}
        />
        <div className="medium-11">
          {timesheetQuery.isFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : Object.keys(progression).length ? (
            <Fragment>
              {renderTimeSheetTree()}
              {renderPaginate()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noClientsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSheetProgression;
