const teamEn = {
  save: "Save",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  yes: "Yes",
  no: "No",
  encoding: "Encoding",
  closure: "Closure",
  deleted: "Deleted collaborator",
  setYouAsBackup: "set you as backup for his absence",
};
export default teamEn;
