import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { BriefCaseIcon, IconExport } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import Reporting from "./Reporting";
import Echeances from "./Echeances";
import CalendarRecap from "./Calendar";
import Bonuses from "./Bonuses";
import PrestationSuivi from "./PrestationSuivi";
import ErrorReporting from "./ErrorReporting";
import AbsenceMatrix from "./AbsenceMatrix/AbsenceMatrix";
import CollaboratorPlansBoard from "../CollaboratorSpace/PlansBoard/CollaboratorPlansBoard";
import Nettoyage from "./ProductionCapacity";
import PortfolioPlaning from "./Portfolio";
import TimeSheetProgression from "./TimeSheetProgression";
import { BeatLoader } from "react-spinners";
import { exportClientsVolumetry, exportProductionCapacity } from "../../api";

let tabs = [
  {
    id: "REPORTING",
    label: "reporting",
  },
  {
    id: "PRODUCTION_CAPACITY",
    label: "productionCapacity",
  },
  {
    id: "BONUSES",
    label: "bonus",
  },
  {
    id: "ECHEANCES",
    label: "echeances",
  },
  {
    id: "CALENDAR",
    label: "calendar",
  },
  {
    id: "TS_PROGRESSION",
    label: "tsProgression",
  },
  {
    id: "PORTFOLIO",
    label: "portfolios",
  },
  {
    id: "ERROR_REPORT",
    label: "erreurReport",
  },
  {
    id: "ABSENCE_MATRIX",
    label: "absenceMatrix",
  },
  {
    id: "PLAN_BOARD",
    label: "planBoard",
  },
  {
    id: "PRESTATION_SUIVI",
    label: "prestationSuivi",
  },
];

const ReportingPage = () => {
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const { language } = useSelector((state) => state.params);
  const [filter, setFilter] = useState({
    tab: tabs[0].id,
    year: new Date().getFullYear().toString()
  });
  const [exporting, setExporting] = useState(false);

  const [productionCapacityFilter, setProductionCapacityFilter] = useState({
    collaborator: null,
    year: new Date().getFullYear().toString(),
    zone: null,
    keyword: "",
    category: 4,
  });

  const handleExportProductionCapacity = async (organization, token, filter) => {
    try {
      setExporting(true);
      await exportProductionCapacity(
        token,
        organization,
        filter
      );
    } catch (e) {
      console.error("Error exporting production capacity:", e);
    } finally {
      setExporting(false);
    }
  }

  const handleExportClientsVolumetry = async () => {
    try {
      setExporting(true);
      await exportClientsVolumetry(token, navCommunity.id);
    } catch (e) {
      console.error("Error exporting clients volumetry:", e);
    } finally {
      setExporting(false);
    }
  };

  const renderReportingTabContent = () => {
    switch (filter.tab) {
      case "REPORTING":
        return <Reporting token={token} navCommunity={navCommunity} />;
      case "ECHEANCES":
        return <Echeances token={token} navCommunity={navCommunity} />;
      case "BONUSES":
        return <Bonuses token={token} navCommunity={navCommunity} />;
      case "ERROR_REPORT":
        return (
          <ErrorReporting
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "PORTFOLIO":
        return (
          <PortfolioPlaning
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "TS_PROGRESSION":
        return (
          <TimeSheetProgression
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "CALENDAR":
        return (
          <CalendarRecap
            token={token}
            navCommunity={navCommunity}
            lng={language}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        );
      case "ABSENCE_MATRIX":
        return (
          <AbsenceMatrix
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "PLAN_BOARD":
        return (
          <CollaboratorPlansBoard
            token={token}
            lng={language}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        );
      case "PRODUCTION_CAPACITY":
        return (
          <Nettoyage
            filter={productionCapacityFilter}
            setFilter={setProductionCapacityFilter}
          />
        );
      case "PRESTATION_SUIVI":
        return (
          <PrestationSuivi
            token={token}
            navCommunity={navCommunity}
            lng={language}
            year={filter.year}
          />
        );
      default:
        return;
    }
  };

  const renderExportButton = () => {
    switch(filter.tab) {
      case "PRODUCTION_CAPACITY" : return (
        <div
        className="export_btn"
        onClick={() => handleExportProductionCapacity(
          navCommunity.id,
          token,
          productionCapacityFilter
        )}
      >
        {exporting ? (
          <BeatLoader color="#3498db" size={10} />
        ) : (
          <>
            <IconExport size={20} />
            Exporter
          </>
        )}
      </div>
      );
      case "PRESTATION_SUIVI": return (
        <div
        className="export_btn"
        onClick={() => handleExportClientsVolumetry()}
      >
        {exporting ? (
          <BeatLoader color="#3498db" size={10} />
        ) : (
          <>
            <IconExport size={20} />
            Exporter la volumétrie
          </>
        )}
      </div>
      );
      default: return null;
    }
  }

  return (
    <div className="page-content">
      <PageHeader
        header={_("reporting")}
        subHeader={_("subTitleReportingsList")}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      >
        {renderExportButton()}
      </PageHeader>
      <div className="tab-container">
        <TTPTab2
          tabs={tabs}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div>

      <div
        className="content-body large-content"
        style={{
          padding:
            (filter.tab === "ECHEANCES" || filter.tab === "BONUSES") && 0,
        }}>
        {renderReportingTabContent()}
      </div>
    </div>
  );
};

export default ReportingPage;
