import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { CustumedMatrix } from "tamtam-components";
import { useSelector } from "react-redux";
import { getCollaboratorsGlobalNettoyage, getCollaboratorNettoyageReport } from "../../../../api";
import _ from "../../../../i18n";
import { renderDurationDisplay } from "../../../../utils";
import NettoyageFilter from "./NettoyageFilter";

function Nettoyage({filter, setFilter}) {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [landing, setLaoading] = useState(false);
  const [isClientsFetching, setIsClientsFetching] = useState(false)
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      id: "volumetry",
      label: _("volumetry"),
      isEditable: true,
      widthPercentage: 15,
    },
    {
      id: "budget",
      label: _("budget"),
      isEditable: true,
      widthPercentage: 15,
    },
    {
      id: "timesheet",
      label: _("timesheet"),
      isEditable: true,
      widthPercentage: 35,
    },
  ]);

  const { isLoading } = useQuery(
    ["getCollaboratorsGlobalNettoyage", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          setLoading(true);
          const response = await getCollaboratorsGlobalNettoyage(
            token,
            navCommunity.id,
            filter
          );
          // this code is for showing clients skeleton when fetching data
          // we create an empty array that represents the number of lines to display
          const responseData = Object.values(response.data.data)
            .map((item) => ({
              ...item,
              details: Array.from({ length: 5 }, (_, index) => ({id: index + 1}))
            }));
          setFiltredContribution(responseData);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          return null;
        }
      }
    }
  );

  const fetchCollaboratorNettoyageReport = async (collaborator) => {
    if (token && navCommunity && collaborator) {
      setIsClientsFetching(true);

      // this code is for showing clients skeleton when fetching data
      // we create an empty array that represents the number of lines to display
      setFiltredContribution(prevState => {
        return prevState.map((item) => ({
          ...item,
          details: Array.from({ length: 5 }, (_, index) => ({id: index + 1}))
        }))
      })

      getCollaboratorNettoyageReport(
        token,
        collaborator,
        navCommunity.id,
        filter
      ).then((response) => {
        const result = response.data.data;
        const updatedContributions = filtredContribution.map((c) => {
          if (c.id === collaborator) {
            return {
              ...c,
              details: result.details,
            };
          }
          return c;
        });
        setFiltredContribution(updatedContributions);
      })
      .catch((e) => {
        console.error(e);
        return null;
      }).finally(() => {
        setIsClientsFetching(false);
      });
    }
  };

  useQuery(
    ["fetchCollaboratorNettoyageReport", filter.collaborator],
    () => fetchCollaboratorNettoyageReport(filter.collaborator),
    {
      enabled: !!filter.collaborator,
    }
  );

  const handleColumns = (column, line) => {
    const handleClick = () => {
      setFilter({collaborator: line.id, ...filter});
      fetchCollaboratorNettoyageReport(line.id);
    };

    if (filtredContribution.indexOf(line) === 0) {
      if (column.id === "volumetry") {
        return (
          <div
            className="tags"
            style={{ marginTop: "15px", justifyContent: "center" }}
            onClick={handleClick}
          >
            <div className="labled-tag no-border">
              <span>{_("realized")}</span>
              <div
                className="tag"
                style={{ borderLeft: "none", justifyContent: "flex-end" }}
              >
                <div>{line.volumetry} p</div>
              </div>
            </div>
          </div>
        );
      } else if (column.id === "budget") {
        return (
          <div
            className="tags"
            // style={{ margin: "1px 0px 2px 2px"}}
            style={{ justifyContent: "center", marginTop: "15px" }}
            onClick={handleClick}
          >
            <div
              className="labled-tag no-border"
              //   style={{ marginBottom: "23px" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "85%",
                }}
              >
                <span>{_("annual")}</span>
              </div>

              <div className="tag" style={{ padding: "0", borderLeft: "none" }}>
                <div
                  className={`progress_item_progress ${
                    line.budget.budgetZoneCollaborator >
                    line.budget.realZoneCollaborator
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                  style={{ background: "none" }}
                >
                  {line.budget.budgetZoneCollaborator
                    ? renderDurationDisplay(line.budget.budgetZoneCollaborator)
                    : 0
                  }
                  <div
                    style={{
                      height: "38px",
                      display: "flex",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div className="progress_item_progress_container">
                      <div
                        className="progress_item_progress_bar"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "100%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    100) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,

                          background:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#EB5757"
                              : "#06D9B1",
                        }}
                      />
                      <div
                        className="progress_item_progress_index"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "200%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    200) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,
                          color:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#DE4848"
                              : "#02AF8E",
                        }}
                      >
                        {/* <TiArrowSortedDown /> */}
                        <div className="realized_progress">
                          {renderDurationDisplay(
                            line.budget.realZoneCollaborator
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (column.id === "timesheet") {
        return (
          <div className="tags" style={{ margin: "15px 10px" }} onClick={handleClick}>
            <div className="labled-tag no-border">
              <span>{_("Encodage Réalisé")}</span>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedEncodage)}
                </div>
              </div>
            </div>

            <div className="labled-tag no-border">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "85%",
                }}
              >
                <span>{_("Nettoyage Réalisé")}</span>
              </div>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedNettoyage)}
                </div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <span>{_("Bilan Réalisé")}</span>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedBalance)}
                </div>
              </div>
            </div>

            <div className="labled-tag no-border">
              <div
                style={{
                  display: "flex",
                  width: "85%",
                }}
              >
                <span>{_("Rapport")}</span>
              </div>
              <div className="tag">
                <div
                  style={{
                    color:
                      line.timesheet.rapport > 2
                        ? " #06D9B1"
                        : line.timesheet.rapport > 1.5
                        ? "#EA8124"
                        : "#eb5757",
                    backgroundColor:
                      line.timesheet.rapport > 2
                        ? " rgba(6, 217, 177, 0.1)"
                        : line.timesheet.rapport > 1.5
                        ? "rgb(235 152 87 / 17%)"
                        : "rgba(235, 87, 87, 0.1)",
                  }}
                >
                  {line.timesheet.rapport}
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (column.id === "volumetry") {
        return (
          <>
            <div className="tags" style={{ justifyContent: "center" }}>
              <div
                className="labled-tag no-border"
                style={{ justifyContent: "center" }}
              >
                <div className="tag" style={{ margin: "0px" }}>
                  <div>{line.volumetry} p</div>
                </div>
              </div>
            </div>
          </>
        );
      } else if (column.id === "budget") {
        return (
          <div
            className="tags"
            style={{ margin: "0px 2px", justifyContent: "center" }}
          >
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div
                className="tag"
                style={{ padding: "0", borderLeft: "none", margin: "0px" }}
              >
                <div
                  className={`progress_item_progress ${
                    line.budget.realZoneCollaborator >
                    line.budget.budgetZoneCollaborator
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                  style={{ background: "none" }}
                >
                  {line.budget.budgetZoneCollaborator
                    ? renderDurationDisplay(line.budget.budgetZoneCollaborator)
                    : 0
                  }
                  <div
                    style={{
                      height: "38px",
                      display: "flex",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div className="progress_item_progress_container">
                      <div
                        className="progress_item_progress_bar"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "100%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    100) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,

                          background:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#EB5757"
                              : "#06D9B1",
                        }}
                      />
                      <div
                        className="progress_item_progress_index"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "200%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    200) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,
                          color:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#DE4848"
                              : "#02AF8E",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            width:
                              parseInt(line.budget.budgetZoneCollaborator) > 0
                                ? parseInt(line.budget.realZoneCollaborator) /
                                    parseInt(
                                      line.budget.budgetZoneCollaborator
                                    ) <
                                    40 && "89px"
                                : "",
                          }}
                        >
                          {renderDurationDisplay(
                            line.budget.realZoneCollaborator
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (column.id === "timesheet") {
        return (
          <div className="tags" style={{ margin: "0px 10px" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div>
                  {renderDurationDisplay(line.timesheet.realizedEncodage)}
                </div>
              </div>
            </div>
            {/* <div className="labled-tag no-border"> */}
            <div
              className="labled-tag no-border"
              style={{ padding: "0", justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div
                  style={{
                    color:
                      line.timesheet.realizedNettoyage >
                      parseFloat(line.timesheet.realizedNettoyage) * 60
                        ? "#eb5757"
                        : "#6d7f92",
                  }}
                >
                  {renderDurationDisplay(line.timesheet.realizedNettoyage)}
                </div>
              </div>
            </div>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div>
                  {renderDurationDisplay(line.timesheet.realizedBalance)}
                </div>
              </div>
            </div>
            <div
              className="labled-tag no-border"
              style={{ padding: "none", justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div
                  style={{
                    color:
                      line.timesheet.rapport > 2
                        ? " #06D9B1"
                        : line.timesheet.rapport > 1.5
                        ? "#EA8124"
                        : "#eb5757",
                    backgroundColor:
                      line.timesheet.rapport > 2
                        ? " rgba(6, 217, 177, 0.1)"
                        : line.timesheet.rapport > 1.5
                        ? "rgb(235 152 87 / 17%)"
                        : "rgba(235, 87, 87, 0.1)",
                  }}
                >
                  {line.timesheet.rapport}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const handleTitle = (title) => {
    const isFirstItem = filtredContribution.indexOf(title) === 0;
    const handleClick = () => {
      setFilter({collaborator: title.id, ...filter});
      fetchCollaboratorNettoyageReport(title.id);
    };
    return (
      <div
        className="matrix_title"
        style={isFirstItem ? { paddingTop: "50px" } : {}}
        onClick={handleClick}
      >
        <div>{title.collaborator_name}</div>
      </div>
    );
  };

  const handleChildTitle = (child) => {
    return (
      <div style={{ marginTop: "25px", marginLeft: "10px", }}>
        {child.clientName}
      </div>
    );
  };

  const handleChildTitleSkeleton = () => {
    return (
      <>
        <div style={{ marginTop: "25px", marginLeft: "15px", }}>
          <div className="skeleton title-skeleton" style={{ margin: "0px" }} />
        </div>
      </>
    );
  };

  const handleChildCellule = (column, line, child) => {
    switch (column.id) {
      case "volumetry":
        return (
          <div className="tags" style={{ marginBottom: "8px", justifyContent: "center" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div>{child.volumetry} p</div>
              </div>
            </div>
          </div>
        );
      case "timesheet":
        return (
          <div className="tags" style={{ marginBottom: "8px", justifyContent: "flex-start" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center", marginRight: "70px" }}
            >
              <div className={`tag`} style={{ marginTop: "0px" }}>
                <div>-</div>
              </div>
            </div>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center", marginRight: "70px" }}
            >
              <div className={`tag`} style={{ marginTop: "0px" }}>
                <div>{renderDurationDisplay(child.realZoneCollaborator)}</div>
              </div>
            </div>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div
                className={`tag ${
                  child.ecart <= 0 ? "tag-success" : "tag-danger"
                }`}
                style={{ marginTop: "0px" }}
              >
                <div>
                  {child.ecart > 0 ? "+" : "-"}
                  {renderDurationDisplay(child.ecart)}
                </div>
              </div>
            </div>
          </div>
        );
      case "budget":
        return (
          <div
            className="tags"
            style={{ marginBottom: "2px", justifyContent: "center" }}
          >
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div
                className="tag"
                style={{ padding: "0", borderLeft: "none", margin: "0px" }}
              >
                <div
                  className={`progress_item_progress ${
                    child.realZoneCollaborator > child.budgetZoneCollaborator
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                  style={{ background: "none" }}
                >
                  {renderDurationDisplay(child.budgetZoneCollaborator)}
                  <div
                    style={{
                      height: "38px",
                      display: "flex",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div className="progress_item_progress_container">
                      <div
                        className="progress_item_progress_bar"
                        style={{
                          width:
                            parseInt(child.realZoneCollaborator) >
                            parseInt(child.budgetZoneCollaborator)
                              ? "100%"
                              : parseInt(child.budgetZoneCollaborator) > 0 &&
                                parseInt(child.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(child.realZoneCollaborator) * 100) /
                                  parseInt(child.budgetZoneCollaborator)
                                }%`
                              : 0,

                          background:
                            parseFloat(child.realZoneCollaborator) >
                            parseFloat(child.budgetZoneCollaborator)
                              ? "#EB5757"
                              : "#06D9B1",
                        }}
                      />
                      <div
                        className="progress_item_progress_index"
                        style={{
                          width:
                            parseInt(child.realZoneCollaborator) >
                            parseInt(child.budgetZoneCollaborator)
                              ? "200%"
                              : parseInt(child.budgetZoneCollaborator) > 0 &&
                                parseInt(child.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(child.realZoneCollaborator) * 200) /
                                  parseInt(child.budgetZoneCollaborator)
                                }%`
                              : 0,
                          color:
                            parseFloat(child.realZoneCollaborator) >
                            parseFloat(child.budgetZoneCollaborator)
                              ? "#DE4848"
                              : "#02AF8E",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            width:
                              parseInt(child.budgetZoneCollaborator) > 0
                                ? parseInt(child.realZoneCollaborator) /
                                    parseInt(child.budgetZoneCollaborator) <
                                    40 && "89px"
                                : "",
                          }}
                        >
                          {renderDurationDisplay(child.realZoneCollaborator)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleChildCelluleSkeleton = (column) => {
    switch (column.id) {
      case "volumetry":
        return (
          <div className="tags" style={{ marginBottom: "8px", justifyContent: "center" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="skeleton tag-skeleton" style={{ margin: "0px" }} />
            </div>
          </div>
        );
      case "timesheet":
        return (
          <div className="tags" style={{ marginBottom: "8px", justifyContent: "flex-start" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center", marginRight: "95px" }}
            >
              <div className="skeleton tag-skeleton" style={{ margin: "0px" }} />
            </div>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center", marginRight: "95px" }}
            >
              <div className="skeleton tag-skeleton" style={{ margin: "0px" }} />
            </div>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="skeleton tag-skeleton" style={{ margin: "0px" }} />
            </div>
          </div>
        );
      case "budget":
        return (
          <div
            className="tags"
            style={{ marginBottom: "2px", justifyContent: "center" }}
          >
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="skeleton tag-skeleton" style={{ margin: "0px" }} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <NettoyageFilter
        token={token}
        organization={navCommunity && navCommunity.id}
        filter={{ ...filter }}
        updateFilter={setFilter}
        fetching={isLoading}
      />
      {loading ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : (
        <div className="production-frame">
          {filtredContribution && filtredContribution.length > 0 ? (
            <div className="production-frame_scroll">
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
                handleChildTitle={
                  isClientsFetching ?
                    handleChildTitleSkeleton :
                    handleChildTitle
                }
                handleChildCellule={
                  isClientsFetching ?
                    handleChildCelluleSkeleton :
                    handleChildCellule
                }
              />
            </div>
          ) : (
            <div className="no_data">
              <div className="title">{_("oops")}</div>
              <div className="subTitle">
                {_("noCollaboratorProductionFound")}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Nettoyage;
