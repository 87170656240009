import { useSelector } from "react-redux";
import _ from "../../i18n";
import { BriefCaseIcon, ArrowLeftIcon } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import { CustumedMatrix } from "tamtam-components";
import { getRevisionForfait,getListClient } from "../../api";
import { useQuery ,useQueryClient } from "react-query";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import styles from "./Forfait.module.scss";
import TTPFilterHorizontalBar from "./TTPFilterHorizontalBar";
import {NumberDisplay} from "../../utils"


const Forfait = () => {
  const { clientID } = useParams();
  const { token, navCommunity } = useSelector((state) => state.auth);
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const columns = [];
  const [isDataFetching, setIsDataFetching] = useState(false);

  for (let year = currentYear; year >= startYear; year--) {
    columns.push({
      id: year.toString(),
      label: _(year.toString()),
      isEditable: year === currentYear,
      widthPercentage: 10,
    });
  }
  const [client, setClient] = useState(clientID);

  const [volumetryData, setVolumetryData] = useState([]);
  const [prestationData, setPrestationData] = useState([]);
  const [facturationData, setFacturationData] = useState([]);
  const [analyseData, setAnalyseData] = useState([]);

  const ENCODING_TYPE = [
    { label: "amount_purchases", value: 'Factures d\'achat' },
    { label: "amount_sales", value: 'Factures de vente' },
    { label: "turnover", value: 'Banques' },
  ];
  const ENCODING_ANALYSE_TYPE = [
    { label: "worked_hours", value: 'Heurs realisés' },
    { label: "Billed_hours", value: 'Heurs facturés' }
  ];
  const ENCODING_FACTURATION_TYPE = [
    { label: "fee_billing", value: 'Facturation honoraires' }
  ];
  const [clientOptions,setClientOptions] =useState([])
  const queryClient = useQueryClient();

  const { status, error } = useQuery(
    ["getClientRevisionForfait", token, navCommunity],
    async () => {
      if (token && navCommunity && client) {
        try {
          const response = await getRevisionForfait(token, navCommunity.id, client);
          setVolumetryData(response.data.data.volumetry);
          setPrestationData(response.data.data.prestation);
          setFacturationData(response.data.data.facturation);
          setAnalyseData(response.data.data.analyse);
          return response.data.data.volumetry;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  const { status: statusList, error: errorList } = useQuery(
    ["getListClient", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getListClient(token, navCommunity.id);
          setClientOptions(response.data.data);
          return response.data.data;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  useEffect(() => {
      if (token && navCommunity && client) {
        setIsDataFetching(true);
        getRevisionForfait(token, navCommunity.id, client).then(response =>{
            console.log({isDataFetching})
            setVolumetryData(response.data.data.volumetry);
            setPrestationData(response.data.data.prestation);
            setFacturationData(response.data.data.facturation);
            setAnalyseData(response.data.data.analyse);
        })
        .catch((e) => {
          throw new Error(e.message);
        })
        .finally(() => {
            setIsDataFetching(false);
        });
    }
  }, [client, queryClient, token, navCommunity]);

  const handleColumnsVolumetry = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              <span key={index}>
                {column.id == data.year ? data.amount : ""}
                </span>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  const handleColumnsPrestation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              <span key={index}>
                {column.id == data.year ? convertToHoursMinutes(data.amount) : ""}
                </span>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsFacturation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              <span key={index}>
                {column.id == data.year ? NumberDisplay(data.amount) : ""}
                </span>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsAnalyse = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
            <style>
      {`
        .CustumedMatrix_matrix_body_line__11lbb:first-child {
          border-bottom: 1px solid #d8dde2;
        }
          .CustumedMatrix_matrix__10BiN {
          background-color: white;
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #29394d;
        }
      `}
    </style>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              <span key={index}>
                {column.id == data.year ? convertToHoursMinutes(data.amount) : ""}
                </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleTitleVolumetry = (title) => (
    <Fragment>
      <div>
        <div className="title_infos">
          <div>
            <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
          </div>
          {ENCODING_TYPE.map(data=>{
            if(data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

  const handleTitlePrestation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos">
          <div>
            <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
          </div>
         {title.type}
        </div>
      </div>
    </Fragment>
  );
  const handleTitleFacturation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos">
          <div>
            <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
          </div>
          {ENCODING_FACTURATION_TYPE.map(data=>{
            if(data.label === title.type) return data.value;
          })}
          </div>
      </div>
    </Fragment>
  );
  const handleTitleAnalyse = (title) => (
    <Fragment>
      <div>
        <div className="title_infos">
          <div>
            <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
          </div>
         {ENCODING_ANALYSE_TYPE.map(data=>{
            if(data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color + "22"
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? true
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default"
    };
  },
  multiValue: (styles, { data }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: color + "22"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
};

  return (
    <div className="page-content">
      <PageHeader
        header={"Révision des Forfaits"}
        subHeader={_("subTitleReportingsList")}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      />

      <div className="ttp-tab-container">
        <div className="content-body large-content" style={{ padding: "0 0 0" }}>
          {status === 'success' && (
            <>
              <TTPFilterHorizontalBar
                clientID={clientID}
                customAddOn={null}
                cssClass=""
                pageSize={null}
                button={true}
                handleListPageSizeChange={null}
                searchPlaceholder=""
                nbResult={0}
                label={""}
                activatedFilter={[]}
                marginLeft={0}
                searchInputValue={""}
                fetching={false}
                handleSearchInputChange={null}
                filterFormIsVisible={null}
                filters={[
                  {
                    type: "SELECT",
                    options: {
                      placeholder: "Client",
                      options: clientOptions,
                      values: client ? [client] : [],
                      isMulti: false,
                      isClearable: true,
                      isSimple: true,
                      action: setClient
                    },
                    grid: 12,
                    color: "#0a0a0a"
                  }
                ]}
                handleResetFilter={null}
              />
              {client ? (
                isDataFetching ? (
                  <div className="ripple_loading">
                    <img src={"/img/Ripple-1s-150px.svg"} alt="" />
                  </div>
                ) : (
                  <>
                    <div className={styles.title_forfait}>
                      <div className={styles.title_info}>
                      <span>{_('Analyse de la facturation')}</span>
                      </div>
                    </div>
                    <CustumedMatrix
                      columns={columns}
                      items={facturationData}
                      handleCellule={handleColumnsFacturation}
                      handleTitle={handleTitleFacturation}
                      headerTagBG={"#FFFFFF"}
                    />
                    <div className={styles.title_forfait}>
                      <div className={styles.title_info}>
                        <span>{_('Analyse des impacts Réalisés Vs Facturés')}</span>
                      </div>
                    </div>
                    <CustumedMatrix
                      columns={columns}
                      items={analyseData}
                      handleCellule={handleColumnsAnalyse}
                      handleTitle={handleTitleAnalyse}
                      headerTagBG={"#FFFFFF"}
                    />
                    <div className={styles.title_forfait}>
                      <div className={styles.title_info}>
                        <span>{_('Volumétrie du dossier')}</span>
                      </div>
                    </div>
                    <CustumedMatrix
                      columns={columns}
                      items={volumetryData}
                      handleCellule={handleColumnsVolumetry}
                      handleTitle={handleTitleVolumetry}
                      headerTagBG={"#FFFFFF"}
                    />
                    <div className={styles.title_forfait}>
                      <div className={styles.title_info}>
                        <span>{_('Analyse des prestations')}</span>
                      </div>
                    </div>
                    <CustumedMatrix
                      columns={columns}
                      items={prestationData}
                      handleCellule={handleColumnsPrestation}
                      handleTitle={handleTitlePrestation}
                      headerTagBG={"#FFFFFF"}
                    />
                  </>
                )
              ) : (
                <div className="no_data">
                  <div className="title">{_("Aucun client sélectionné !")}</div>
                  <div className="subTitle">{_("Veuillez choisir un client")}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {status === 'loading' && <p>Loading data...</p>}
        {status === 'error' && <p>Error: {error.message}</p>}
      </div>
    </div>
  );
};

export default Forfait;
