const teamFr = {
  // Clients list
  clientList: "Liste des clients",
  subTitleClientList: "VISUALISER ET GÉRER VOS DOSSIER CLIENTS",
  folderCount: "Nombre de dossiers",
  portfolioCount: "Nombre de porte-feuilles",
  economicBudget: "Budget économique",
  budgetedTime: "Temps budgétés",
  hourBudget: "Budget horaire",
  completedBudgetization: "Budgétisation complete",
  manageBudget: "Gerer budget",
  clientForm: "Fiche client",
  hisAssociates: "ses attachés",
  attachedTo: "attaché a",
  entity: "Entité",
  attatched: "Attaché",
  clientsAttachedTo: "Entité (clients associés à: )",
  clientsType: "Type Client (CRM)",
  clientsGroup: "Groupe Client (CRM)",
  folderAs: "Dossiers En tant que",
  finFacturation: "Fin de facturation",
  finPrestation: "Fin de prestation",
  departureCommuniquer: "Depart communiquer ",
  capacityPlanning: "Capacity Planning",
  portfolios: "Suivi des Portefeuilles",
  tsProgression: "Suivi du Temps",
  productionCapacity: "Suivi de production",
  absenceMatrix: "Suivi des Absences",
  bonus: "Plan de Bonus",
  echeances: "Suivi d’Echéances",
  dynamicTable: "Tableau dynamique",
  financial: "Etats financiers",
  affecteAbsence: "Affecter un congé",

  //Filter
  clientState: "Etat client",
  clientType: "Type de clients",
  collaborator: "Collaborateur",
  category: "Catégorie",
  budgetStatus: "Etat budget",
  goBack: "Retour",
  relized: "réalisés",
  attributeds: "Attribués",
  nonAttributeds: "Non-Attribués",
  totalConcernedFolder: "Total dossiers concernés",

  //ClientSpace
  client: "Client",
  ficheClient: "Fiche client",
  ficheCollaborator: "Fiche Collaborateur",
  relizedDuration: "Temps réalisées",
  taskList: "Liste des tâches",
  total: "Total",
  budgetedProfitability: "Rentabilité budgétaire",
  realizedProfitability: "Rentabilité réel",
  budgetCurentResult: "Résultat du budget courant",
  manageClientBudget: "Gérer le budget client",
  noActivitiesFound: "Aucune activitée trouvée !",
  clientId: "ID Client",
  pack: "Pack",
  appliedTariffGrid: "Grille Tarifaire appliquée",
  tvaType: "Type de TVA",
  groupSociety: "Groupe de société",
  closingDate: "Date de clôture",
  squad: "Squad",
  bu: "BU",
  cluster: "Cluster",
  tvaRegime: "Régime TVA",
  horusFolder: "Dossier HORUS",
  offshoring: "Offshoring",
  budgetedHours: "Heures budgetées",
  realHours: "Heures réalisées",
  budgetedCA: "CA budgeté",
  realCA: "CA réel",

  //Collaborator space
  erreurReport: "Reporting d'erreurs",
  absenceMatrix: "Matrice de congé",
  planBoard: "Suivi des Plans",
  prestationSuivi: "Suivi des prestations",
  noReportingsFound: "Aucune donné trouvée !",
  isSerious: "est-elle grave ?",
  serious: "Grave",
  toCorrect: "Correction effectuée",
  isCategory: "est-elle categorie ?",
  underWhichCategory: "Sous quelle categorie raison",
  cpDetails: "Details CP",
  select: "Selectionner",
  productionTracking: "Suivi de production",
  plansBoard: "Gestion des plans",
  folderCapacity: "Capacité dossier",
  calendar: "Calendrier",
  dashboard: "tableau de bord",
  planResponsable: "Responsable du plan",
  reportResponsable: "Responsable du rapport",
  hasAdd: "a ajouté",
  hasRemoved: "a enlevé",
  hasCreate: "a crée",
  thePlan: "le plan",
  responsables: "Responsables",
  asPlanResponsable: "comme responsables du plan",
  fromPlanResponsables: "des responsables du plan",
  assignedCollaborator: "Collaborateurs assignés",
  deadLine: "Deadline",
  anomaly: "Anomalie",
  reason: "Reason",
  newCard: "Nouvelle carte",
  priorityLevel: "Niveau de priorité",
  addNewReason: "Ajouter une nouvelle raison",
  selectCategory: "Sélectionnez la categorie",
  selectTheReason: "Sélectionnez la raison de vos plans de correction",
  selectTheAnomaly: "Sélectionnez l'anomalie de vos plans de correction",
  selectTheErrorReportAnomaly:
    "Sélectionnez l'anomalie de votre rapport d'erreur",
  pendingReport: "Rapport en cours",
  selectResponsable: "A qui s’adresse le rapport",
  addNewReasonCategory: "Ajouter une nouvelle categorie raison ...",
  addNewAnomaly: "Ajouter une nouvelle anomalie ...",
  clientFolder: "Dossier client",
  default: "Par defaut",
  addDescription: "ajouter une description",
  cor_card: "Carte de correction",
  dev_card: "Carte de developpement",
  commun: "Commun",
  zone: "Zone",
  role: "Role",
  morocco: "Maroc",
  belgium: "Belgique",
  cardType: "Type de carte",
  global: "Génerale",
  months: "Mois",
  realBillableHours: "reel facturable",
  realNonBillableHours: "reel non-facturable",

  monthly: "MENSUEL",
  termly: "TRIMESTRIEL",
  annual: "Annuel",

  UNAUTHORIZED_BUDGET: "Budget non autorisé",
  GLOBAL_BUDGET_NOT_AFFECTED: "Budget global non affecté",
  INFORMATIONS_CLIENT_COMPLETED: "informations du client",
  BUDGET_GLOBAL_COMPLETED: "Budget global",
  MANAGEMENT_TACHES_COMPLETED: "Gestion des taches",
  BUDGET_COMPLETED: "Budgétisation complete",

  TODO: "À faire",
  WIP: "En cours",
  VALIDATION: "En validation",
  DONE: "Terminé",

  MEDIUM: "Moyen",
  URGENT: "Urgent",
  LOW: "Pas urgent",

  gap: "Écart",
  sales: "Ventes",
  purchases: "Achats",
  financial_transactions_coda: "coda",
  financial_transactions_noncoda: "non-coda",
  caisse: "Caisse",
  remuneration: "Rémunération",
  moreRecent: "plus récentes",
  morePrioritaire: "plus prioritaires",
  toAttributTheoricaly: "À attribuer théoriquement",
  addCertificats: "ajouter les PJs",
  needCertificat: "Besoin de fournir une certificat",
  needCertificats: "Besoin de fournire les pièces justifiantes",
  showIntervenants: "afficher les intervenents en details",
  reduceIntervenants: "reduire les intervenents",
  portfolio: "portfeuille",
  gapBudgetReal: "différence budget/realisé",
  gapCPReal: "différence Capacity planing/realisé",
  affecteAbsenceMessage:
    "Besoin d'affecter un congé payé ou une autre absence ?",

  proportionnalBudget: "Budget proportionnel",
  realized: "Réalisé",
  theoric: "Théorique",
  budgeted: "Budgétisé",
  ecart: "Écart",
  ppp: "Production par pièce",
  pc: "Compensation de prod",
  budget: "Budget",
  volumetry: "Volumétrie",
  timesheet: "Timesheet",
  echeanceAnnual: "Échéances Annuelle",
  filtrerBy: "Filtrer par",
  endDate: "Date de fin",
  startDate: "Date de début",
  durationByDefaultCoefficients:
    "cette durée est calculé avec les coefficient par défauts",
  defaultSoftwareMessage:
    "Ce chiffre est calculé a la base des coefficients par default",
  noSoftwareCoefficientsMessage:
    "Les coefficients du logiciel du client sont pas encore configurés. Ce chiffre est calculé a la base des coefficients par default",

  folders: "Dossiers",
  inactifs: "Inactifs",
  actifs: "Actifs",
  all: "Tout",
  nbrResult: "Nombre de resultat",
  errorReports: "Rapport d'erreurs",
  correctionCard: "Carte de correction",
  plansCount: "Nbr. de plans",
  gapAnalyse: "Analyse d’Écart",
  activities: "Activitées",
  hasModified: "a modifié",
  allPlans: "tous plans",
  post: "Poster",
  the: "le",
  remarks: "Remarques",
  productionAnalysePerPiece: "Analyse de production par pièce",
  timePerPiece: "temps par pièce",
  description: "Description",
  deleteConfirm: "Voulez-vous supprimer?",
  impossibleDelete: "suppression impossible",
  confirmCardDelete:
    "En supprimant la carte vous supprimer tout les plans associés !",
  confirmPlanDeleteCascade:
    "le supression du seul plan restant engendre la supreesion de sa carte !",
  confirmPlanDelete: "le plan sera supprimé",
  cancelConfirmContent: "Au moin un seul responsable est requis pour un plan !",
  certificatRequieredMessage:
    "N’oublie pas de joindre ton certificat médical pour les dates",
  noRoleConfiguredMessage: "Besoin de configurer le role du collaborateur!",
  // roles
  SUPERVISOR: "superviseur",

  //Volumetry Sidebare
  EncodingSetting: 'Configuration "Encodage"',
  coefficientsConfig: 'Gestion de coefficients pour la categorie "Encodage"',
  docTypesConfig: "Gestion de type de document a partir du task",

  //Collaborators list
  collaboratorState: "État collaborateur",
  collaborators: "Collaborateurs",
  subTitleCollaborators: "VISUALISER ET GÉRER VOS COLLABORATEURS",
  nbrFolders: "Nombre de dossiers",
  nbrReports: "Nombre de rapport",
  folder: "Dossier",
  details: "Détails",
  capacityPlaning: "Capacity planing",
  configuration: "Configuration",
  innactifSince: "Inactif depuis le",
  lastInsert: "Derniere saisie",

  // Echeance page
  subTitleEcheanceList: "VISUALISER ET GÉRER VOS DOSSIER CLIENTS",

  // developementCard
  developementCard: "Carte de développement",
  seriousError: "erreur grave",
  edit: "modifier",
  add: "ajouter",
  search: "Rechercher",
  save: "Enregistrer",
  choose: "Choisir",
  cancel: "Annuler",
  english: "Anglais",
  french: "Français",
  dutch: "Néerlandais",
  yes: "Oui",
  no: "Non",
  none: "aucun",
  from: "du",
  to: "au",
  asGD: "en tant que GD",
  nbrCollaborators: "Nombre de collaborateurs",
  card: "Carte",
  periodic: "Periodique",
  depart: "Départ",
  refuse: "Refuser",
  accept: "Accepter",

  weekend: "Weekend",
  holiday: "Jour ferié",
  annualLeave: "Vacances annuelles",
  sickLeave: "Maladie",
  absence: "Absence",
  businessTrip: "Déplac ement de travail",
  remote: "Télétravail",
  present: "Présence",
  outDay: "Temps partiel",
  manageAbsence: "Gérer l'absence",
  manageRegime: "Gérer le regime",
  respondToRequest: "Repondre à la demande",
  paidLeave: "Congé payé",

  tauxEffectif: "Taux effectif",
  tauxBudgetaire: "Taux budgétaire",
  effectifBenshmark: "benshmark eff.",
  budgetaireBenshmark: "benshmark bud.",
  budgetedFolders: "est attribué",
  handledFolders: "intervient",
  intervenantsWithNoRole: "intervient sans role",
  allFiles: "Tous les dossiers",

  sortBy: "Trier par",
  today: "Aujourd'hui",
  yesterday: "Hier",
  otherday: "Avant-hier",
  week: "la semaine",
  correctionPlan: "plan",
  affected: "a affecté(e)",
  affectedYou: "Vous a affecté(e)",
  anAbscence: "an congé",
  for: "pour",
  requestedAnAbscence: "a demandé un congé",
  approvedAbscenceOf: "a accepté(e) la demande de",
  rejectededAbscenceOf: "a refusé(e) la demande de",
  indicatedAnSickAbscence: "a deposé un congé de maladie",

  absence_sick: "Congé de maladie",
  absence_affected: "Affectation congé",
  certificat_required: "Certificats requises",
  absence_request: "Demande congé",
  absence_approved: "Demande congé acceptée",
  absence_rejected: "Demande congé refusée",
  correction_card_affected: "Carte de correction affectée",
  correction_card_removed: "Carte de correction retirée",
  correction_card_returned: "Carte de correction retournée",
  correction_card_validated: "Carte de correction validée",
  correction_card_request_validat: "Demande validation",

  requestCorrectionCardValidation:
    "demande une validation du plan de correction",
  validatedCorrectionCard: "a validé le plan de correction",
  returnedCorrectionCard: "a retourné le plan de correction",
  youRequestedAnAbscence: "avez demandé un congé",
  yourAbscenceRequest: "votre demande de congé",
  hasRemovedYou: "vous a retiré du plan",
  hasAffectedYou: "vous a affecté au plan",

  allNotifications: "Toutes les notifications",
  allNotif: "Tous",
  cardNotifications: "Notifications liées aux cartes",
  calendarNotifications: "Notifications liées au calendrier",
  you: "Vous",
  other: "Autre",
  backup: "Remplaçant",
  setYouAsBackup: "vous a défini comme remplaçant durant son absence",
  regimeNotConfigurated: "Regime non configuré!",
  confirmationAbsenceMessage: "Cette demande n’est pas encore validé !",
  nonCompleted: "non-Complet",
  noRoleConfigured: "Role non-configuré!",
  noDataFound: "Aucune information trouvée !",
  noNotificationsFound: "Aucune notification trouvée !",
  collaboratorNotConfigured: "Ce collaborateur n'est pas configuré !",
  calendarNotConfigured: "le calendrier n'est pas configuré !",
  noClientsFound: "Aucun client trouvé pour le filtre activé.",
  noBalanceFound: "Aucune balance trouvée pour le collaborateur sélectionné",
  noCollaboratorsFound: "Aucun collaborators trouvés pour le filtre activé.",
  noCollaboratorProductionFound:
    "Aucune production attribuée par le collaborateur pour cette période/catégorie.",
  oops: "Oups !",
  serverError: "Error de serveur! essayer plutard.",
  wip: "Travail en cours",
  wipWorkshop: "Ce chantier est en cour d'élaboration.",
  encoding: "Encodage",
  closure: "Clôture",
  subTitleEncodageList: "VISUALISER ET GÉRER L'ENCODAGE DE VOS COLLABORATEURS",
  filesOverLimit: "Dossiers avec dépassement",

  deletedCollab: "Collaborateur supprimé",
};
export default teamFr;
