import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { BriefCaseIcon } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import CapacityPlaningMatrix from "./Matrix";

let tabs = [
  {
    id: "CAPACITY_PLANING",
    label: "capacityPlaning",
  },
  {
    id: "SYNTHESE",
    label: "synthese",
  },
];

const CapacityPlaningPage = () => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);

  const [filter, setFilter] = useState({
    tab: tabs[0].id,
    year: new Date().getFullYear().toString(),
  });

  const renderCapacityPlaningTabContent = () => {
    switch (filter.tab) {
      case "CAPACITY_PLANING":
        return (
          <CapacityPlaningMatrix
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "SYNTHESE":
        return (
          <div className="no_data">
            <div className="title">{_("wip")}</div>
            <div className="subTitle">{_("wipWorkshop")}</div>
          </div>
        );
      default:
        return;
    }
  };
  return (
    <div className="page-content">
      <PageHeader
        header={_("capacityPlaning")}
        subHeader={_("subTitleCapacityPlaning")}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      />
      <div className="tab-container">
        <TTPTab2
          tabs={tabs}
          width="45%"
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div>

      <div className="content-body large-content">
        {renderCapacityPlaningTabContent()}
      </div>
    </div>
  );
};

export default CapacityPlaningPage;
