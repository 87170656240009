import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import _ from "../../i18n";
import { FilterIcon } from "../common/Icons";
import "./TTPFilterHorizontalBar.local.scss";
import "moment/locale/fr";
import "moment/locale/nl";

const TTPFilterHorizontalBar = ({
  clientID,
  cssClass,
  button,
  marginLeft,
  filterFormIsVisible: propsFilterFormIsVisible,
  filters,
}) => {
  const [filterFormIsVisible, setFilterFormIsVisible] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [clientLabel, setClientLabel] = useState("");
  const [client, setClient] = useState("");

  useEffect(() => {
    if (propsFilterFormIsVisible) {
      propsFilterFormIsVisible(filterFormIsVisible);
    }
  }, [filterFormIsVisible]);

  const handleToggleFilterForm = () => {
    setFilterFormIsVisible(!filterFormIsVisible);
  };

  const [initialClientID, setInitialClientID] = useState(clientID);

  useEffect(() => {
    if (clientID && clientID === initialClientID) {
      const selectedLabel = filters[0]?.options?.options.find(item => item.id == clientID)?.name;
      if (selectedLabel) {
        setClientLabel(selectedLabel);
        setInitialClientID("");
      } else {
        setClientLabel("");
      }
    }
  }, [clientID, filters]);

  useEffect(() => {
    const selectedLabel = filters[0]?.options?.options.find(item => item.id == client)?.name;
    if (selectedLabel) {
      setClientLabel(selectedLabel);
    } else {
      setClientLabel("");
    }
  }, [client]);

  const onInputChange = (inputValue, action, isSimple = false) => {
    let selected = null;
    let selectedLabel = null;

    if (isSimple) {
      selected = inputValue ? inputValue.value + "" : null;
      selectedLabel = inputValue ? inputValue.label + "" : null;
    } else {
      selected = inputValue.map(({ value }) => value);
      selectedLabel = inputValue.map(({ label }) => label);
    }

    if (selected) {
      setClient(selected);
      setClientLabel(selectedLabel);
      action(selected);
    }
  };

  const renderFilters = () => {
    let filterCols = [];
    if (filters && filters.length > 0) {
      filters.forEach((filter, index) => {
        filterCols.push(
          <div key={`col-${index}`} className={`small-${filter.grid}`}>
            {renderFilterByType(filter.type, filter.options)}
          </div>
        );
      });
    }
    return (
      <div className="columns">
        <div className="row">{filterCols}</div>
      </div>
    );
  };

  const renderFilterByType = (type, options) => {
    switch (type) {
      case "SELECT":
        return renderSimpleSelect(options);
      default:
        return <div />;
    }
  };

  const renderSimpleSelect = options => {
    const colourStyles = {
      menu: (provided) => ({
        ...provided,
        color: "hsl(0,0%,20%)",
        width: "285px",
      }),
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : 'white',
        color: 'black',
        cursor: 'pointer',
        zIndex: 3,
        padding: '15px'
      }),
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: data.color + "22"
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          backgroundColor: data.color,
          color: "white"
        }
      })
    };

    const formattedOptions = options.options.map(o => ({ value: o.id, label: o.name }));
    const filteredValues = filterValues(formattedOptions, options.values);

    return (
      <div key={options.placeholder} className="columns" style={{ marginLeft: "20px" }}>
        <label>
          <span className="lbl">{options.placeholder}</span>
          <Select
            setActive={true}
            isMulti={options.isMulti !== undefined ? options.isMulti : false}
            isSearchable={true}
            isClearable={options.isClearable !== undefined ? options.isClearable : false}
            closeMenuOnSelect
            placeholder={clientLabel || "Client"}
            options={formattedOptions}
            value={filteredValues}
            onChange={inputValue => onInputChange(inputValue, options.action, options.isSimple)}
            styles={colourStyles}
            className={`ttp-select ${options.values && options.values.length > 0 ? "" : "empty-box"}`}
          />
        </label>
      </div>
    );
  };

  return (
    <div id="ttp-horizontal-filter" className={`${filterFormIsVisible && "ttp-horizontal-filter__open"} ${cssClass}`}>
      <div className="filter-wrapper">
        <div className="filter-wrapper">
          <span className="count_item">
            {"Le client est "} : <p>{clientLabel}</p>
          </span>
        </div>
        <div className="filter-wrapper">
          {button && (
            <button className="filter-button m-l-s" onClick={handleToggleFilterForm}>
              {_("filter")}
              <FilterIcon className="filter-icon" />
            </button>
          )}
        </div>
      </div>
      <CSSTransition
        in={filterFormIsVisible}
        timeout={200}
        classNames="filter-form"
        unmountOnExit
      >
        <div>
          <div className="row filter-form">
            {renderFilters()}
            <div className="filter-close" onClick={handleToggleFilterForm}>
              <img
                className="icomoon icon-tt-close"
                src="/img/icon/close.svg"
                alt={""}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const filterValues = (options, values) => {
  if (options && options.length > 0 && options[0].options) {
    options = options.reduce((acc, o) => acc.concat(o.options), []);
  }
  let result = options.filter(({ value }) => {
    return (
      values &&
      (Array.isArray(values) ? values.includes(value) : value === values)
    );
  });
  return result;
};

export default TTPFilterHorizontalBar;
