import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCollaboratorsBonuses } from "../../../api";

import "./Bonuses.local.scss";
import { renderCollaboratorAvatar } from "../../../utils";
import { CustumedMatrix } from "tamtam-components";
import { Tooltip } from "antd";
import { COLLABORATOR_ROLE_ATTRIBUT } from "../../../config";
import _ from "../../../i18n";

const Bonuses = () => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState(null);
  const [selectedEcheanceClosingDate, setSelectedEcheanceClosingDate] =
    useState(null);
  const [filter, setFilter] = useState({
    keyWord: "",
    selectedZone: null,
    year: new Date().getFullYear().toString(),
    // pageSize: 10,
    // paginationPage: 1,
    // collaborators: [],
    // deletedStatus: null,
    // clientCRMGroup: [],
    // clientCRMTypes: [],
    // counters: [],
  });
  const [columns, setColumns] = useState([
    {
      id: "balance",
      label: _("Balance"),
      isEditable: true,
      widthPercentage: 20,
    },
    {
      id: "ipp_cr",
      label: _("Ipp CR"),
      isEditable: true,
      widthPercentage: 23,
    },
    {
      id: "ipp",
      label: _("Ipp"),
      isEditable: true,
      widthPercentage: 18,
    },
    {
      id: "horus",
      label: _("Horus"),
      isEditable: true,
      widthPercentage: 6,
    },
    {
      id: "intermediateSituation",
      label: _("Int. Situation"),
      isEditable: true,
      widthPercentage: 11,
    },
  ]);
  const [collaborators, setCollaborators] = useState(null);
  const [data, setData] = useState(null);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);

  let reportingsQuery = useQuery(
    ["getCollaboratorsBonuses", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsBonuses(
            token,
            navCommunity.id,
            "2024"
          );
          const defaultCollaborator = {
            id: null,
            name: "Tous les collaborateurs",
          };
          setCollaborators(response.data.data);
          setData(response.data.data.data);
          setLoadingCollaborators(false);
        } catch (e) {
          setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const handleColumns = (column, line) => {
    if (column.id === "balance") {
      return (
        <div
          className="tags"
          style={{ margin: "0px 10px 2px 2px" }}

          // style={{ marginTop: "20px", justifyContent: "center" }}
        >
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.createdTasks} %</div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("Blian fini deg")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.balanceFinishedDeg}</div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("Retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.delay}</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "horus") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div 
          // className="labled-tag no-border" 
          className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("client horus")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.horus.clientsHorus} %</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp_cr") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.createdTasks} %</div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && (
              <span>{_("Presentation déclaré")}</span>
            )}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.isPresentationDeclared} </div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && (
              <span>{_("taches completes")}</span>
            )}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.completedTaskes} </div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.createdTasks} %</div>
            </div>
          </div>

          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && (
              <span>{_("taches comptetes")}</span>
            )}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.completedTaskes} </div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "intermediateSituation") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.intermediateSituation.createdTasks} %</div>
            </div>
          </div>
          <div className={`no-border ${data.indexOf(line) === 0 ? 'labled-tag ' : ''}`}>
            {data.indexOf(line) === 0 && <span>{_("Presentation Si")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.intermediateSituation.isPresentationSi} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "timesheet") {
      return (
        <div className="tags" style={{ margin: "20px 10px" }}>
          <div className="labled-tag no-border">
            <span>{_("Encodage Réalisé")}</span>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedEncodage)} */}
              </div>
            </div>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <span>{_("Nettoyage Réalisé")}</span>
            </div>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedNettoyage)} */}
              </div>
            </div>
          </div>
          <div className="labled-tag no-border">
            <span>{_("Bilan Réalisé")}</span>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                width: "85%",
              }}
            >
              <span>{_("Rapport")}</span>
            </div>
            <div className="tag">
              <div
                style={{
                  color:
                    line.timesheet.rapport > 2
                      ? " #06D9B1"
                      : line.timesheet.rapport > 1.5
                      ? "#EA8124"
                      : "#eb5757",
                  backgroundColor:
                    line.timesheet.rapport > 2
                      ? " rgba(6, 217, 177, 0.1)"
                      : line.timesheet.rapport > 1.5
                      ? "rgb(235 152 87 / 17%)"
                      : "rgba(235, 87, 87, 0.1)",
                }}
              >
                {line.timesheet.rapport}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleTitle = (title) => {
    const isFirstItem = data.indexOf(title) === 0;
    return (
      <div
        className="matrix_title"
        style={{ paddingTop: isFirstItem ? "50px" : 0, minWidth: "20 px" }}
      >
        <div>{title.collaborator_name}</div>
      </div>
    );
  };
  return (
    <div className="page-content">
      <div className="bonuses_table">
        {loadingCollaborators ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : (
          data && (
            <div className="production-frame">
              <CustumedMatrix
                columns={columns}
                items={data}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Bonuses;
