import { useSelector } from "react-redux";
import _ from "../../../i18n";
import { CustumedMatrix } from "tamtam-components";
import { getVolumetryCliets } from "../../../api";
import { useQuery, useQueryClient } from "react-query";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

import "./PrestationSuivi.local.scss";
import { getMonthShortLabelObject, useOnClickOutside, renderTeamYears } from "../../../utils";
import TTPPaginator from "../../common/TTPPaginator";
import PageSize from "../../common/Filter/PageSize";

const PrestationSuivi = ({ lng }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [nbrClients, setNbrClients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState( new Date().getFullYear());
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [filter, setFilter] = useState({
    pageSize: 10,
    paginationPage: 1,
    filterTab: [
      {
        id: "MONTHLY",
        label: "monthly",
        subTab: getMonthShortLabelObject(lng),
      },
      { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    ],
    period: { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    subPeriod: "01-01",
  });
  const queryClient = useQueryClient();

  const fetchVolumetryClients = async () => {
    if (token && navCommunity) {
      try {
        setLoading(true);
        const filterSubPeriodStart =
          filter.subPeriod && filter.subPeriod.start
            ? filter.subPeriod.start
            : "01-01";
        const formattedDate = `${year}-${filterSubPeriodStart}`;
        const response = await getVolumetryCliets(
          token,
          navCommunity.id,
          formattedDate,
          filter,
        );
        setData(response.data.data.pagination);
        setNbrClients(response.data.nbResult ? response.data.nbResult : 0);
        setLoading(false);
        return response.data.data;
      } catch (e) {
        setLoading(false);
        throw new Error(e.message);
      }
    }
  };

  useQuery(
    ["getVolumetryCliets", token, navCommunity, filter],
    fetchVolumetryClients,
    {
      enabled: !!token && !!navCommunity,
    },
  );
  useEffect(() => {
    fetchVolumetryClients();
  }, [filter,year]);

  useEffect(() => {
    if (filter.period.id === "ANNUAL") {
      setFilter({ ...filter, subPeriod: "01-01" });
    }
  }, [filter.period.id]);

  const columnsClientsInformations = [
    {
      id: "type",
      label: "Type",
      isEditable: true,
      widthPercentage: 12,
    },
    {
      id: "volumetry",
      label: "Caractéristiques encodage",
      isEditable: true,
      widthPercentage: 17,
    },
    {
      id: "tasks",
      label: "Time Sheet",
      isEditable: true,
      widthPercentage: 45,
    },
    {
      id: "5€",
      label: "5.00 €",
      isEditable: true,
      widthPercentage: 7,
    },
    // {
    //   id: "ceiling_compliance_1_6",
    //   label: "Plafond en respect de la norme 1/6",
    //   isEditable: true,
    //   widthPercentage: 30,
    // }
  ];

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={nbrClients}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value).toLocaleString();
    const minutes = Math.round((value - Math.floor(value)) * 60);
    return `${hours}h ${minutes}m`;
  };

  const formatLabel = (key) => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleColumns = (column, line) => {
    switch (column.id) {
      case "type":
        return (
          <div className="colomn" style={{ margin: "15px 0" }}>
           {Object.keys(line.type).map((key, index) => (
              <div
                className="labled-tag no-border"
                key={index}
                style={{ justifyContent: "flex-end", textAlign: "center", marginLeft: "6px" }}
              >
                {data.indexOf(line) === 0 && <span>{_(key)}</span>}
                {line.client_id === "%" ? (
                  <div
                    style={{
                      backgroundColor: "#dfdfdf",
                      marginTop: "47.5px",
                      fontSize: "11px",
                    }}
                  >
                    {convertToHoursMinutes(line.type[key])}
                  </div> 
                ) : (
                  <div >
                    {convertToHoursMinutes(line.type[key])}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "tasks":
        return (
          <div
            className="colomn"
            style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 0"}}
          >
            {line.tasks.map((item, key) => (
              <div
                className="labled-tag no-border"
                key={key}
                style={{
                  justifyContent:
                    data.indexOf(line) === 0 ? "flex-end" : "center",
                  paddingLeft: "10px",
                  textAlign: "center"
                }}
              >
                {data.indexOf(line) === 0 && (
                  <span>
                    { item.label_fr.substr(0, 24).toLowerCase()}
                    {item.label_fr.length > 25 ? "." : ""}
                  </span>
                )}

                {line.client_id === "%" ? (
                  <>
                    <div
                        style={{
                          backgroundColor: "#dfdfdf",
                          marginTop: "10px" 
                        }}
                      >
                      {`${item.percentage } %`}
                    </div> 
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        marginTop: "10px",
                        fontSize: "11px",
                      }}
                    >
                      {convertToHoursMinutes(item.task_duration)}
                    </div> 
                  </>
                ) : (
                  <div
                    style={{
                      backgroundColor: item.label_fr === "Valeur" && "#dfdfdf",
                    }}
                  >
                    {convertToHoursMinutes(item.task_duration)}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "volumetry":
        return (
          <div className="colomn" style={{ margin: "15px 0" }}>
           {Object.keys(line.volumetry).map((key, index) => (
              <div
                className="labled-tag no-border"
                key={index}
                style={{ justifyContent: "flex-end", textAlign: "center", marginLeft: "6px" }}
              >
                {data.indexOf(line) === 0 && <span>{formatLabel(key)}</span>}
                {line.client_id === "%" ? (
                  <div
                    style={{
                      backgroundColor: "#dfdfdf",
                      marginTop: "47.5px",
                      fontSize: "11px",
                    }}
                  >
                    {line.volumetry[key]}
                  </div> 
                ) : (
                  <div >
                    {line.volumetry[key] !== undefined ? line.volumetry[key] : 0}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "5€":
        return (
          <div className="colomn" style={{ padding: "15px 0" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "flex-end", textAlign: "center" }}
            >
              {data.indexOf(line) === 0 && <span>{line["_5€"].label}</span>}
              {line.client_id === "%" ? (
                  <>
                    <div
                      style={{
                        backgroundColor: "#90ee90",
                        marginTop: "10px",
                        marginLeft: "10px",
                        fontSize: "11px",
                        color: "green"
                      }}
                    >
                      {`+ ${line["_5€"].diff}`}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        marginTop: "10px",
                        marginLeft: "10px",
                        fontSize: "11px",
                      }}
                    >
                      {line["_5€"].value.toFixed(2)}
                    </div>
                  </> 
                ) : (
                  <div style={{ marginLeft: "10px" }}>
                    {line["_5€"].value ? line["_5€"].value.toFixed(2) : 0}
                  </div>
                )}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleTitleClients = (title) => (
    <Fragment>
      {title.client_name.length > 0 ? (
        <div className="clients_panel">
            <div
              className="title_infos"
              style={{
                textAlign: data.indexOf(title) === 0 && "center", zIndex: 3
              }}
            >
              {title.client_name}
            </div>
          
        </div> ) : (
          <div className="clients_panel" style={{ top: 60, height: 300, margin: 0, padding: 0 }} />
        )
      }
    </Fragment>
  );
  const renderFilterFrame = () => {
    const year = new Date().getFullYear();

    return (
      <div className="filter-frame" style={{ width: "100%", padding: "10px 5px" }}>
        <div className="principal-line" style={{ width: "100%" }}>
          <div className="filter-wrapper">{renderYearSelect()}</div>
          <div style={{ display: "flex" }}>
            <div className="mini-tabs-frame" style={{margin: "0"}}>
              {filter.filterTab.map((item) => (
                <div
                  key={item.id}
                  className={
                    item.id === filter.period.id ? "mini-tabs-frame_active" : ""
                  }
                  onClick={() => {
                    setFilter({
                      ...filter,
                      period: item,
                      subPeriod: "ANNUAL" !== item.id ? item.subTab[0] : null,
                    });
                  }}
                >
                  {_(item.label)}
                </div>
              ))}
            </div>
            <PageSize
              pageSize={filter.pageSize}
              onPageSizeChange={(item) => setFilter({ ...filter, pageSize:  item })}
            />
          </div>
        </div>

        {"ANNUAL" !== filter.period.id ? (
          <div className="additional-line">
            {filter.period.subTab.map((tab) => {
              return (
                <div
                  key={tab.id}
                  className={`tag ${filter.subPeriod?.id === tab.id ? "active" : ""}`}
                  onClick={() => setFilter({ ...filter, subPeriod: tab })}
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };
  
  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => setYear(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="page-content" style={{ background: "none" }}>
      <div className="ttp-tab-container">
        <div
          className="content-body large-content"
          style={{ padding: "0" }}
        >
          {renderFilterFrame()}
          {loading ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : data.length > 0 ? (
            <Fragment>
              <div style={{ overflowX: "auto", marginTop: 10 }}>
                <div style={{ width: "116%" }}>
                  <CustumedMatrix
                    columns={columnsClientsInformations}
                    items={data}
                    handleCellule={handleColumns}
                    handleTitle={(title, index) => handleTitleClients(title, index)}
                    headerTagBG={"#FFFFFF"}
                  />
                </div>
              </div>
              {renderPaginate()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noReportingsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrestationSuivi;
