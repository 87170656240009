import axios from "axios";
import { TTP_API_URL } from "../config";

export const getRevisionForfait = (token, organizationId, clientId) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/revision-forfaits`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      clientId: clientId
    },
  });
};

export const getListClient = (token, organizationId) => {
  const requestUrl = `${TTP_API_URL}/team/clients`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId
    },
  });
};

