import React, { Fragment, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Badge } from "antd";
import moment from "moment";
import { getManagerNotification, getBU } from "../../api";
import { setManagerNotification } from "../../store";
import { getUserNameForAvatar, generateFullName, getNotificationManagerCollaboratorName } from "../../utils";
import classnames from "classnames";
import styles from "./subMenu.module.scss";

import {
  SynchroDownIcon,
  AlertTriangleIcon,
  SynchroRuningIcon,
  IconChevronDown,
  MaximizeIcon,
  IconPlus,
  CircleIcon,
  BellIcon,
} from "../common/Icons";
import _ from "../../i18n";
import { NOTIFICATION_TABS } from "../../config";
import {
  useInterval,
  renderNotificationManagerMessage,
  renderNotificationCollaboratorMessage,
} from "../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import { Tooltip } from "antd";
import { ActivitiesNotificationsIcon } from "../common/Icons/ActivitesNotificationsIcon";

const SubMenu = (menu) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.params);
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS[0]);
  const { synchronizationList, managerNotification, loggedAs } = auth;

  const [activeBu, setActiveBu] = useState('all');
  const [listCollaboratorFiltredByBu, setListCollaboratorFiltredByBu] =
    useState([]);
  const initialElement = { id: 'all', label: _('allNotif') };
  const [BU_LIST, setBU_LIST] = useState([initialElement]);
  const [notificationsPresence, setNotificationsPresence] = useState(null);

  const [showSynchronizationNotifications, setShowSynchronizationNotifications] = useState(false);
  const [showManagerNotifications, setShowManagerNotifications] = useState(false);

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id
      ).authorisation.preferences
    : {};

  useInterval(() => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotifications || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        auth.loggedAs,
        allowedNotifications
      );
      managerNotificationQuery.then((result) => {
        if (
          result.data.data &&
          managerNotification &&
          managerNotification.nbrResult < result.data.data.nbrResult
        ) {
          const data = [...result.data.data.data];
          const pendingData = data.filter((not) => not.status === "PENDING");
          dispatch(
            setManagerNotification({
              nbrResult: result.data.data.nbrResult,
              countPending: pendingData
                ? pendingData.length + managerNotification.countPending
                : 0,
              data,
            })
          );
          setInterval({
            ...interval,
            start: 10,
          });
        }
      });
    } catch (e) {
      return null;
    }
  }, 100000);

  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  const handleFetchNotifications = async () => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotifications || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.loggedAs === "COLLABORATOR" ? auth.user.uid : null,
        auth.navCommunity.id,
        auth.token,
        auth.loggedAs,
        allowedNotifications,
        interval.start,
        interval.limit
      );
      if (managerNotificationQuery) {
        managerNotificationQuery.then((result) => {
          const data = [...managerNotification.data, ...result.data.data.data];
          const pendingData = data.filter((not) => not.status === "PENDING");
          dispatch(
            setManagerNotification({
              nbrResult: managerNotification.nbrResult,
              countPending: pendingData ? pendingData.length : 0,
              data,
            })
          );
          setInterval({
            ...interval,
            start: interval.start + 10,
          });
        });
      }
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    async function fetchBUData() {
      try {
        const responseBu = await getBU({
          token: auth.token,
          organizationId: auth.navCommunity.id,
        });
        if (responseBu) {
          setBU_LIST((prevBUList) => [initialElement, ...responseBu.data.data]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données BU:", error);
      }
    }
    fetchBUData();
  }, []);

  useEffect(() => {
    if (synchronizationList && synchronizationList.listeCollaboarators)
      setListCollaboratorFiltredByBu(synchronizationList.listeCollaboarators);
  }, [synchronizationList]);

  useEffect(() => {
    if (synchronizationList && synchronizationList.listeCollaboarators) {
      if (activeBu === 'all') {
        setListCollaboratorFiltredByBu(synchronizationList.listeCollaboarators);
      } else {
        const filteredCollaborators = synchronizationList.listeCollaboarators.filter(
          (collaborator) => collaborator.BusinessUnit === activeBu
        )
        setListCollaboratorFiltredByBu(filteredCollaborators);
      }
    }
  }, [activeBu]);

  useEffect(() => {
    if (notificationsPresence === null || notificationsPresence['all'] === false) {
      const presence = BU_LIST.reduce((acc, tab) => {
        const hasNotifications = listCollaboratorFiltredByBu.some(
          (notif) => notif.BusinessUnit === tab.id || tab.id === "all"
        );
        acc[tab.id] = hasNotifications;
        return acc;
      }, {});
      setNotificationsPresence(presence);
    }
  }, [BU_LIST, listCollaboratorFiltredByBu]);

  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  const handleMenuClick = (title) => {
    setActiveMenu(activeMenu === title ? null : title);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveMenu(null);
    }
  };
  const handleLinkClick = () => {
    setActiveMenu(null);
  };

  const renderNotificationItemIcon = (notificationItem) => {
    if (activeTab.tab === 'allNotifications') {
      const otherTabs = NOTIFICATION_TABS.filter(tab => tab.tab !== 'allNotifications')

      for (let i = 0; i < otherTabs.length; i++) {
        if (otherTabs[i]?.actions?.includes(notificationItem.data.action)) {
          return React.cloneElement(
            otherTabs[i]?.icon,
            {fill: notificationItem.status === "PENDING" ? "#18a0fb" : "#6D7F92"}
          )
        }
      }
    } else if (activeTab?.actions?.includes(notificationItem.data.action)) {
      if (activeTab.icon) {
        return React.cloneElement(
          activeTab?.icon,
          {fill: notificationItem.status === "PENDING" ? "#18a0fb" : "#6D7F92"}
        )
      }
    }
    return null;
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTabCount = (tab) => {
    if (tab.tab === 'allNotifications') {
      let count = 0;
      for(let i = 0; i < managerNotification.data.length; i++) {
        if (managerNotification.data[i].status === 'PENDING') {
          ++count;
        }
      }
      return count;
    }

    let count = 0
    for(let i = 0; i < managerNotification.data.length; i++) {
      if (tab.actions.includes(managerNotification.data[i].data.action) && managerNotification.data[i].status === 'PENDING') {
        ++count;
      }
    }

    return count;
  }

  return (
    <nav ref={menuRef} id="subMenu" className="sub-menu">
      <ul className="menu">
        {menu &&
          menu.menu &&
          menu.menu.map((i) => (
            <li key={i.title}>
              {!i.subMenu ? (
                <NavLink
                  to={i.url}
                  activeClassName="active"
                  className="menu__link">
                  <img src={i.iconUrl} alt={i.title} />
                  <span className="title">{i.title}</span>
                </NavLink>
              ) : (
                <div className={styles.submenu}>
                  <div
                    className={styles.menu__item_header}
                    onClick={() => handleMenuClick(i.title)}>
                    <img src={i.iconUrl} alt={i.title} />
                    <span className="title">{i.title}</span>
                    {/* <IconChevronDown className="chevron-icon" /> */}
                  </div>
                  {activeMenu === i.title && (
                    <ul
                      className="submenu_details"
                      style={{
                        marginTop: "11px",
                        backgroundColor: "#384658",
                        color: "#fff",
                        zIndex: 1,
                        borderBottom: " 1px solid #344254",
                        textAlign: "center",
                      }}>
                      {i.subMenu.map((subMenu) => (
                        <li key={subMenu.title}>
                          <NavLink
                            to={subMenu.url}
                            activeClassName="active"
                            className="submenu__link"
                            style={{
                              color: "white",
                              alignItems: "center ",
                              borderBottom: " 1px solid #344254",
                              color: " #c7e8fe ",
                              fontFamily:
                                "Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
                            }}
                            onClick={handleLinkClick}>
                            {subMenu.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </li>
          ))}
      </ul>


      {(loggedAs === "COLLABORATOR" ||
        (preferences &&
          (preferences.activateCarteNotifications ||
            preferences.activateCalendarNotifications) &&
          loggedAs === "MANAGER")) && (
        <div className={"notifications"}>
          <Badge
            count={
              managerNotification && managerNotification.countPending > 0
                ? managerNotification.countPending
                : 0
            }
            className={classnames(
              "notification_badge",
              managerNotification && managerNotification.countPending > 0
                ? "notifications_without_header"
                : ""
            )}
            style={{
              transform: "translate(116%, 85%)",
              minWidth: "24px",
              height: "14px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
            onClick={
              () => {
                setShowManagerNotifications(!showManagerNotifications)
                setShowSynchronizationNotifications(false);
              }
            }>
            <BellIcon fill="#c7e8fe" size="20" className="notification_badge" />
          </Badge>
          {showManagerNotifications && (
            managerNotification && managerNotification.data.length > 0 ? (
              <div className="notifications_list">
                <Fragment>
                  <div className="notifications_list_header">
                    <div className="notifications_list_header_title">
                      <div>Notifications</div>
                      <Link to={`/${language}/notifications`}>
                        <div onClick={() => setShowManagerNotifications(false)}>
                          <MaximizeIcon />
                        </div>
                      </Link>
                    </div>

                    <div className="notifications_list_header_tabs">
                      {NOTIFICATION_TABS.map((tab) => (
                        <div
                          className={`tab ${
                            activeTab.tab === tab.tab ? "tab_active" : ""
                          }`}
                          onClick={() => setActiveTab(tab)}>
                          {_(tab.tab === 'allNotifications' ? 'allNotif' : tab.tab)} ({getTabCount(tab)})
                          <span className="tab_bottom_line" />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="notifications_list_items">
                    {managerNotification &&
                    managerNotification.data.filter(
                      (notific) =>
                        activeTab.tab === "allNotifications" ||
                        activeTab.actions.includes(notific.data.action)
                    ).length > 0 ? (
                      managerNotification &&
                      managerNotification.data
                        .filter(
                          (notific) =>
                            activeTab.tab === "allNotifications" ||
                            activeTab.actions.includes(notific.data.action)
                        )
                        .map((notif) => (
                          <Link
                            to={NOTIFICATION_TABS.filter(
                              (tab) =>
                                tab.actions &&
                                tab.actions.includes(notif.data.action)
                            )[0]
                              .redirect.replace(":language", language)
                              .replace(":id", notif.collaborator)}>

                            <div className={`notifications_list_item ${notif.status === 'PENDING' ? 'notifications_list_item_pending' : ''}`}>
                              <div className="avatar">
                                <CircleIcon
                                  width="6"
                                  height="6"
                                  fill={
                                    notif.status === "PENDING" ? "#18a0fb" : ""
                                  }
                                  className="notification_status_indicator"
                                />

                                <div className="avatar_picture">
                                  {renderAvatar(notif.avatarUrl?.avatar, getNotificationManagerCollaboratorName(notif.data))}
                                </div>
                              </div>

                              <div className="details">
                                <div className="title">{_(notif.data.action)}</div>
                                <div className="description">
                                  {loggedAs === "COLLABORATOR"
                                    ? renderNotificationCollaboratorMessage(
                                        notif.data.action,
                                        notif.data
                                      )
                                    : renderNotificationManagerMessage(
                                        notif.data.action,
                                        notif.data
                                      )}
                                </div>
                              </div>

                              <div className="notification_type_icon">
                                {renderNotificationItemIcon(notif)}
                              </div>
                            </div>
                          </Link>
                        ))
                    ) : (
                      <div className="no_data_notif min" style={{ width: 350 }}>
                        <div className="title">{_("noNotificationsFound")}</div>
                      </div>
                    )}
                    {managerNotification &&
                      managerNotification.nbrResult >
                        managerNotification.data.length && (
                        <div
                          className="fetch_more"
                          onClick={() => handleFetchNotifications()}>
                          <div>
                            <IconPlus size="12" />
                          </div>
                        </div>
                      )}
                  </div>
                </Fragment>
              </div>
            ) : (
              <div className="no_data_notif min">
                <div className="title">{_("noNotificationsFound")}</div>
              </div>
            )
          )}
        </div>
      )}

      {/* Synchronization Notifications */}
      {loggedAs === "MANAGER" && (
        <div className={"notifications"}>
          <Badge
            count={
              synchronizationList &&
              synchronizationList.listeCollaboarators &&
              synchronizationList.synchro_is_runing &&
              synchronizationList.listeCollaboarators.length > 0
                ? synchronizationList.listeCollaboarators.length
                : 0
            }
            className={classnames(
              "notification_badge",
              synchronizationList &&
                synchronizationList.listeCollaboarators &&
                synchronizationList.listeCollaboarators.length > 0
                ? "notifications_without_header"
                : "",
              synchronizationList &&
                !synchronizationList.synchro_is_runing &&
                "synchronization_alert"
            )}
            style={{
              transform: "translate(116%, 85%)",
              minWidth: "24px",
              height: "14px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
            onClick={
              () => {
                setShowSynchronizationNotifications(!showSynchronizationNotifications)
                setShowManagerNotifications(false);
              }
            }
            >
            {synchronizationList &&
              (synchronizationList.synchro_is_runing ? (
                // <SynchroRuningIcon />
                <ActivitiesNotificationsIcon/>
              ) : (
                <SynchroDownIcon />
                // <ActivitiesNotificationsIcon/>
              ))}
          </Badge>

          {showSynchronizationNotifications && synchronizationList && synchronizationList.synchro_is_runing && (
            <div className="notifications_list">
              <div className="notifications_list_header">
                <div className="notifications_list_header_title">
                  <div>Activités</div>
                  <Link to={`/${language}/Notifications-activite`}>
                    <div onClick={() => setShowSynchronizationNotifications(false)}>
                      <MaximizeIcon />
                    </div>
                  </Link>
                </div>
                <div className="notifications_list_header_tabs">
                  {BU_LIST.map((tab) => {
                    const hasNotifications = notificationsPresence[tab.id];
                    return hasNotifications ? (
                      <div
                        key={tab.id}
                        className={`tab ${
                          activeBu === tab.id ? "tab_active" : ""
                        }`}
                        onClick={() => setActiveBu(tab.id)}>
                        {_(tab.label)}
                        <span className="tab_bottom_line" />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <div className="notifications_list_items">
                {listCollaboratorFiltredByBu &&
                listCollaboratorFiltredByBu.length > 0 ? (
                  listCollaboratorFiltredByBu.map((notif) => (
                    <div className="notifications_list_item centered_content">
                      <div className="avatar avatar_centered">
                        {renderAvatar(notif.avatarUrl, notif.name)}
                      </div>
                      <div className="details">
                        <div className="title">
                          {notif.name}
                        </div>
                        <div className="description">
                          {notif.BusinessUnit
                            ? BU_LIST.map((tab) => {
                                return tab.id === notif.BusinessUnit
                                  ? tab.label
                                  : null;
                              })
                            : "tous"}
                        </div>
                      </div>

                      {notif.state === "NOT_CONFIGURED" ? (
                        <Fragment>
                          <Tooltip
                            key="tool-2"
                            placement="top"
                            title={_("regimeNotConfigurated")}
                            mouseLeaveDelay={0}>
                              <AlertTriangleIcon
                                fill="#FF636E"
                                className="icon_tag"
                                size="14"
                              />
                          </Tooltip>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="text_tag">
                            <TiArrowSortedDown className="text_tag_icon" />
                            <span>{`${parseFloat(
                              parseFloat(notif.ecart).toFixed(2)
                            )}H`}</span>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  ))
                ) : (
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    {" "}
                    Aucune notification n'est disponible{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}></div>
    </nav>
  );
};

export default SubMenu;
