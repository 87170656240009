import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";

import { getPortfolioList, getCollaboratorsAvatars } from "../../../api";
import _ from "../../../i18n";

import { Tooltip } from "antd";
import Item from "./Item";
// import PortfolioPlaningFilter from "./PortfolioPlaningFilter";
import "./PortfolioPlaning.local.scss";
import { TiWarning } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    id: "responsable",
    label: _("responsable"),
    widthPercentage: 23,
  },
  {
    id: "cp",
    label: `${_("capacityPlanning")} (%)`,
    widthPercentage: 13,
  },
  {
    id: "budgetedHours",
    label: `${_("budgetedHours")} (h)`,
    widthPercentage: 20,
  },
  {
    id: "realHours",
    label: `${_("realHours")} (h)`,
    widthPercentage: 12,
  },
  {
    id: "budgetedCA",
    label: `${_("budgetedCA")} (€)`,
    widthPercentage: 12,
  },
  {
    id: "realCA",
    label: `${_("realCA")} (€)`,
    widthPercentage: 12,
  },
];

const PortfolioPlaning = ({ token, navCommunity, language }) => {
  const [activeAttachedClient, setActiveAttachedClient] = useState(0);
  const [collapseIntervenants, setCollapseIntervenants] = useState(true);
  const [portfolios, setPortfolios] = useState([]);
  const [cp, setCapacityPlanings] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    clients: [],
    budgetStatus: null,
    deletedStatus: "NOT_DELETED",
    clientCRMGroup: [
      _("AiSBL"),
      _("ASBL"),
      _("ASBL - Consultation"),
      _("ASBL - Partenaire"),
      _("ASBL petite entreprise"),
      _("Contact"),
      _("Fondation privée"),
      _("Indépendants"),
      _("Indépendants - Consultation"),
      _("Indépendants petite entreprise"),
      _("Partenaire"),
      _("Particuliers"),
      _("Particuliers - Consultation"),
      _("Sociétés"),
      _("Sociétés - Partenaire"),
      _("Sociétés petite entreprise"),
    ],
    clientCRMTypes: [],
    sortBy: [],
    filterIsClientGroupReseted: true,
  });

  let portfolioQuery = useQuery(
    [("getPortfolioList", token, navCommunity, filter)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getPortfolioList(
            navCommunity.id,
            token,
            filter
          );
          setPortfolios(response.data.data.data);
          setCapacityPlanings(response.data.data.cp);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const navigate = useNavigate();

  const handleOnClick = (id, label) => {
    const filter = { value: Number(id), label };
    localStorage.setItem("filterCol", JSON.stringify(filter));
    navigate(`/${language}/clients`);
  };

  const renderFetchingLines = () => {
    let cardFetching = [];
    for (let i = 0; i < 10; i++) {
      cardFetching.push(
        <div key={i} className="line_fetching">
          <div className="infos">
            <div className="infos_header gradiant" />
            <div className="infos_sub_header gradiant" />
          </div>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}>
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}>
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="avatars">
            {[1, 2, 3, 4].map((index) => (
              <div className="avatar gradiant" key={index} />
            ))}
          </div>
        </div>
      );
    }
    return cardFetching;
  };

  return (
    <div className="portfolio_container">
      <div className="container">
        {/* <PortfolioPlaningFilter
          filterFormIsVisible={false}
          filter={filter}
          nbResult={nbrClients}
          updateFilter={setFilter}
          fetching={portfolioQuery.isFetching}
          loggedAs={loggedAs}
          spaceAs={spaceAs}
        /> */}
        <div className="result_label">
          {_("portfolioCount")} : <span>{portfolios.length}</span>
        </div>
        <div className="medium-11">
          {portfolioQuery.isFetching ? (
            renderFetchingLines()
          ) : portfolios.length ? (
            <Fragment>
              {portfolios.map((item, index) => {
                let responsables = [];
                Object.values(item.data).forEach((element) => {
                  if (Object.values(element.responsable.roles).length > 0) {
                    responsables.push({
                      ...element.responsable,
                      roles: Object.values(element.responsable.roles),
                      avatar: avatars[element.responsable.uid]?.avatarUrl,
                    });
                  }
                });
                var order = ["GD", "GE", "Sup", "SE", "ScanOp"];
                let sortedResponsable = responsables.sort(function (a, b) {
                  return order.indexOf(a.roles[0]) - order.indexOf(b.roles[0]);
                });
                let sotedData = Object.values(item.data).sort(function (a, b) {
                  return (
                    order.indexOf(Object.values(a.responsable.roles)[0]) -
                    order.indexOf(Object.values(b.responsable.roles)[0])
                  );
                });
                let intervenantsCollaborators = {};
                let responsablesCollaborators = {};
                let intervenantsCollaboratorsData = {
                  realHours: 0,
                  realCA: 0,
                  budgetedCA: 0,
                };
                Object.keys(sotedData).forEach((elemK) => {
                  if (
                    sotedData[elemK].budgetedHours.total === 0 &&
                    sotedData[elemK].responsable.roles.length === 0
                  ) {
                    intervenantsCollaborators[elemK] = sotedData[elemK];
                    intervenantsCollaboratorsData = {
                      ...intervenantsCollaboratorsData,
                      realHours:
                        intervenantsCollaboratorsData.realHours +
                        sotedData[elemK].realHours,
                      realCA:
                        intervenantsCollaboratorsData.realCA +
                        sotedData[elemK].realCA,
                    };
                  } else {
                    responsablesCollaborators[elemK] = sotedData[elemK];
                  }
                });
                let detailedData = {};
                if (collapseIntervenants) {
                  detailedData = { ...responsablesCollaborators };
                } else {
                  detailedData = {
                    ...responsablesCollaborators,
                    ...intervenantsCollaborators,
                  };
                }
                return (
                  <div key={`client-${item.id}`}>
                    <Item
                      index={index}
                      lng={language}
                      item={item}
                      responsables={sortedResponsable}
                      year={filter.year}
                      isEntity={true}
                      setCollapsedItem={setActiveAttachedClient}
                      collapsedItem={activeAttachedClient}
                    />

                    <div className="list-frame_item_body">
                      {activeAttachedClient === item.id && (
                        <div
                          style={{
                            margin: "0px 0px 13px 15px",
                            padding: "10px 0px 20px",
                          }}>
                          <span className="title_details">Détails :</span>
                          <div className="table">
                            <div className="table_header">
                              {columns.map((column, i) => (
                                <div
                                  key={i}
                                  style={{
                                    width: `${column.widthPercentage}%`,
                                    justifyContent:
                                      column.id === "responsable"
                                        ? "start"
                                        : "center",
                                  }}>
                                  {_(column.label)}
                                </div>
                              ))}
                            </div>
                            <div className="table_body">
                              {Object.keys(detailedData).map(
                                (attachedKey, i) => {
                                  return (
                                    <div className="line">
                                      {columns.map((column, i) => (
                                        <div
                                          key={i}
                                          style={{
                                            width: `${column.widthPercentage}%`,
                                            justifyContent:
                                              column.id === "responsable"
                                                ? "start"
                                                : "center",
                                          }}>
                                          {column.id === "budgetedHours" ? (
                                            <div className="mini-table">
                                              {Object.keys(
                                                detailedData[attachedKey]
                                                  .budgetedHours
                                              ).map((element, i) => (
                                                <div>
                                                  <span>{element}</span>
                                                  <div className="tag">
                                                    {parseFloat(
                                                      parseFloat(
                                                        detailedData[
                                                          attachedKey
                                                        ].budgetedHours[element]
                                                      ).toFixed(0)
                                                    )}
                                                    h
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          ) : column.id === "responsable" ? (
                                            <div className="infos">
                                              <div
                                                className="avatar"
                                                style={{
                                                  backgroundImage: `url(${
                                                    avatars[
                                                      detailedData[attachedKey]
                                                        .responsable.uid
                                                    ]
                                                      ? avatars[
                                                          detailedData[
                                                            attachedKey
                                                          ].responsable.uid
                                                        ].avatarUrl
                                                      : ""
                                                  }`,
                                                }}></div>
                                              <div>
                                                {
                                                  detailedData[attachedKey]
                                                    .responsable.name
                                                }
                                              </div>
                                              <div>
                                                {_(
                                                  Object.values(
                                                    detailedData[attachedKey]
                                                      .responsable.roles
                                                  ).length > 0
                                                    ? Object.values(
                                                        detailedData[
                                                          attachedKey
                                                        ].responsable.roles
                                                      ).join("-")
                                                    : "-"
                                                )}
                                              </div>
                                            </div>
                                          ) : column.id === "cp" ? (
                                            detailedData[attachedKey]
                                              .responsable.deleted ? (
                                              <div
                                                onClick={() =>
                                                  handleOnClick(
                                                    attachedKey,
                                                    detailedData[attachedKey]
                                                      .responsable.name
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}>
                                                <Tooltip
                                                  key={`role-${item.id}-${attachedKey}`}
                                                  placement="top"
                                                  title={_("deletedCollab")}
                                                  color="red">
                                                  <div
                                                    className="tag"
                                                    style={{
                                                      color: "red",
                                                      backgroundColor:
                                                        "#FF2D0020",
                                                    }}>
                                                    <TiWarning />
                                                  </div>
                                                </Tooltip>
                                              </div>
                                            ) : cp[
                                                detailedData[attachedKey]
                                                  .responsable.id
                                              ] ? (
                                              <div>
                                                {cp[
                                                  detailedData[attachedKey]
                                                    .responsable.id
                                                ].disponibility ===
                                                "NO_CONFIGURATION" ? (
                                                  <Tooltip
                                                    key={`role-${item.id}-${attachedKey}`}
                                                    placement="top"
                                                    title={_(
                                                      "regimeNotConfigurated"
                                                    )}>
                                                    <div className="tag">
                                                      <TiWarning />
                                                    </div>
                                                  </Tooltip>
                                                ) : (
                                                  <Tooltip
                                                    key={`role-${item.id}-${attachedKey}`}
                                                    placement="top"
                                                    title={Object.keys(
                                                      cp[
                                                        detailedData[
                                                          attachedKey
                                                        ].responsable.id
                                                      ].budget
                                                    ).map((i) => (
                                                      <div>
                                                        {`${i} : ${parseFloat(
                                                          parseFloat(
                                                            (cp[
                                                              detailedData[
                                                                attachedKey
                                                              ].responsable.id
                                                            ].budget[i] *
                                                              100) /
                                                              cp[
                                                                detailedData[
                                                                  attachedKey
                                                                ].responsable.id
                                                              ].disponibility
                                                          ).toFixed(0)
                                                        )}%`}
                                                      </div>
                                                    ))}>
                                                    <div className="tag">
                                                      {parseFloat(
                                                        parseFloat(
                                                          (cp[
                                                            detailedData[
                                                              attachedKey
                                                            ].responsable.id
                                                          ].total *
                                                            100) /
                                                            cp[
                                                              detailedData[
                                                                attachedKey
                                                              ].responsable.id
                                                            ].disponibility
                                                        ).toFixed(0)
                                                      )}
                                                      %
                                                    </div>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            ) : (
                                              <span>-</span>
                                            )
                                          ) : (
                                            <div className="tag">
                                              {parseFloat(
                                                parseFloat(
                                                  detailedData[attachedKey][
                                                    column.id
                                                  ]
                                                ).toFixed(0)
                                              )}
                                              {column.id === "realHours"
                                                ? "h"
                                                : "€"}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              )}
                              {collapseIntervenants && (
                                <div className="line">
                                  {columns.map((column, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        width: `${column.widthPercentage}%`,
                                        justifyContent:
                                          column.id === "responsable"
                                            ? "start"
                                            : "center",
                                      }}>
                                      {column.id === "budgetedHours" ? (
                                        <div>-</div>
                                      ) : column.id === "responsable" ? (
                                        <div className="infos">
                                          <div className="avatar"></div>
                                          <div>
                                            {_("intervenantsWithNoRole")}
                                          </div>
                                          <div>-</div>
                                        </div>
                                      ) : column.id === "cp" ? (
                                        <span>-</span>
                                      ) : (
                                        <div className="tag">
                                          {parseFloat(
                                            parseFloat(
                                              intervenantsCollaboratorsData[
                                                column.id
                                              ]
                                            ).toFixed(0)
                                          )}
                                          {column.id === "realHours"
                                            ? "h"
                                            : "€"}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                              <Tooltip
                                placement="top"
                                title={_(
                                  collapseIntervenants
                                    ? "showIntervenants"
                                    : "reduceIntervenants"
                                )}>
                                <div
                                  className="float_button"
                                  onClick={() =>
                                    setCollapseIntervenants(
                                      !collapseIntervenants
                                    )
                                  }>
                                  {collapseIntervenants ? "+" : "-"}
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noClientsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioPlaning;
