import axios from "axios";

import { TTP_API_URL } from "../config";

export const getPortfolioList = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/portfolio`;

  let params = {
    access_token: token,
    organization_id: organization,
    organization: organization,
    year: data.year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getTimeSheetProgression = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/ts_progression`;
  let params = {
    access_token: token,
    organization_id: organization,
    organization: organization,
    year: data.year,
    collaborators: data.collaborators,
    clients: data.clients,
    role: data.role,
    bu: data.bu ? data.bu.value : null,
    zone: data.zone ? data.zone.value : null,
    sortBy: data.sort,
  };

  params.limit = data.pageSize || 10;
  params.start = !data.paginationPage ? 0 : (data.paginationPage - 1) * 10;

  return axios.get(requestUrl, {
    params,
  });
};

export const getCapacityPlaning = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_calendar`;

  let params = {
    organizationId: organization,
    access_token: token,
    organization_id: organization,
    collaborators: data.collaborators.map(({ value }) => value),
    squads: data.squads.map(({ value }) => value),
    zones: data.zones.map(({ value }) => value),
    year: [data.year],
    clientCRMTypes: data.clientCRMTypes,
    clientCRMGroup: data.clientCRMGroup,
    deletedStatus: data.deletedStatus,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getCalendarRecap = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/calendar_recap`;

  let params = {
    organization: organization,
    access_token: token,
    organization_id: organization,
    year: data.year,
    month: data.month,
    collaborators: data.collaborators,
    role: data.role,
    bu: data.bu ? [data.bu.value] : null,
    zone: data.zone ? [data.zone.value] : null,
  };

  return axios.get(requestUrl, {
    params,
  });
};
