import React, { useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import moment from "moment";
import classNames from "classnames";
import { IconPlus, IconChevronDown, IconTrash } from "../../common/Icons";
import { deleteCollaboratorPlanner } from "../../../api";
import { toast } from "react-toastify";
const Item = ({
  events,
  setEvents,
  token,
  organizationId,
  setOpenAddEvent,
  setSelectedDateEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  day,
  dayIndex,
  setSelectedCategory,
  selectedDateEvent,
  selectedDurationHours,
  setSelectedDurationHours,
  selectedDurationMin,
  setSelectedDurationMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  setSelectedDescription,
  setSelectedTypeEvent,
  setSelectedClient,
  setSelectedEventId,
  weekIndex,
  collapsedWeeks,
  toggleCollapseWeek,
  refreshEvents,
  isMonthVue,
}) => {
  const onClickEvent = (event) => {
    setSelectedClient({ value: event.client_id, label: event.client_name });
    setSelectedCategory({
      value: event.category_id,
      label: event.category_name,
    });
    setSelectedDateEvent(moment(event.date.date).toDate());
    setSelectedDurationHours(Math.floor(event.duration / 60));
    setSelectedDurationMin(event.duration % 60);
    setSelectedStatus(event.status);
    setSelectedPlaceRDV(event.meetingPlace);
    setSelectedDescription(event.description);
    setSelectedTypeEvent(event.type);
    setSelectedEventId(event.id);
    setOpenAddEvent(true);
  };
  const deletePlanner = async (eventId) => {
    if (eventId && token && organizationId) {
      try {
        const response = await deleteCollaboratorPlanner(
          eventId,
          organizationId,
          token
        );
        // setEvents(events.filter((event) => event.id !== eventId));

        refreshEvents();
        if (response) {
          toast.success(_("saveSuccess"));
        }
      } catch (e) {
        toast.error(_("savingError"), { autoClose: true });
        return null;
      }
    }
  };

  const convertDecimalTime = (decimalTime) => {
    // Extract hours and decimal part
    let hours = Math.floor(decimalTime);
    let decimalPart = decimalTime - hours;

    // Convert decimal part to minutes
    let minutes = Math.round(decimalPart * 60);

    return hours + "h " + minutes;
  };

  return (
    <div className="calendar_columns" key={dayIndex}>
      <div className={classNames("day_label")}>{day.format("dddd")}</div>
      <div className={classNames("day_label")}>{day.format("DD")}</div>
      <div
        className={classNames(
          "day_content",
          day.isSame(moment(), "day") ? "selected_day" : ""
        )}>
        <div className="day_header line_space_content">
          <div
            onClick={() => {
              setOpenAddEvent(true);
              setSelectedDateEvent(day.format("YYYY/MM/DD"));
              setSelectedEventId(null);
            }}>
            <IconPlus size={13} />
          </div>

          <div className="realised">
            Realisé:
            {timeSheetByDays.filter(
              (task) =>
                task.date &&
                moment(task.date.replace(" ", "T") + "Z").isSame(day, "day")
            ).length > 0 ? (
              timeSheetByDays
                .filter((filteredEvent) =>
                  moment(filteredEvent.date.replace(" ", "T") + "Z").isSame(
                    day,
                    "day"
                  )
                )
                .map((filteredEvent, eventIndex) => (
                  <>
                    {renderDurationDisplay(
                      filteredEvent.total_task_duration * 60
                    )}
                  </>
                ))
            ) : (
              <>{renderDurationDisplay(0)}</>
            )}
          </div>

          <div
            className="disponibility"
            style={{
              background:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(255, 226, 221)",
              // backgroundColor: holiday.key,
              color:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(93, 23, 21)",
            }}>
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length > 0
              ? holidays
                  .filter((event) => moment(event.date).isSame(day, "day"))
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{
                        background: "rgb(255, 226, 221)",
                        backgroundColor: holiday.key,
                        color: "rgb(93, 23, 21)",
                      }}>
                      {holiday.label.slice(0, 14)}
                    </div>
                  ))
              : durationsByDay &&
                durationsByDay.filter((duration) =>
                  moment(duration.date).isSame(day, "day")
                ).length > 0
              ? durationsByDay
                  .filter((duration) =>
                    moment(duration.date).isSame(day, "day")
                  )
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{ background: holiday.key }}>
                      {formatDuration(holiday.duration)}
                    </div>
                  ))
              : formatDuration(0)}
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length === 0 && (
              <>
                {" "}
                sur{" "}
                {convertDecimalTime(
                  workingHours[dayMapping[moment(day).format("ddd")]]
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={classNames(
            "events",
            collapsedWeeks[weekIndex] ? "collapsed" : ""
          )}>
          {events
            .filter((event) =>
              moment(moment(event.date.date, "Y-M-D").toDate()).isSame(
                day,
                "day"
              )
            )
            .map((filteredEvent, eventIndex) => (
              <div key={eventIndex} className={`event ${filteredEvent.status}`}>
                <div
                  className="event_type"
                  onClick={() => onClickEvent(filteredEvent)}>
                  <div className="line_content">
                    {filteredEvent.type === "task" && (
                      <div className="circle">
                        <div
                          className={classNames(
                            "circle",
                            filteredEvent.status === "done" ||
                              filteredEvent.status === "Terminé"
                              ? "tag-success"
                              : filteredEvent.status === "inprogress" ||
                                filteredEvent.status === "En cours"
                              ? "tag-warning"
                              : "tag-danger"
                          )}></div>
                      </div>
                    )}
                    <div className="type">
                      {filteredEvent.type === "task"
                        ? filteredEvent.type
                        : filteredEvent.type +
                          " : " +
                          filteredEvent.meetingPlace}
                    </div>
                  </div>
                  <div className="type duration">
                    {formatDuration(filteredEvent.duration)}
                  </div>
                </div>
                <div className="client_name">{filteredEvent.client_name}</div>{" "}
                {filteredEvent.type === "task" && (
                  <div className="line_space_content" style={{ width: "100%" }}>
                    <div className="category_name">
                      {filteredEvent.category_name}
                    </div>
                    <div onClick={() => deletePlanner(filteredEvent.id)}>
                      <IconTrash />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>

        {isMonthVue &&
          events &&
          events.filter((event) => moment(event.date.date).isSame(day, "day"))
            .length > 2 && (
            <div
              className="day_footer"
              onClick={() => toggleCollapseWeek(weekIndex)}>
              {collapsedWeeks[weekIndex] ? (
                <div>
                  +{" "}
                  {events.filter((event) =>
                    moment(event.date.date).isSame(day, "day")
                  ).length - 2}{" "}
                  cartes <IconChevronDown size={10} fill="#29394D" />
                </div>
              ) : (
                <div className="action">
                  Reduire <IconChevronDown size={10} fill="#29394D" />
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default Item;
