import React, { useState, useRef, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { getCollaborator } from "../../api";

import TTPTab from "../common/Tab/TTPTab";

import CollaboratorProductionTracking from "./ProductionTracking/CollaboratorProductionTracking";
import CollaboratorPlansBoard from "./PlansBoard/CollaboratorPlansBoard";
import Planner from "./Planner";

import "./CollaboratorSpace.local.scss";
import CalendarCollaborator from "./CalendarIframe";
import CapacityDossier from "./CapacityDossierIframe";
import CollaboratorDashboardIframe from "./CollaboratorDashboardIframe";
import FinancialCollaborator from "./FinancialCollaborator/FinancialCollaborator";
import CollaboratorSpaceHeader from "./CollaboratorSpaceHeader";

let tabs = [
  {
    id: "DASHBOARD",
    label: "dashboard",
  },
  {
    id: "PRODUCTON_TRACKING",
    label: "productionTracking",
  },
  {
    id: "PLANS_BOARD",
    label: "plansBoard",
  },
  {
    id: "CALENDAR",
    label: "calendar",
  },
  {
    id: "PLANNER",
    label: "planning",
  },
  {
    id: "FOLDER_CAPACITY",
    label: "folderCapacity",
  },
  {
    id: "FINANCIAL",
    label: "etat financier personnel",
  },
];

const CollaboratorSpace = ({ userSpace = null, collaboratorSpace = null }) => {
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  const [searchParams] = useSearchParams();
  const { language } = useSelector((state) => state.params);
  const activeTab = searchParams.get("tab");
  let { id } = useParams();

  //Header
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    tab:
      tabs.filter((tab) => tab.id === activeTab).length > 0
        ? tabs.filter((tab) => tab.id === activeTab)[0].id
        : tabs[1].id,
  });

  const [collaborator, setCollaborator] = useState(null);

  useEffect(() => {
    if (collaboratorSpace && userSpace) {
      setCollaborator(userSpace);
    }
  }, [collaborator]);

  let collaboratorQuery = useQuery(["collaboratorQuery", token], async () => {
    if (token) {
      try {
        const response = await getCollaborator(id, navCommunity.id, token);
        setCollaborator(response.data.data);
      } catch (e) {
        setCollaborator(null);
        return null;
      }
    }
  });

  const renderTabContent = () => {
    console.log("collaboratoor========", collaborator)
    switch (filter.tab) {
      case "DASHBOARD":
        return loggedAs === "MANAGER" ? (
          <CollaboratorDashboardIframe
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        ) : (
          <div>DASHBOARD</div>
        );
      case "PRODUCTON_TRACKING":
        return (
          <CollaboratorProductionTracking
            id={collaborator.id}
            name={collaborator.name}
            collaborator={collaborator}
            token={token}
            lng={language}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            year={filter.year}
            user={user}
          />
        );

      case "PLANS_BOARD":
        return (
          <CollaboratorPlansBoard
            id={collaborator.id}
            token={token}
            lng={language}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        );

      case "CALENDAR":
        return (
          <CalendarCollaborator
            id={collaborator.id}
            name={collaborator && collaborator.name}
            year={filter.year}
          />
        );

      case "PLANNER":
        return (
          <Planner
            collaboratorId={collaborator.id}
            collaboratorName={collaborator && collaborator.name}
            avater={collaborator && collaborator.avatar}
            organizationId={navCommunity && navCommunity.id}
            navCommunity={navCommunity}
            lng={language}
            token={token}
            year={filter.year}
          />
        );

      case "FOLDER_CAPACITY":
        return (
          <CapacityDossier
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        );
      case "FINANCIAL":
        return (
          <FinancialCollaborator
            token={token}
            year={filter.year}
            id={collaborator.id}
            name={collaborator.name}
            organization={navCommunity && navCommunity.id}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="page-content">
      <CollaboratorSpaceHeader
        collaboratorSpace={collaboratorSpace}
        onChangeYear={(item) => {
          setFilter({ ...filter, year: item });
        }}
        collaborator={collaborator}
        year={filter.year}
      />
      <div className="ttp-tab-container">
        <TTPTab
          tabs={tabs}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item });
          }}
          handleActivateArrowRight={() => {
            return;
          }}
        />
      </div>

      <div className="content-body">{collaborator && renderTabContent()}</div>
    </div>
  );
};

export default CollaboratorSpace;
